import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '@full-fledged/alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { BuscaEscolaMonitoramentoFilter } from '../../../model/BuscaEscolaMonitoramentoFilter';
import { EscolaMonitoramento } from '../../../model/EscolaMonitoramento';
import { ModalFiltroInstituicaoComponent } from '../../../pages/modal/modal-filtro-instituicao/modal-filtro-instituicao.component';
import { MonitoramentoService } from '../../../services/monitoramento.service';

@Component({
  selector: 'app-instituicao',
  templateUrl: './instituicao.component.html',
  styleUrls: ['./instituicao.component.scss']
})
export class InstituicaoComponent implements AfterViewInit {
  displayedColumns: string[] = ['inep', 'escolas', 'turmas', 'p-sala', 'p-nec', 'vagas', 'matriculas', 'ocupacao'];
  dataSource = new MatTableDataSource<EscolaMonitoramento>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  escolasMonitoramento: EscolaMonitoramento[] = [];
  filtroEscolaMonitoramento = new BuscaEscolaMonitoramentoFilter();

  indicadoresForm = new FormControl();
  exibirColunas: boolean[] = [true, true, true, true, true];

  indicadores: string[] = ['Professores em sala', 'Professores necessários', 'Vagas', 'Matrículas', 'Ocupação'];

  constructor(private monitoramentoService: MonitoramentoService,
              private alertService: AlertService,
              private spinner: NgxSpinnerService, public dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Exibição';

     }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  buscarEscolas() {
    this.spinner.show();
    this.monitoramentoService.buscarEscolaMonitoramentoFiltro(this.filtroEscolaMonitoramento).subscribe((data) => {
      this.spinner.hide();
      console.log('data', data);
      this.escolasMonitoramento = data;
      this.dataSource.data = data;
      this.paginator._intl.itemsPerPageLabel = 'Exibição';

      if (!this.escolasMonitoramento.length) {
        this.alertService.warning('Nenhuma escola encontrada');
      }
    }, (error) => {
      this.spinner.hide();
      console.log('Deu erro;');
    });
  }

  teste(ev) {
    console.log(ev.source.value);
    switch (ev.source.value) {
      case 'Professores em sala':
        this.exibirColunas[0] = !this.exibirColunas[0];
        break;
      case 'Professores necessários':
        this.exibirColunas[1] = !this.exibirColunas[1];
        break;
      case 'Vagas':
        this.exibirColunas[2] = !this.exibirColunas[2];
        break;
      case 'Matrículas':
        this.exibirColunas[3] = !this.exibirColunas[3];
        break;
      case 'Ocupação':
        this.exibirColunas[4] = !this.exibirColunas[4];
        break;

    }
    if (ev.source.value === 'Professores em sala') {
    }
  }

  abrirFiltro() {
    const dialogRef = this.dialog.open(ModalFiltroInstituicaoComponent, {
      panelClass: 'dialog-responsive',
      data: 1,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        console.log(result);
        this.filtroEscolaMonitoramento = result;
        console.log(this.filtroEscolaMonitoramento);
        this.buscarEscolas();
      }
    });
  }
}
