<div class="container main">
  <table class="table table-bordered">
    <thead>
    <tr>
      <th scope="col" class="base">HORÁRIOS</th>
      <th scope="col" class="base">SEGUNDA-FEIRA</th>
      <th scope="col" class="base">TERÇA-FEIRA</th>
      <th scope="col" class="base">QUARTA-FEIRA</th>
      <th scope="col" class="base">QUINTA-FEIRA</th>
      <th scope="col" class="base">SEXTA-FEIRA</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let ht of horariosDisciplinas">
      <th>{{converterHora(ht.horario.inicio) | date:'shortTime':'GMT-6' }} - {{converterHora(ht.horario.fim) | date:'shortTime':'GMT-6' }} </th>

      <th *ngFor="let number of [2, 3, 4, 5, 6]">

        <span >
            {{returnDisciplina(ht, number)}}
        </span>


      </th>

    </tr>
    </tbody>

  </table>
</div>
