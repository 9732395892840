<div class="bg-container">
  <div class="filters">
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-filtro" (click)="abrirFiltro()" >
      + Adicionar filtro
    </button>
  </div>

  <div class="indicadoresVisu">
    <div class="filtros_selecionados">
      <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngIf="anoSelecionado">{{anoSelecionado}}</mat-chip>
        <mat-chip *ngIf="escolaSelecionada.ano">{{escolaSelecionada.nome}}</mat-chip>

      </mat-chip-list>
    </div>
    <div class="col-md-4 col-6">
<!--      <mat-form-field appearance="fill" class="w-100">-->
<!--        <mat-label  class="color-white">Indicadores para Visualização</mat-label>-->
<!--      </mat-form-field>-->
    </div>
    <!-- Vai ser um select com check box -->
  </div>
</div>

<h4  *ngIf="!anoSelecionado"  class="mt-4">
  <i class="fas fa-exclamation-circle"></i> Adicione um filtro para que seja exibido os dados
</h4>

<h4  *ngIf="anoSelecionado && !dataSource.data.length"  class="mt-4">
  <i class="fas fa-exclamation-circle"></i> Nenhuma escola encontrada!
</h4>


<div class="mat-elevation-z8 mt-4" >



  <table  mat-table [dataSource]="dataSource" matSort  class="table-striped">

    <!-- Position Column -->
    <ng-container matColumnDef="turma">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TURMA </th>
      <td mat-cell *matCellDef="let element"> {{element.turma}} </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="turno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TURNO </th>
      <td mat-cell *matCellDef="let element"> {{element.turno}} </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="vagas">
      <th  class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> VAGAS </th>
      <td  mat-cell *matCellDef="let element"> {{element.vagas}} </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="matriculados">
      <th  class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> MATRICULADOS </th>
      <td mat-cell *matCellDef="let element"> {{element.matriculados}} </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="saldo">
      <th class="text-center"  mat-header-cell *matHeaderCellDef  mat-sort-header> SALDO </th>
      <td   mat-cell *matCellDef="let element"> {{element.saldo}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator class="paginacao" [pageSizeOptions]="getPageSizeOptions()"
                 showFirstLastButtons  >
  </mat-paginator>
</div>
