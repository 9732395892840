import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { Cidade } from '../model/Cidade';
import { Estado } from '../model/Estado';
import { Generico } from '../model/Generico';
import { Instituicao } from '../model/Instituicao';

@Injectable({
  providedIn: 'root'
})
export class InstituicaoService {

  private URL = environment.API_URL + '/instituicao';
  private URLFiltro = environment.API_URL;

  constructor(private http: HttpClient ) {
  }

  // tslint:disable-next-line:typedef
  buscarCidades(): Observable<Cidade[]>  {
    return this.http.get<Cidade[]>(`${this.URLFiltro}/util/cidades`).pipe(take(1));
  }

  // tslint:disable-next-line:typedef
  buscarTodosEstados(): Observable<Estado[]>  {
    return this.http.get<Estado[]>(`${this.URLFiltro}/util/estados`).pipe(take(1));
  }

  // tslint:disable-next-line:typedef
  buscarCisdadesPorId(estadoId: number): Observable<Cidade[]>  {
    return this.http.get<Cidade[]>(`${this.URLFiltro}/util/cidades/${estadoId}`).pipe(take(1));
  }


  // tslint:disable-next-line:typedef
  buscarInstituicaoPorCidade(cidadeId: number): Observable<Instituicao[]>  {
    return this.http.get<Instituicao[]>(`${this.URL}/cidade/${cidadeId}`).pipe(take(1));
  }

  // tslint:disable-next-line:typedef
  buscarInstituicaoPorId(id: number): Observable<Instituicao>  {
    return this.http.get<Instituicao>(`${this.URL}/${id}`).pipe(take(1));
  }

  getSexo(): Observable<Generico[]>  {
    return this.http.get<Generico[]>(`${this.URLFiltro}/util/sexo`).pipe(take(1));
  }

  // api-filtro/util/tipoLogradouro" -H "accept: */*"
  buscarTiposLogradouros(): Observable<Generico[]>  {
    return this.http.get<Generico[]>(`${this.URLFiltro}/util/tipoLogradouro`).pipe(take(1));
  }

  buscarOrgaos(): Observable<Generico[]>  {
    return this.http.get<Generico[]>(`${this.URLFiltro}/util/orgaoEmissor`).pipe(take(1));
  }
}
