import {Component, OnInit} from '@angular/core';
import {AlunoService} from '../../services/aluno.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';
import {Aluno} from '../../model/Aluno';
import {FotoService} from '../../services/foto.service';
import {FotoAluno} from '../../model/FotoAluno';
import {AlertService} from '@full-fledged/alerts';
import {FotoEvent} from '../../events/foto-event';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  aluno = new Aluno();
  fotoBase64 = null;
  fotoNome = null;

  constructor(private alunoService: AlunoService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService,
              public authService: AuthService,
              private fotoService: FotoService,
              private fotoEvent: FotoEvent) {
  }

  ngOnInit(): void {
    if (this.authService.check()) {
      if (localStorage.hasOwnProperty('fotoPerfil')) {
        this.fotoBase64 = localStorage.getItem('fotoPerfil');
      }
      this.buscarPerfil();
    }
  }

  // tslint:disable-next-line:typedef
  buscarPerfil() {
    this.spinner.show();
    this.alunoService.buscarPefil(this.authService.getUser().id).subscribe((data) => {
        console.log(data);
        this.aluno = data;
        this.spinner.hide();
      },
      (error) => {
        console.log('erro');
        console.log(error);
        this.spinner.hide();

      });
  }

//  FOto do perfil
  // tslint:disable-next-line:typedef
  handleFileInput(files: FileList) {
    const fileToUpload = files.item(0);
    this.fotoNome = fileToUpload.name;
    console.log(this.fotoNome);
    const reader = new FileReader();
    reader.readAsDataURL(fileToUpload);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        this.fotoBase64 = reader.result.split(',')[1];
        // console.log(this.fotoBase64);
      }
    };
  }

  // tslint:disable-next-line:typedef
  salvarFoto() {
    if (this.fotoBase64) {
      this.spinner.show();
      const foto = new FotoAluno();
      const aluno = new Aluno();
      aluno.id = this.authService.getUser().id;
      foto.aluno = aluno;
      foto.perfil = true;
      foto.arquivo = this.fotoNome;
      foto.foto = this.fotoBase64;
      console.log(foto);

      this.fotoService.salvarFotoPerfil(foto).subscribe((data) => {
        console.log(data);
        localStorage.setItem('fotoPerfil', foto.foto);
        this.fotoNome = null;
        this.fotoEvent.alteracao('');
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        this.spinner.hide();
        this.alertService.warning('Ops! Ocorreu um erro, tente mais tarde');

      });

    } else {
      this.alertService.warning('Foto não anexada! Atualize a página e tente novamente');
    }
  }

  // tslint:disable-next-line:typedef
  cancelarFoto() {
    this.fotoNome = null;
    if (localStorage.hasOwnProperty('fotoPerfil')) {
      this.fotoBase64 = localStorage.getItem('fotoPerfil');
    } else {
      this.fotoBase64 = null;
    }
  }
}
