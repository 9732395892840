<div *ngIf="login" class="containerMain">
  <div id="box">
    <div class="container">
      <div class="title">LOGIN</div>
      <div id="login-form">
        <form [formGroup]="loginForm" novalidate>
          <div class="input">
            <!--      <label for="name">Código</label>-->
            <input type="number" formControlName="id" placeholder="Código Aluno" name="name" id="name">
            <span class="spin"></span>
          </div>
          <span
            *ngIf="loginForm.controls['id'].invalid && (loginForm.controls['id'].touched || loginForm.controls['id'].dirty)">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <span class="color-red">Preencha o código.</span>
          </span>
          <div class="input">
            <!--      <label for="pass">Data de Nascimento</label>-->
            <input mask="00/00/0000" [dropSpecialCharacters]="false" formControlName="dataNascimento"
              placeholder="Data de Nascimento" name="pass" id="pass">
            <span class="spin"></span>
            <span
              *ngIf="loginForm.controls['dataNascimento'].invalid && (loginForm.controls['dataNascimento'].touched || loginForm.controls['dataNascimento'].dirty)">
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <span class="color-red">Data de nascimento inválida</span>
            </span>
          </div>

          <div class="button login">
            <button [disabled]="loginForm.invalid" (click)="entrar()"><span>Entrar</span> <i
                class="fa fa-check"></i></button>
          </div>
          <a href="#" class="pass-forgot">Esqueceu a senha?</a>
          <button (click)="login = !login" class="btn btn-danger cadastrese">Cadastre-se</button>
        </form>
      </div>
    </div>
  </div>
</div>

<app-cadastro-aluno *ngIf="!login" (loginToggle)="toggleLogin($event)" ></app-cadastro-aluno>

<ff-alerts></ff-alerts>