import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Cidade} from '../../../model/Cidade';
import {Instituicao} from '../../../model/Instituicao';

@Component({
  selector: 'app-modal-aviso',
  templateUrl: './modal-aviso.component.html',
  styleUrls: ['./modal-aviso.component.scss']
})
export class ModalAvisoComponent implements OnInit {
  instituicao = new Instituicao();
  constructor(public dialogRef: MatDialogRef<ModalAvisoComponent>,
              private router: Router) { }

  ngOnInit(): void {


    if (localStorage.hasOwnProperty('instituicaoSelecionada')){
      this.instituicao = JSON.parse(localStorage.getItem('instituicaoSelecionada'));
    } else {
      this.close();
    }
  }

  close(): void {
    this.dialogRef.close();
    this.router.navigate(['/']);
  }

}
