<!--<div class="container main">-->
<!--  <div id="box-identificaçao">-->
<!--    <div class="right-boletim">-->
<!--      <p>ANO: 1º ANO FUNDAMENTAL II</p>-->
<!--      <p>TURNO: MATUTINO</p>-->
<!--    </div>-->
<!--    <div id="nome-aluno">-->
<!--      <p>NOME: {{authService.getUser().nome}}</p>-->
<!--      <p>ANO LETIVO: 2021</p>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="container main">
  <div id="faltas">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th scope="col" class="table-hearder">DISCIPLINA</th>
        <th scope="col" class="table-hearder">FALTAS</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let falta of faltasQuantidade">
        <th>{{falta.disciplina.descricao}}</th>
        <td>
          <div>
            {{falta.quantidade}}
          </div>
          <div class="detalhes cursor" *ngIf="falta.quantidade > 0">
            <a (click)="openDialogFaltas(falta.disciplina)" data-toggle="modal"
               data-target="#modal-detalhes">Detalhes</a>
          </div>
        </td>
      </tr>

      </tbody>
    </table>
  </div>
</div>


<div class="container main">
  <p class="mt-3">BOLETIM</p>
  <p>Aguardando o fechamento do período letivo...</p>
</div>


