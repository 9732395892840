<mat-toolbar>
  <span>Atividade</span>
  <span class="spacer"></span>
  <a (click)="close(false)" class="cursor"><i class="fas fa-times"></i></a>
</mat-toolbar>

<div class="mt-3 ">

  <a class="cursor" (click)="downloadMaterial(atividadeRecebida.materialAula.id, atividadeRecebida.materialAula.arquivo)" style="text-decoration: none; color: #3425af">{{atividadeRecebida.materialAula.titulo}} <i class="fas fa-file-download"></i></a>

  <div class="descricao mt-2">
    <h4> DESCRIÇÃO: {{atividadeRecebida.materialAula.descricao}}</h4>
  </div>

  <div>
    <div *ngIf="atividadeRecebida.statusAtividade.id == 1">
    <input class="ng-hide d-none" id="input-file-id" multiple type="file"  (change)="handleFileInput($event.target.files)"/>
    <label for="input-file-id" class=" btn btn-outline-primary ">
      <i class="fas fa-paperclip"></i>
      Anexar arquivo</label>
    </div>

    <div *ngIf="this.fileToUpload != null">
      Arquivo Selecionado: {{this.fileToUpload.name}}
      <br/>
    </div>

    <div id="text-area mt-2">
      <textarea [disabled]="atividadeRecebida.statusAtividade.id !== 1" [(ngModel)]="atividade.observacaoAluno" name="Description" id="text-box" cols="30" rows="3" placeholder="Adicione um comentário aqui! (Opcional)"></textarea>
    </div>

    <a *ngIf="atividadeRecebida.statusAtividade.id == 1" type="button" class="btn btn-primary float-right mt-3" (click)="enviarAlunoAtividade()">
      <i class="fas fa-paper-plane"></i> Enviar
    </a>

  </div>
</div>
<ff-alerts></ff-alerts>

