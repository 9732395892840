import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { BuscaEscolaMonitoramentoFilter } from '../model/BuscaEscolaMonitoramentoFilter';
import { EscolaMonitoramento } from '../model/EscolaMonitoramento';
import { FiltroMatriculaBusca } from '../model/FiltroMatriculaBusca.model';
import { FiltroMatriculaResultado } from '../model/FiltroMatriculaResul.model';
import { Generico } from '../model/Generico';
import { Modalidade } from '../model/Modalidade';
import { TurmaMonitoramento } from '../model/TurmaMonitoramento';

@Injectable({
  providedIn: 'root'
})
export class MonitoramentoService {

  // private URL = environment.API_URL + '/monitoramento';
  private URL_API_ETUTOR = environment.API_RELATORIO_URL + '/monitoramento';
  private API_RELATORIO_URL = environment.API_RELATORIO_URL;

  constructor(private http: HttpClient ) {
  }

  buscarEscolaMonitoramento(filtro: BuscaEscolaMonitoramentoFilter): Observable<EscolaMonitoramento[]>{
    return this.http.put<EscolaMonitoramento[]>(`${this.URL_API_ETUTOR}/escolas`, filtro);
  }


  buscarEscolaMonitoramentoFiltro(filtro: BuscaEscolaMonitoramentoFilter): Observable<EscolaMonitoramento[]>{
    return this.http.put<EscolaMonitoramento[]>(`${this.URL_API_ETUTOR}/escolas`, filtro);
  }

  buscarTurmaMonitoramento(anoId: number): Observable<TurmaMonitoramento[]> {
    return this.http.get<TurmaMonitoramento[]>(`${this.URL_API_ETUTOR}/turmas/ano/${anoId}`);
  }

  getModalidade(mediacao?: number): Observable<Modalidade[]> {
    console.log(`${this.API_RELATORIO_URL}/util/modalidade`);
    return this.http.get<Modalidade[]>(`${this.API_RELATORIO_URL}/modalidades?idMediacao=1`);
  }

  // apietutor-0.0.1/anoLetivo/porInstituicao/1
  getAnoLetivo(entidade: number): Observable<any> {
    return this.http.get(`${this.API_RELATORIO_URL}/anoLetivo/porInstituicao/${entidade}`);
  }

  // tslint:disable-next-line:typedef
  getEtapa(id: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(`${this.API_RELATORIO_URL}/etapasEnsino?idNivelEnsino=${id}`);
  }
  // tslint:disable-next-line:typedef
  getEscola(entidade: number): Observable<Generico[]> {
    console.log(`${this.API_RELATORIO_URL}/escolas/${entidade}`);
    return this.http.get<Generico[]>(`${this.API_RELATORIO_URL}/escolas/${entidade}`);
  }
  // tslint:disable-next-line:typedef
  getNivel(id: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(`${this.API_RELATORIO_URL}/niveisEnsino?idModalidade=${id}`);
  }

  // apietutor-0.0.1/monitoramento/preMatriculas
  getPreMatriculas(filtro: FiltroMatriculaBusca): Observable<FiltroMatriculaResultado[]> {
    return this.http.put<FiltroMatriculaResultado[]>(`${this.API_RELATORIO_URL}/monitoramento/preMatriculas`, filtro);
  }

}
