<div class="bg-container">
  <div class="filters">
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-filtro" (click)="abrirFiltro()">
        + Adicionar filtro
      </button>
  </div>

  <div class="indicadoresVisu">
    <div class="filtros_selecionados">
      <mat-chip-list aria-label="Fish selection" *ngIf="filtroEscolaMonitoramento.ano">
        <mat-chip *ngIf="filtroEscolaMonitoramento.ano" >Ano: {{filtroEscolaMonitoramento.ano}}
        </mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.zona" >ZONA: {{filtroEscolaMonitoramento.zona.descricao}}
          </mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.situacao" >SITUAÇÃO: {{filtroEscolaMonitoramento.situacao.descricao}}
          </mat-chip>

<!--         Com valores nulos é pq é todos-->
        <mat-chip *ngIf="!filtroEscolaMonitoramento.zona" >ZONA: TODAS
          </mat-chip>

        <mat-chip *ngIf="! filtroEscolaMonitoramento.situacao" >SITUAÇÃO: TODAS
         </mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.turma" >TURMA: {{filtroEscolaMonitoramento.turma}}
          <mat-icon matChipRemove *ngIf="filtroEscolaMonitoramento.turma != 'TODAS'" >cancel</mat-icon></mat-chip>

      </mat-chip-list>
    </div>
    <div class="col-md-4 col-6">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label  class="color-white">Indicadores para Visualização</mat-label>
        <mat-select [formControl]="indicadoresForm" multiple>
          <mat-select-trigger>
            {{indicadoresForm.value ? indicadoresForm.value[0] : ''}}
            <span *ngIf="indicadoresForm.value?.length > 1" class="example-additional-selection">
        (+{{indicadoresForm.value.length - 1}} {{indicadoresForm.value?.length === 2 ? 'outro' : 'outros'}})
      </span>
          </mat-select-trigger>
          <mat-option (onSelectionChange)="teste($event)"  *ngFor="let topping of indicadores" [value]="topping">{{topping}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Vai ser um select com check box -->
  </div>
</div>

<!--Tabeka-->

<h4  *ngIf="!filtroEscolaMonitoramento.ano"  class="mt-4">
  <i class="fas fa-exclamation-circle"></i> Adicione um filtro para que seja exibido os dados
</h4>

<h4  *ngIf="filtroEscolaMonitoramento.ano && !dataSource.data.length"  class="mt-4">
  <i class="fas fa-exclamation-circle"></i> Nenhuma escola encontrada!
</h4>

<div class="mat-elevation-z8 mt-4" >



  <table  mat-table [dataSource]="dataSource"  class="table-striped"
         cdkDropList
         cdkDropListOrientation="horizontal"
         (cdkDropListDropped)="drop($event)">

    <!-- Position Column -->
    <ng-container matColumnDef="inep">
      <th cdkDrag mat-header-cell *matHeaderCellDef> INEP </th>
      <td mat-cell *matCellDef="let element"> {{element.inep}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="escolas">
      <th cdkDrag mat-header-cell *matHeaderCellDef> ESCOLAS </th>
      <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="turmas">
      <th cdkDrag  class="text-center" mat-header-cell *matHeaderCellDef> TURMAS </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.turmas}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="p-sala">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[0]"> PROFESSORES EM SALA </th>
      <td mat-cell class="text-center" *matCellDef="let element"  [hidden]="exibirColunas[0]"> {{element.professores}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="p-nec">
      <th cdkDrag  class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[1]"> PROFESSORES NECESSÁRIOS </th>
      <td mat-cell class="text-center" *matCellDef="let element" [hidden]="exibirColunas[1]"> {{element.totalProfessores}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="vagas">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[2]"> VAGAS </th>
      <td mat-cell class="text-center" *matCellDef="let element" [hidden]="exibirColunas[2]"> {{element.vagas}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="matriculas">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[3]"> MATRÍCULAS </th>
      <td mat-cell class="text-center" *matCellDef="let element" [hidden]="exibirColunas[3]"> {{element.matriculas}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="ocupacao">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[4]"> OCUPAÇÃO </th>
      <td mat-cell class="text-center" *matCellDef="let element" [hidden]="exibirColunas[4]"> {{element.porcentagemOcupada}}%</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr *ngIf="filtroEscolaMonitoramento.ano && !dataSource.data.length" class="mat-row">
      <td class="mat-cell" colspan="9999">
        NENHUMA ESCOLA ENCONTRADA!
      </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons  >
  </mat-paginator>
</div>
<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
