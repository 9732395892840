import {Component, Input, OnInit} from '@angular/core';
import {AlunoService} from '../../services/aluno.service';
import {TurmaDisciplina} from '../../model/TurmaDisciplina';
import {MaterialAula} from '../../model/MaterialAula';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';
import {AlunoAtividade} from '../../model/AlunoAtividade';
import { AlertService } from '@full-fledged/alerts';


@Component({
  selector: 'app-disciplinas',
  templateUrl: './disciplinas.component.html',
  styleUrls: ['./disciplinas.component.scss']
})
export class DisciplinasComponent implements OnInit {
  // Lista de gradientes para os cards de disciplinas
  listaGradiente = [
    {cor: 'linear-gradient(135deg, #f00f91 0%,#9a03d9 100%)'},
    {cor: 'linear-gradient(135deg, #5b247a 0%,#1bcedf 100%)'},
    {cor: 'linear-gradient(135deg, #f2d50f 0%,#da0641 100%)'},
    {cor: 'linear-gradient(135deg, #fad961 0%,#f76b1c 100%)'},
    {cor: 'linear-gradient(135deg, #7117ea 0%,#ea6060 100%)'},
    {cor: 'linear-gradient(135deg, #65799b 0%,#5e2563 100%)'},
    {cor: 'linear-gradient(135deg, #F5515F 0%,#A1051D 100%)'},
    {cor: 'linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)'},
    {cor: 'linear-gradient(135deg, #184e68 0%,#57ca85 100%)'},
    {cor: 'linear-gradient(135deg, #c3ec52 0%,#0ba29d 100%)'},
    {cor: 'linear-gradient(135deg, #f00f91 0%,#9a03d9 100%)'},
    {cor: 'linear-gradient(135deg, #5b247a 0%,#1bcedf 100%)'},
    {cor: 'linear-gradient(135deg, #f2d50f 0%,#da0641 100%)'},
    {cor: 'linear-gradient(135deg, #F5515F 0%,#A1051D 100%)'},
    {cor: 'linear-gradient(135deg, #fad961 0%,#f76b1c 100%)'},
    {cor: 'linear-gradient(135deg, #7117ea 0%,#ea6060 100%)'},
    {cor: 'linear-gradient(135deg, #17ead9 0%,#6078ea 100%)'},
    {cor: 'linear-gradient(135deg, #65799b 0%,#5e2563 100%)'},
    {cor: 'linear-gradient(135deg, #F5515F 0%,#A1051D 100%)'},
    {cor: 'linear-gradient(135deg, #E3E3E3 0%,#5D6874 100%)'},
    {cor: 'linear-gradient(135deg, #184e68 0%,#57ca85 100%)'},
    {cor: 'linear-gradient(135deg, #c3ec52 0%,#0ba29d 100%)'},
  ];

  mostrarDetalhes = false;
  disciplinas: TurmaDisciplina[] = [];
  disciplinaSelecionada: TurmaDisciplina;
  materiais: MaterialAula[] = [];
  atividades: AlunoAtividade[] = [];


  constructor(private alunoService: AlunoService,
              private spinner: NgxSpinnerService,
              public authService: AuthService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    if (this.authService.check()) {
      this.buscarDisciplinas();
    }
  }

  // tslint:disable-next-line:typedef
  buscarDisciplinas() {
    this.spinner.show();
    this.alunoService.buscarDisciplinasPorAluno(this.authService.getUser().id).subscribe((data) => {
        console.log(data);
        this.disciplinas = data;
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      });
  }

  // tslint:disable-next-line:typedef
  buscarMaterialAula(disciplinaSelecionada: TurmaDisciplina) {
    this.spinner.show();
    this.buscarAtividade(disciplinaSelecionada);
    // 3161
    this.alunoService.buscarMateriaAula(disciplinaSelecionada.id).subscribe((data) => {
        console.log(data);
        this.materiais = data;
        this.disciplinaSelecionada = disciplinaSelecionada;
        this.mostrarDetalhes = true;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.alertService.warning(error.error.mensagemUsuario);
      });
  }

  // tslint:disable-next-line:typedef
  buscarAtividade(disciplinaSelecionada: TurmaDisciplina){
    this.alunoService.buscarAtividade(this.authService.getUser().id, disciplinaSelecionada.id).subscribe((data) => {
        console.log(data);
        this.atividades = data;
      },
      (error) => {
        console.log(error);
      });
  }

  // tslint:disable-next-line:typedef
  voltar() {
    this.mostrarDetalhes = false;
  }
}
