import {Endereco} from './Endereco';
import {Contato} from './Contato';
import {Cidade} from './Cidade';
import {Sexo} from './Sexo';


export class Aluno {
  id: number;
  idPais: number;
  uc: number;
  codigoInep: string;
  codigoNis: string;
  nome: string;
  nascimento: Date;
  nDependentes: number;
  mae: string;
  pai: string;
  conjuge: string;
  justificativa: string;
  sus: string;
  altasHabilidades: string;
  endereco: Endereco;
  contato: Contato;
  cidade: Cidade;
  cidadeNascimento: Cidade;
  sexo: Sexo;
  bolsaFamilia: boolean;
  // continuar
}
