import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class RelatoriosService {
  private URL = environment.API_RELATORIO_URL + '/relatorios';

  constructor(private http: HttpClient) {}

  // /totalizacaoMatriculasEscolas/instituicao/pdf/2017?idInstituicao=1
  totalizacaoMatriculasEscola(ano, idInstituicao): Observable<Blob> {
    return this.http
      .get<Blob>(
        `${this.URL}/totalizacaoMatriculasEscolas/instituicao/pdf/${ano}?idInstituicao=${idInstituicao}`,
        { observe: 'body', responseType: 'blob' as 'json' }
      )
      .pipe(take(1));
  }

  boletimAluno(idMatricula): Observable<Blob> {
    return this.http
      .get<Blob>(`${this.URL}/boletim/aluno/pdf?idMatricula=${idMatricula}`, {
        observe: 'body',
        responseType: 'blob' as 'json',
      })
      .pipe(take(1));
  }

  //  Escola #############################################
  // relatorios/cargaHoraria/escolas/pdf/2017?idInstituicao=1&idUnidade=108
  cargaHoraria(ano, idInstituicao, idUnidade): Observable<Blob> {
    return this.http
      .get<Blob>(
        `${this.URL}/cargaHoraria/escolas/pdf/${ano}?idInstituicao=${idInstituicao}&idUnidade=${idUnidade}`,
        { observe: 'body', responseType: 'blob' as 'json' }
      )
      .pipe(take(1));
  }

  professoresETurmas(ano, idInstituicao, idUnidade): Observable<Blob> {
    return this.http
      .get<Blob>(
        `${this.URL}/professoresEturmas/escolas/pdf/${ano}?idInstituicao=${idInstituicao}&idUnidade=${idUnidade}`,
        { observe: 'body', responseType: 'blob' as 'json' }
      )
      .pipe(take(1));
  }

  turmas(ano, idInstituicao, idUnidade): Observable<Blob> {
    return this.http
      .get<Blob>(
        `${this.URL}/turmasPorEscola/escolas/pdf/${ano}?idInstituicao=${idInstituicao}&idUnidade=${idUnidade}`,
        { observe: 'body', responseType: 'blob' as 'json' }
      )
      .pipe(take(1));
  }

  turmasPorGrade(ano, idInstituicao, idUnidade): Observable<Blob> {
    return this.http
      .get<Blob>(
        `${this.URL}/turmasPorGrade/escolas/pdf/${ano}?idInstituicao=${idInstituicao}&idUnidade=${idUnidade}`,
        { observe: 'body', responseType: 'blob' as 'json' }
      )
      .pipe(take(1));
  }

  turmasSemProfessor(ano, idInstituicao, idUnidade): Observable<Blob> {
    return this.http
      .get<Blob>(
        `${this.URL}/turmasSemProfessor/escolas/pdf/${ano}?idInstituicao=${idInstituicao}&idUnidade=${idUnidade}`,
        { observe: 'body', responseType: 'blob' as 'json' }
      )
      .pipe(take(1));
  }

  //   Professor

  cargaHorariaProf(ano, idInstituicao, idProfessor): Observable<Blob> {
    return this.http
      .get<Blob>(
        `${this.URL}/cargaHoraria/professores/pdf/${ano}/${idProfessor}?idInstituicao=${idInstituicao}`,
        { observe: 'body', responseType: 'blob' as 'json' }
      )
      .pipe(take(1));
  }

  //   Turma

  // 2017?etapaEnsino=32&idInstituicao=1&idUnidade=36&mediacao=1&modalidade=1&nivelEnsino=3
  turmaPorGradeCompleto(
    ano,
    idInstituicao,
    etapaEnsino,
    mediacao,
    modalidade,
    nivelEnsino,
    idUnidade
  ): Observable<Blob> {
    let url = `${this.URL}/turmasPorGrade/turmas/pdf/${ano}/?idInstituicao=${idInstituicao}&mediacao=${mediacao}&modalidade=${modalidade}&nivelEnsino=${nivelEnsino}`;

    if (etapaEnsino !== null) {
      url += `&etapaEnsino=${etapaEnsino}`;
    }
    if (idUnidade !== null) {
      url += `&idUnidade=${idUnidade}`;
    }

    console.log(url);

    return this.http
      .get<Blob>(`${url}`, { observe: 'body', responseType: 'blob' as 'json' })
      .pipe(take(1));
  }

  // relatorios/redimentoEscolar/turmas/pdf/Rendimento Escolar?anoLetivo=2017&etapaEnsino=32&idInstituicao=1&idUnidade=36
  // &mediacao=1&modalidadeEnsino=1&nivelEnsino=3
  rendimentoTurma(
    ano,
    idInstituicao,
    etapaEnsino,
    mediacao,
    modalidade,
    nivelEnsino,
    idUnidade
  ): Observable<Blob> {
    let url = `${this.URL}/redimentoEscolar/turmas/pdf/Rendimento Escolar?anoLetivo=${ano}&idInstituicao=${idInstituicao}&mediacao=${mediacao}&modalidadeEnsino=${modalidade}&nivelEnsino=${nivelEnsino}`;

    if (etapaEnsino !== null) {
      url += `&etapaEnsino=${etapaEnsino}`;
    }
    if (idUnidade !== null) {
      url += `&idUnidade=${idUnidade}`;
    }

    console.log(url);

    return this.http
      .get<Blob>(`${url}`, { observe: 'body', responseType: 'blob' as 'json' })
      .pipe(take(1));
  }
}
