<app-header-aluno></app-header-aluno>
<div class="container mt-4">
  <mat-tab-group>
    <mat-tab label="Disciplinas">
      <app-disciplinas></app-disciplinas>
    </mat-tab>
    <mat-tab label="Acompanhamento Escolar">
      <app-acompanhamento></app-acompanhamento>
    </mat-tab>
    <mat-tab label="Horários">
      <app-horario></app-horario>
    </mat-tab>
    <mat-tab label="Matrícula Online">
      <app-aluno-matricula></app-aluno-matricula>
    </mat-tab>
  </mat-tab-group>
</div>
