import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UsuarioDTO} from '../../../model/UsuarioDTO';
import {PerfilInstituicaoDTO} from '../../../model/PerfilInstituicaoDTO';
import {MatDialogRef} from '@angular/material/dialog';
import {AlertService} from '@full-fledged/alerts';

@Component({
  selector: 'app-selecionar-instituicao',
  templateUrl: './selecionar-instituicao.component.html',
  styleUrls: ['./selecionar-instituicao.component.scss']
})
export class SelecionarInstituicaoComponent implements OnInit {
  gestor: UsuarioDTO = new UsuarioDTO();
  perfilInstituicoes: PerfilInstituicaoDTO = new PerfilInstituicaoDTO();

  constructor(public authService: AuthService,
              public dialogRef: MatDialogRef<SelecionarInstituicaoComponent>,
              private alertService: AlertService,
  ) {
    this.gestor = this.authService.getGestor();
  }

  ngOnInit(): void {
  }

  selecionar() {
    console.log(this.perfilInstituicoes);
    if (this.perfilInstituicoes === undefined) {
      this.alertService.warning('Selecione a instituição (perfil)');
    } else {
      localStorage.setItem('instituicao', btoa(JSON.stringify(this.perfilInstituicoes.instituicao)));
      localStorage.setItem('perfil', btoa(JSON.stringify(this.perfilInstituicoes.perfil)));
      this.dialogRef.close();
    }
  }

}
