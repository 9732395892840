import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ModalFiltroInstituicaoComponent} from '../../../pages/modal/modal-filtro-instituicao/modal-filtro-instituicao.component';
import {MonitoramentoService} from '../../../services/monitoramento.service';
import {AlertService} from '@full-fledged/alerts';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {EscolaMonitoramento} from '../../../model/EscolaMonitoramento';
import {MatPaginator} from '@angular/material/paginator';
import {BuscaEscolaMonitoramentoFilter} from '../../../model/BuscaEscolaMonitoramentoFilter';
import {FormControl} from '@angular/forms';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-escola',
  templateUrl: './escola.component.html',
  styleUrls: ['./escola.component.scss']
})
export class EscolaComponent implements AfterViewInit {

  displayedColumns: string[] = ['inep', 'nome', 'turmas', 'professores', 'totalProfessores', 'vagas',  'vagasDisponiveis', 'matriculas', 'porcentagemOcupada', 'deficitProfessor'];
  dataSource = new MatTableDataSource<EscolaMonitoramento>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  escolasMonitoramento: EscolaMonitoramento[] = [];
  filtroEscolaMonitoramento = new BuscaEscolaMonitoramentoFilter();

  indicadoresForm = new FormControl();
  exibirColunas: boolean[] = [true, true, true, true, true, true, true];

  indicadores: string[] = ['Professores em sala', 'Professores necessários', 'Vagas', 'Matrículas', 'Vagas Disponiveis', 'Ocupação', 'Défict professor'];
  constructor(private monitoramentoService: MonitoramentoService,
              private alertService: AlertService,
              private spinner: NgxSpinnerService, public dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Exibição';
    // this.buscarEscolas();
  }

  abrirFiltro() {
    const dialogRef = this.dialog.open(ModalFiltroInstituicaoComponent, {
      panelClass: 'dialog-responsive',
      data: 2,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.filtroEscolaMonitoramento = result;
        console.log(this.filtroEscolaMonitoramento);
        this.buscarEscolas();
      }
    });
  }

  selecaodeIndicadores(ev) {
    console.log(ev.source.value);
    switch (ev.source.value) {
      case 'Professores em sala':
        this.exibirColunas[0] = !this.exibirColunas[0];
        break;
      case 'Professores necessários':
        this.exibirColunas[1] = !this.exibirColunas[1];
        break;
      case 'Vagas':
        this.exibirColunas[2] = !this.exibirColunas[2];
        break;
      case 'Matrículas':
        this.exibirColunas[3] = !this.exibirColunas[3];
        break;
      case 'Vagas Disponiveis':
        this.exibirColunas[4] = !this.exibirColunas[4];
        break;
      case 'Ocupação':
        this.exibirColunas[5] = !this.exibirColunas[5];
        break;
      case 'Défict professor':
        this.exibirColunas[6] = !this.exibirColunas[6];
        break;

    }
  }

  buscarEscolas() {
    this.spinner.show();
    console.log('O que esta sendo enviado');
    console.log(this.filtroEscolaMonitoramento);
    this.monitoramentoService.buscarEscolaMonitoramentoFiltro(this.filtroEscolaMonitoramento).subscribe((data) => {
      this.spinner.hide();
      console.log('Chegou da api');
      console.log(data);
      this.escolasMonitoramento = data;
      this.dataSource.data = data;
      console.log('data');
      console.log(data);
      this.paginator._intl.itemsPerPageLabel = 'Exibição';

      if (!this.escolasMonitoramento.length) {
        this.alertService.warning('Nenhuma escola encontrada');
      }
    }, (error) => {
      this.spinner.hide();
      this.alertService.danger('Ocorreu um erro. Tente mais tarde!');
      console.log('Deu erro;');
      console.log(error);
    });
  }

}
