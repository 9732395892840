import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AlertModule } from '@full-fledged/alerts';
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrMaskerModule } from 'br-mask';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ComponentesModule } from '../componentes/componentes.module';
import { TemplateModule } from '../template/template.module';
import { AcompanhamentoComponent } from './acompanhamento/acompanhamento.component';
import { AlunoMatriculaComponent } from './aluno-matricula/aluno-matricula.component';
import { DetalheDisciplinaComponent } from './disciplinas/detalhe-disciplina/detalhe-disciplina.component';
import { DisciplinasComponent } from './disciplinas/disciplinas.component';
import { EtutorComponent } from './etutor/etutor.component';
import { HomeComponent } from './home/home.component';
import { HorarioComponent } from './horario/horario.component';
import { LoginGestorComponent } from './login-gestor/login-gestor.component';
import { CadastroAlunoComponent } from './login/cadastro-aluno/cadastro-aluno.component';
import { LoginComponent } from './login/login.component';
import { MonitoramentoComponent } from './monitoramento/monitoramento.component';
import { PerfilComponent } from './perfil/perfil.component';
import { PortalAlunoComponent } from './portal-aluno/portal-aluno.component';
import { TurmasMonitoramentoComponent } from './turmas-monitoramento/turmas-monitoramento.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [HomeComponent, DisciplinasComponent, PortalAlunoComponent,
    HorarioComponent, AcompanhamentoComponent, DetalheDisciplinaComponent,
    LoginComponent, PerfilComponent, EtutorComponent, LoginGestorComponent,
    MonitoramentoComponent, TurmasMonitoramentoComponent, AlunoMatriculaComponent, CadastroAlunoComponent],
  exports: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot(),
    // Angular Material
    MatTabsModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAofSgPCKs-NUy2_AVLw-opQiPm75wR030',
      libraries: ['places']
    }),
    RouterModule,
    TemplateModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    FormsModule,
    ComponentesModule,
    BrMaskerModule,
    MatMenuModule,
    NgSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule,
    SweetAlert2Module.forRoot()
  ]
})
export class PagesModule {
}
