import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EscolaMonitoramentoComponent} from './escola-monitoramento/escola-monitoramento.component';
import {RelatorioComponent} from './relatorio/relatorio.component';
import {MatMenuModule} from '@angular/material/menu';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertModule} from '@full-fledged/alerts';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {InstituicaoComponent} from './escola-monitoramento/instituicao/instituicao.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {TurmaComponent} from './escola-monitoramento/turma/turma.component';
import {EscolaComponent} from './escola-monitoramento/escola/escola.component';
import {ProfessorComponent} from './escola-monitoramento/professor/professor.component';
import {AlunoComponent} from './escola-monitoramento/aluno/aluno.component';
import {MatSortModule} from '@angular/material/sort';
import {RTurmasComponent} from './relatorio/r-turmas/r-turmas.component';
import { MatriculaComponent } from './escola-monitoramento/matricula/matricula.component';


@NgModule({
  declarations: [EscolaMonitoramentoComponent, RelatorioComponent, InstituicaoComponent, TurmaComponent, EscolaComponent, ProfessorComponent, AlunoComponent,  RTurmasComponent, MatriculaComponent],
  exports: [
    EscolaMonitoramentoComponent,
    RelatorioComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    FormsModule,
    AlertModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    DragDropModule,
    MatChipsModule,
    MatIconModule,
    MatSortModule
  ]
})
export class ComponentesModule {
}
