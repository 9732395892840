import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialAula} from '../../../model/MaterialAula';
import {MatDialog} from '@angular/material/dialog';
import {ModalAtividadeComponent} from '../../modal/modal-atividade/modal-atividade.component';
import {TurmaDisciplina} from '../../../model/TurmaDisciplina';
import {AlunoAtividade} from '../../../model/AlunoAtividade';
import {AlunoService} from '../../../services/aluno.service';
import {AuthService} from '../../../services/auth.service';
import {AlertService} from '@full-fledged/alerts';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-detalhe-disciplina',
  templateUrl: './detalhe-disciplina.component.html',
  styleUrls: ['./detalhe-disciplina.component.scss']
})
export class DetalheDisciplinaComponent implements OnInit {

  @Input() materiais: MaterialAula[] = [];
  @Input() disciplinaSelecionada: TurmaDisciplina;
  @Input() atividades: AlunoAtividade[] = [];

  @Output() evento: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog,
              private alunoService: AlunoService,
              private authService: AuthService,
              private alertService: AlertService,
              private spinner: NgxSpinnerService) {
  }


  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  getAtividades() {

  }

  // tslint:disable-next-line:typedef
  voltar() {
    this.evento.emit(true);
  }

  // tslint:disable-next-line:typedef
  openDialog(atividade: AlunoAtividade) {
    const dialogRef = this.dialog.open(ModalAtividadeComponent, {
      panelClass: 'dialog-responsive',
      data: atividade
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result) {
        // this.alertService.success('Resposta enviada com sucesso!!!');
        this.buscarAtividade(this.disciplinaSelecionada);
      }
    });
  }

  // tslint:disable-next-line:typedef
  buscarAtividade(disciplinaSelecionada: TurmaDisciplina) {
    this.spinner.show();
    this.alunoService.buscarAtividade(this.authService.getUser().id, disciplinaSelecionada.id).subscribe((data) => {
        console.log(data);
        this.atividades = data;
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      });
  }

  // tslint:disable-next-line:typedef
  downloadMaterial(idMaterial, filename) {
    this.spinner.show();
    this.alunoService.downloadMaterial(idMaterial).subscribe((response) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      });
  }
}
