<header>
  <div class="container container-header">
    <div id="first-partHeader">
      <div id="logo">
        <a href="#">e-tutor</a>
      </div>
    </div>
    <div id="navbar">
      <nav>
        <div class="nav ">
          <a class="nav-item nav-link" routerLink="/monitoramento" [class.active]="tabSelect === 1">
            <i class="fas fa-arrow-left mr-3 "></i>Voltar
          </a>
<!--          <a class="nav-item nav-link " [class.active]="tabSelect === 2" (click)="tabSelect =2">Relatório</a>-->
        </div>
      </nav>
    </div>
    <div id="userName" class="second-partHeader">

      <div>


        <a [matMenuTriggerFor]="menu"> {{authService.getGestor().usuario.nome}}
          <i id="arrow" class="fas fa-chevron-down"></i></a>
        <mat-menu #menu="matMenu" class="bg-dark">
          <button mat-menu-item style="color: white">
            <span>Sair</span>
          </button>
        </mat-menu>
      </div>

    </div>
  </div>
</header>
<div class="bg-white bg-body">
  <div class="container container-body mt-5">

    <div class="row pt-5">

      <div class="col-8">
        <h2 >ESCOLA {{this.escola.nome}}</h2>
      </div>
      <div class="col-4">
        <p class="float-right">TOTAL DE TURMAS: {{this.escola.turmas}}</p>
      </div>

    </div>

    <hr/>
    <div class="float-right mb-3">
      <button class="btn btn-secondary" [matMenuTriggerFor]="filtro" aria-label="Example icon-button with a menu">
        <i class="fas fa-filter"></i>
      </button>
      <mat-menu #filtro="matMenu">
        <button mat-menu-item (click)="ordenarMenorOcupaca()">
          <span>Menor Saldo de Vagas</span>
        </button>
        <button mat-menu-item (click)="ordenarMaiorOcupaca()">
          <span>Maior Saldo de Vagas</span>
        </button>
      </mat-menu>
    </div>

    <div class="row" style="clear: both" *ngIf="turmasMonitoramento.length">
      <div class="col-md-4 col-12" *ngFor="let turma of turmasMonitoramento">
        <div class="school-card ">
          <div class="card-header">
            <h2>{{turma.turma}}</h2>
          </div>
          <div class="card-body">
            <div class="card-info first-info">
              <p>TURNO:</p>
              <p>{{turma.turno}}</p>
            </div>
            <div class="card-info">
              <p>VAGAS: </p>
              <p>{{turma.vagas}}</p>
            </div>
            <div class="card-info">
              <p>MATRICULADOS: </p>
              <p>{{turma.matriculados}}</p>
            </div>
            <div class="card-info">
              <p>SALDO DE VAGAS: </p>
              <p>{{turma.saldo}}</p>
            </div>
          </div>
          <div class="card-footer">
            <button (click)="detalhesTurma()">Detalhes</button>
          </div>
        </div>
        <!-- Card -->
      </div>
    </div>


  </div>
</div>
<ff-alerts></ff-alerts>
