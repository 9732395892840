import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-faltas',
  templateUrl: './modal-faltas.component.html',
  styleUrls: ['./modal-faltas.component.scss']
})
export class ModalFaltasComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalFaltasComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    console.log(data);
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

}
