<!-- Cabeçalho principal -->
<div id="header" class="container">
  <div class="img-fundo-header">

  </div>
</div>
<div class="footer-of-header container">
  <div class="left-part-of-footer">
    <!-- img do aluno -->
    <div class="img-profile">
      <img *ngIf="!fotoBase64" class="perfil" src="assets/img/graduated.png" alt="image of profile">
      <img *ngIf="fotoBase64" class="perfil" [src]="'data:image/jpg;base64,' + fotoBase64" alt="image of profile">

    </div>
    <!-- Nome do aluno -->
    <div class="name-profile">
      <h1>{{authService.getUser().nome}}</h1>

      <div *ngIf="this.route !== '/perfil'" id="perfil" routerLink="/perfil" ><a >Meu Perfil</a></div>
      <div *ngIf="this.route == '/perfil'" id="disciplina" routerLink="/portal" ><a >Minhas disciplinas</a></div>
    </div>
  </div>
  <!-- notificações e menu do cabeçalho -->
  <div class="right-part-of-footer">
    <!-- Voltar para a paging de login -->
    <div id="exit" (click)="sair()">
      <a >Sair</a>
    </div>
  </div>
</div>
