<mat-toolbar>
  <span class="text-uppercase">
    <i class="fas fa-filter"></i> Filtros
  </span>
  <span class="spacer"></span>
  <a (click)="close()" class="cursor"><i class="fas fa-times"></i></a>
</mat-toolbar>

<mat-dialog-content>
  <div class="mt-3 row">
    <div class="form-group col-md-3">
      <label for="anoLetivo">ANO: <span class="color-red">*</span></label>
      <select id="anoLetivo" [(ngModel)]="filtro.ano" class="form-control labelFilter">
        <option *ngFor="let ano of anosLetivos" [value]="ano">{{ano}}</option>
      </select>
    </div>
    <div class="form-group col-md-9">
      <label for="anoLetivo">ESCOLA:</label>
      <select [(ngModel)]="filtro.escola" id="anoLetivo" (ngModelChange)="onChangeEscola($event)"
        class="form-control labelFilter">
        <option *ngFor="let escola of listaEscolas" [value]="escola.id">{{escola.nome}}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="zona">MODALIDADE:</label>
      <select id="zona" [(ngModel)]="filtro.modalidade" (ngModelChange)="onChangeModalidade($event)"
        class="form-control labelFilter">
        <option *ngFor="let modalidade of modalidades" [value]="modalidade.id">{{ modalidade.descricao }}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="situacaoFuncional">NIVEL: </label>
      <select id="situacaoFuncional" [(ngModel)]="filtro.nivel" (ngModelChange)="onChangeNivel($event)"
        class="form-control labelFilter">
        <option *ngFor="let nivel of niveis" [value]="nivel.id">{{ nivel.descricao }}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="turma">ETAPA:</label>
      <select id="turma" class="form-control labelFilter" [(ngModel)]="filtro.etapa">
        <option *ngFor="let etapa of etapas" [value]="etapa.id">{{ etapa.descricao }}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="turma">TURNO:</label>
      <select id="turma" [(ngModel)]="filtro.turno" class="form-control labelFilter">
        <option *ngFor="let turno of turnos" [value]="turno.id">{{turno.descricao}}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label class="d-block" for="turma">VAGAS: <span class="color-red">*</span></label>
      <mat-radio-group color="primary" [(ngModel)]="filtro.comVaga" aria-label="Select an option">
        <mat-radio-button class="d-block" value="2">TODAS</mat-radio-button>
        <mat-radio-button class="d-block" value="1">SEM VAGAS</mat-radio-button>
        <mat-radio-button class="d-block" value="3">COM VAGAS</mat-radio-button>

      </mat-radio-group>
      <!-- <input type="radio" name="" id="">
      <input type="radio" name="" id="">
      <select id="turma" class="form-control labelFilter">
        <option selected="selected" value="TODAS">ESCOLA COM/SEM VAGAS</option>
        <option value="COM_TURMA">ESCOLA COM VAGAS</option>
        <option value="SEM_TURMA">ESCOLA SEM VAGAS</option>
      </select> -->
    </div>
  </div>
</mat-dialog-content>

<hr />

<button type="button" (click)="aplicar()" class="btn btn-filtro">
  Aplicar
</button>

<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>