import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '@full-fledged/alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { BuscaEscolaMonitoramentoFilter } from 'src/app/model/BuscaEscolaMonitoramentoFilter';
import { EscolaMonitoramento } from 'src/app/model/EscolaMonitoramento';
import { FiltroMatriculaResultado } from 'src/app/model/FiltroMatriculaResul.model';
import { ModalMatriculaComponent } from 'src/app/pages/modal/modal-matricula/modal-matricula.component';
import { MonitoramentoService } from 'src/app/services/monitoramento.service';

@Component({
  selector: 'app-matricula',
  templateUrl: './matricula.component.html',
  styleUrls: ['./matricula.component.scss']
})
export class MatriculaComponent implements OnInit {
  displayedColumns: string[] = ['inep', 'escolas', 'modalidade', 'nivel', 'etapa', 'p-vagas', 'p-pre', 'p-matriculas'];

  dataSource = new MatTableDataSource<FiltroMatriculaResultado>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  escolasMonitoramento: EscolaMonitoramento[] = [];
  filtroEscolaMonitoramento = new BuscaEscolaMonitoramentoFilter();
  temEscola = true;

  filtro = {
    ano: null,
    escola: null,
    etapa: null,
    turno: null,
    comVaga: null,
    instituicao: null,
    modalidade: null,
    nivel: null
  };

  indicadoresForm = new FormControl();
  exibirColunas: boolean[] = [true, true, true];

  indicadores: string[] = ['Vagas', 'Pré Matriculas', 'Matrículas'];

  constructor(public dialog: MatDialog, private monitoramento: MonitoramentoService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Exibição';

  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  abrirFiltro() {
    const dialogRef = this.dialog.open(ModalMatriculaComponent, {
      panelClass: 'dialog-responsive',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.spinner.show();
        this.filtro = result;
        this.monitoramento.getPreMatriculas(result).subscribe(resp => {
          if (!(resp.length > 0)) {
            this.temEscola = false;
            this.dataSource = new MatTableDataSource();
            this.alertService.danger('Nenhum resultado encontrado!');
          } else {
            this.alertService.success('Sucesso!');
            this.temEscola = true;
            this.dataSource.data = resp;
          }
        }, (e) => { this.spinner.hide(); }, () => {
          this.spinner.hide();
        });
      }
    });
  }

  teste(ev) {
    console.log(ev.source.value);
    switch (ev.source.value) {
      case 'Vagas':
        this.exibirColunas[0] = !this.exibirColunas[0];
        break;
      case 'Pré Matriculas':
        this.exibirColunas[1] = !this.exibirColunas[1];
        break;
      case 'Matrículas':
        this.exibirColunas[2] = !this.exibirColunas[2];
        break;
    }
  }

}
