<mat-toolbar>
  <span class="text-uppercase">Faltas - {{data.disciplina.descricao}}</span>
  <span class="spacer"></span>
  <a (click)="close()" class="cursor"><i class="fas fa-times"></i></a>
</mat-toolbar>

<div class="mt-3 ">
  <table class="table table-bordered">
    <thead>
    <tr>
      <th scope="col" class="table-hearder">DATA</th>
      <th scope="col" class="table-hearder">QUANTIDADE DE FALTAS</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let falta of data.lista">

      <td *ngIf="falta.disciplina.id === data.disciplina.id && falta.quantidade > 0">
        {{falta.data  | date: 'dd/MM/yyyy'}}
      </td>

      <td *ngIf="falta.disciplina.id === data.disciplina.id && falta.quantidade > 0">
        {{falta.quantidade}}
      </td>
    </tr>

    </tbody>
  </table>

</div>
