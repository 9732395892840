<mat-toolbar>
  <span class="text-uppercase">
    <i class="fas fa-filter"></i> Filtros
  </span>
  <span class="spacer"></span>
  <a (click)="close(null)" class="cursor"><i class="fas fa-times"></i></a>
</mat-toolbar>

<div class="mt-3 row">
  <div class="form-group col-md-3">
    <label for="anoLetivo">ANO LETIVO: <span class="color-red">*</span></label>
    <select [(ngModel)]="anoSelecionado" (ngModelChange)="buscarEscolas()" id="anoLetivo"
            class="form-control labelFilter">
      <option *ngFor="let ano of filtros.anosLetivos" [ngValue]="ano">{{ano}}</option>
    </select>
  </div>
    <form [formGroup]="filtroForm" class="form-group col-md-9">
      <label for="nome_escola">ESCOLA: <span class="color-red">*</span></label>
      <input  type="text" class="form-control" id="nome_escola" placeholder="Nome da escola"
             formControlName="nomeEscola" name="nome_escola" required [matAutocomplete]="autoGroupEscola">
      <div *ngIf="anoSelecionado && filtroForm.controls['nomeEscola'].disabled ">
        <small class="color-red">Nenhuma escola com turmas encontrada no ano selecionado</small>
      </div>
    </form>

    <mat-autocomplete #autoGroupEscola="matAutocomplete">
      <mat-option *ngFor="let unidade of escolas" [value]="unidade.nome"
                  (onSelectionChange)="selecionarUnidadeEnsino(unidade)"
                  >
        {{unidade.nome}}
      </mat-option>
    </mat-autocomplete>
</div>

<hr/>

<button type="button" [disabled]="!escolaSelecionada.id" class="btn btn-filtro" (click)="close(escolaSelecionada)">
  Aplicar
</button>
<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
