export class EscolaMonitoramento {
  id: number;
  inep: string;
  nome: string;
  turmas: number;
  totalProfessores: number;
  professores: number;
  vagas: number;
  matriculas: number;
  ano: number;
  porcentagemOcupada: number;
  deficitProfessor: number;
  vagasDisponiveis: number;
}
