<mat-toolbar>
  <span class="text-uppercase">
    <i class="fas fa-filter"></i> Filtros
  </span>
  <span class="spacer"></span>
  <a (click)="close(null)" class="cursor"><i class="fas fa-times"></i></a>
</mat-toolbar>

<mat-dialog-content>
<div class="mt-3 row">
    <div class="form-group col-md-6">
      <label for="anoLetivo">ANO LETIVO: <span class="color-red">*</span></label>
      <select [(ngModel)]="filtroEscolaMonitoramento.ano" id="anoLetivo" class="form-control labelFilter">
        <option *ngFor="let ano of filtros.anosLetivos" [ngValue]="ano">{{ano}}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="zona">ZONA:</label>
      <select [(ngModel)]="filtroEscolaMonitoramento.zona" id="zona" class="form-control labelFilter">
        <option selected [ngValue]="null">TODAS</option>
        <option *ngFor="let zona of filtros.zonas" [ngValue]="zona">{{zona.descricao}}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="situacaoFuncional">SITUAÇÃO FUNCIONAL: </label>
      <select [(ngModel)]="filtroEscolaMonitoramento.situacao" id="situacaoFuncional"
              class="form-control labelFilter">
        <option selected [ngValue]="null">TODAS</option>
        <option *ngFor="let situacao of filtros.situacoes" [ngValue]="situacao">{{situacao.descricao}}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="turma">TURMA:</label>
      <select [(ngModel)]="filtroEscolaMonitoramento.turma" id="turma" class="form-control labelFilter">
        <option selected="selected" value="TODAS">COM/SEM TURMAS</option>
        <option value="COM_TURMA">COM TURMAS</option>
        <option value="SEM_TURMA">SEM TURMAS</option>
      </select>
    </div>
    <!--   TODO: trafazer a informação da api-->
    <div class="form-group col-md-6" *ngIf="data == 2">
      <label for="turma">PROGRAMA EDUCACIONAL:</label>


<!--      <ng-select-->
<!--        [items]="toppingList"-->
<!--        [multiple]="true"-->
<!--        bindLabel="name"-->
<!--        [selectableGroup]="true"-->
<!--        [closeOnSelect]="false"-->
<!--        bindValue="id"-->
<!--        [(ngModel)]="toppings">-->
<!--        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">-->
<!--          <input  type="checkbox" [ngModel]="item$.selected"/> {{item | uppercase}}-->
<!--        </ng-template>-->
<!--        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">-->
<!--          <input type="checkbox" [ngModel]="item$.selected"/> {{item}}-->
<!--        </ng-template>-->
<!--      </ng-select>-->
      <ng-select
        [items]="programaEducacional"
        [multiple]="true"
        bindLabel="name"
        appendTo="body"
        [selectableGroup]="true"
        [closeOnSelect]="false"
        [(ngModel)]="programasSelecionado">
        <ng-template ng-multi-label-tmp>
          <div class="" *ngIf="programasSelecionado.length">
            <span class="">Seleção: {{programasSelecionado.length}}</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <input #allSelected  type="checkbox" (click)="toggleSelecaoPE()" /> Todos
        </ng-template>

        <!--        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" >-->
<!--          <input  type="checkbox" [ngModel]="item$.selected"/> {{item | uppercase}}-->
<!--        </ng-template>-->

        <ng-template ng-option-tmp let-item="item" let-item$="item$">
          <input type="checkbox" [ngModel]="item$.selected"/> {{item}}
        </ng-template>
      </ng-select>

    </div>

<!--    <div class="form-group col-md-6" *ngIf="data == 2">-->
<!--      <label for="turma">PROGRAMA EDUCACIONAL:</label>-->
<!--      <select [(ngModel)]="filtroEscolaMonitoramento.progEducacional" id="prog_educacional"-->
<!--              class="form-control labelFilter">-->
<!--        <option selected="selected" value="TODAS"></option>-->
<!--        <option [ngValue]="1">BRASIL ALFABETIZADO</option>-->
<!--        <option [ngValue]="2">ACELERA BRASIL</option>-->
<!--      </select>-->
<!--    </div>-->
    <div class="form-group col-md-6" *ngIf="data == 2">
      <label for="turma">TIPO DE TURMAS:</label>
      <ng-select
        [items]="tiposTurma"
        [multiple]="true"
        bindLabel="name"
        appendTo="body"
        dropdownPosition="bottom"
        [selectableGroup]="true"
        [closeOnSelect]="false"
        [(ngModel)]="tiposTurmaSelecionado">
        <ng-template ng-multi-label-tmp>
          <div class="" *ngIf="tiposTurmaSelecionado.length">
            <span class="">Seleção: {{tiposTurmaSelecionado.length}}</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <input #allSelectedTurma  type="checkbox" (click)="toggleSelecaoTurmas()" /> Todos
        </ng-template>

        <!--        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" >-->
        <!--          <input  type="checkbox" [ngModel]="item$.selected"/> {{item | uppercase}}-->
        <!--        </ng-template>-->

        <ng-template ng-option-tmp let-item="item" let-item$="item$">
          <input type="checkbox" [ngModel]="item$.selected"/> {{item.descricao}}
        </ng-template>
      </ng-select>
<!--      <select [(ngModel)]="filtroEscolaMonitoramento.tipoTurma" id="tipo_turma" class="form-control labelFilter">-->
<!--        <option selected="selected" [ngValue]="null">TODAS</option>-->
<!--        <option [ngValue]="3">ATIVIDADE COMPLEMENTAR</option>-->
<!--        <option [ngValue]="4">ATENDIMENTO EDUCACIONAL ESPECIALIZADO</option>-->
<!--      </select>-->
    </div>
    <div class="form-group col-md-6" *ngIf="data == 2">
      <label for="turma">VAGAS:</label>
      <select [(ngModel)]="filtroEscolaMonitoramento.vagasDisponivel" id="vagas" class="form-control labelFilter">
        <option selected="selected" [ngValue]="null">TODAS</option>
        <option [ngValue]="true">DISPONÍVEL</option>
        <option [ngValue]="false">INDISPONÍVEL</option>
      </select>
    </div>
    <div class="form-group col-md-6" *ngIf="data == 2">
      <label for="turma">DÉFICIT:</label>
      <select [(ngModel)]="filtroEscolaMonitoramento.deficitProfessor" id="disciplina" class="form-control labelFilter">
        <option selected="selected" [ngValue]="null">COM/SEM DÉFICIT DE PROFESSORES</option>
        <option [ngValue]="true">COM DÉFICIT DE PROFESSORES</option>
        <option [ngValue]="false">SEM DÉFICIT DE PROFESSORES</option>
      </select>
    </div>
</div>
</mat-dialog-content>

<hr/>

<button type="button" class="btn btn-filtro" (click)=" aplicarFiltro()">
  Aplicar
</button>
