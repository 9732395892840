import {Component, OnInit} from '@angular/core';
import {UsuarioDTO} from '../../model/UsuarioDTO';
import {Instituicao} from '../../model/Instituicao';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {UnidadeEscolarService} from '../../services/unidade-escolar.service';
import {EscolaMonitoramento} from '../../model/EscolaMonitoramento';
import {MonitoramentoService} from '../../services/monitoramento.service';
import {TurmaMonitoramento} from '../../model/TurmaMonitoramento';
import {AlertService} from '@full-fledged/alerts';

@Component({
  selector: 'app-turmas-monitoramento',
  templateUrl: './turmas-monitoramento.component.html',
  styleUrls: ['./turmas-monitoramento.component.scss']
})
export class TurmasMonitoramentoComponent implements OnInit {

  tabSelect = 1;
  gestor: UsuarioDTO = new UsuarioDTO();
  instituicao: Instituicao = new Instituicao();
  escola: EscolaMonitoramento;
  turmasMonitoramento: TurmaMonitoramento[] = [];

  constructor(private spinner: NgxSpinnerService,
              private router: Router,
              public authService: AuthService,
              public dialog: MatDialog,
              private monitoramentoService: MonitoramentoService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (localStorage.hasOwnProperty('escolaMonitoramento')) {
      this.escola = JSON.parse(localStorage.getItem('escolaMonitoramento'));
      this.buscarTurmasMonitoramento();
    } else {
      this.router.navigate(['/monitoramento']);
    }
  }

  buscarTurmasMonitoramento() {
    this.monitoramentoService.buscarTurmaMonitoramento(this.escola.ano).subscribe((data) => {
      this.turmasMonitoramento = data;
      console.log(this.turmasMonitoramento);
      if (!this.turmasMonitoramento.length) {
        this.alertService.warning('Nenhuma turma encontrada');
      }
    }, (error) => {
      this.alertService.danger('Ops, ocorreu um erro. Tente novamente mais tarde');
    });
  }

  ordenarMenorOcupaca() {
    this.turmasMonitoramento.sort((a, b) => {
      return a.saldo < b.saldo ? -1 : a.saldo > b.saldo ? 1 : 0;
    });
    this.alertService.info('Ordenação feita com sucesso!');
  }


  ordenarMaiorOcupaca() {
    this.turmasMonitoramento.sort((a, b) => {
      return a.saldo > b.saldo ? -1 : a.saldo < b.saldo ? 1 : 0;
    });
    this.alertService.info('Ordenação feita com sucesso!');
  }



  detalhesTurma(){
    this.alertService.info('Em desenvolvimento');

  }
}
