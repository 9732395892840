<div class="row m-0">
  <div class="form-group col-md-2 mt-3">
    <label for="anoLetivo">ANO LETIVO: <span class="color-red">*</span></label>
    <select
      [(ngModel)]="anoSelecionado"
      id="anoLetivo"
      class="form-control labelFilter"
    >
      <option *ngFor="let ano of anosLetivos" [ngValue]="ano">{{ ano }}</option>
    </select>
  </div>

  <div class="form-group col-md-2 mt-3">
    <label for="medicacao">MEDIAÇÃO: <span class="color-red">*</span></label>
    <select
      [(ngModel)]="medicaoSelecionada"
      id="medicacao"
      class="form-control labelFilter"
      (change)="buscarModalidade()"
    >
      <option *ngFor="let medicao of mediacoes" [ngValue]="medicao.id">
        {{ medicao.descricao }}
      </option>
    </select>
  </div>

  <div class="form-group col-md-2 mt-3" *ngIf="modalidades.length">
    <label for="mod">MODALIDADES: <span class="color-red">*</span></label>
    <select
      [(ngModel)]="modalidadeSelecionada"
      (change)="buscarNiveis()"
      id="mod"
      class="form-control labelFilter"
    >
      <option selected [ngValue]="null" disabled>Selecione</option>
      <option *ngFor="let mod of modalidades" [ngValue]="mod.id">
        {{ mod.descricao }}
      </option>
    </select>
  </div>

  <div class="form-group col-md-2 mt-3" *ngIf="niveis.length">
    <label for="nivel"
      >NÍVEIS DE ENSINO: <span class="color-red">*</span></label
    >
    <select
      [(ngModel)]="nivelSelecionada"
      (change)="buscarEtapas()"
      id="nivel"
      class="form-control labelFilter"
    >
      <option selected [ngValue]="null" disabled>Selecione</option>
      <option *ngFor="let nivel of niveis" [ngValue]="nivel.id">
        {{ nivel.descricao }}
      </option>
    </select>
  </div>

  <div class="form-group col-md-2 mt-3" *ngIf="etapas.length">
    <label for="etapa">ETAPAS DE ENSINO:</label>
    <select
      [(ngModel)]="etapaSelecionada"
      id="etapa"
      class="form-control labelFilter"
    >
      <option selected [ngValue]="null" disabled>Selecione</option>
      <option *ngFor="let etapa of etapas" [ngValue]="etapa.id">
        {{ etapa.descricao }}
      </option>
    </select>
  </div>

  <form
    [formGroup]="filtroForm"
    class="form-group col-8 mt-3"
    *ngIf="nivelSelecionada"
  >
    <label for="nome_escola">ESCOLA:</label>
    <input
      type="text"
      class="form-control"
      id="nome_escola"
      placeholder="Nome da escola"
      formControlName="nomeEscola"
      name="nome_escola"
      required
      [matAutocomplete]="autoGroupEscola"
    />
  </form>
  <mat-autocomplete #autoGroupEscola="matAutocomplete">
    <mat-option
      *ngFor="let unidade of unidadesEnsino"
      [value]="unidade.nome"
      (onSelectionChange)="selecionarUnidadeEnsino(unidade)"
    >
      {{ unidade.nome }}
    </mat-option>
  </mat-autocomplete>
</div>

<div class="box-relatorios">
  <div class="listaRelatorios">
    <ul>
      <li class="li-relatorios" (click)="turmaPorGradeCompleto()">
        <a><i class="fas fa-print mr-2"></i> Turmas por Grade</a>
      </li>
      <li class="li-relatorios" (click)="rendimentoTurma()">
        <a><i class="fas fa-print mr-2"></i> Rendimento</a>
      </li>
    </ul>
  </div>
</div>
<ngx-spinner type="ball-atom">
  <p style="color: white">Carregando...</p>
</ngx-spinner>
<!--<ff-alerts></ff-alerts>-->
