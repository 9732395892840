<div class="col-12 m-0 p-0">
  <label for="situacaoFuncional">SELECIONE A INSTITUIÇÃO (PERFIL)</label>
  <select [(ngModel)]="perfilInstituicoes"  id="situacaoFuncional"
          class="form-control labelFilter m-0 p-0">
    <option selected></option>
    <option  [ngValue]="instituicao" class="p-0 m-0" *ngFor="let instituicao of this.gestor.perfilInstituicoes">
      {{instituicao.instituicao.nomeFantasia}} ({{instituicao.perfil.descricao}})
    </option>
  </select>
</div>
<button [disabled]="!perfilInstituicoes.perfil" class="btn btn-primary float-right mt-3" (click)="selecionar()">Selecionar</button>
