<div class="bg-container">
  <div class="filters">
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-filtro" (click)="abrirFiltro()">
      + Adicionar filtro
    </button>
  </div>

  <div class="indicadoresVisu">
    <div class="filtros_selecionados">
      <mat-chip-list aria-label="Fish selection" *ngIf="filtroEscolaMonitoramento.ano">
        <mat-chip *ngIf="filtroEscolaMonitoramento.ano" >Ano: {{filtroEscolaMonitoramento.ano}}
        </mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.zona" >ZONA: {{filtroEscolaMonitoramento.zona.descricao}}
        </mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.situacao" >SITUAÇÃO: {{filtroEscolaMonitoramento.situacao.descricao}}
        </mat-chip>

        <!--         Com valores nulos é pq é todos-->
        <mat-chip *ngIf="!filtroEscolaMonitoramento.zona" >ZONA: TODAS
        </mat-chip>

        <mat-chip *ngIf="! filtroEscolaMonitoramento.situacao" >SITUAÇÃO: TODAS
        </mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.turma" >TURMA: {{filtroEscolaMonitoramento.turma}}
          <mat-icon matChipRemove *ngIf="filtroEscolaMonitoramento.turma != 'TODAS'" >cancel</mat-icon></mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.vagasDisponivel" >VAGAS DISPONÍVEIS
        </mat-chip>
        <mat-chip *ngIf="!filtroEscolaMonitoramento.vagasDisponivel" >VAGAS INDISPONÍVEIS
        </mat-chip>

        <mat-chip *ngIf="filtroEscolaMonitoramento.deficitProfessor" >COM DÉFICIT DE PROFESSORES
        </mat-chip>

        <mat-chip *ngIf="!filtroEscolaMonitoramento.deficitProfessor" >SEM DÉFICIT DE PROFESSORES
        </mat-chip>

        <div *ngIf="filtroEscolaMonitoramento.tiposTurma">
        <mat-chip  *ngFor="let tipo of filtroEscolaMonitoramento.tiposTurma">
          {{tipo.descricao}}
        </mat-chip>
        </div>

        <mat-chip *ngIf="filtroEscolaMonitoramento.progEducacionalId1" >
          BRASIL ALFABETIZADO
          <span></span>
        </mat-chip>
        <mat-chip *ngIf="filtroEscolaMonitoramento.progEducacionalId2" >
          ACELERA BRASIL
          <span></span>
        </mat-chip>


      </mat-chip-list>
    </div>
    <div class="col-md-4 col-6">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label  class="color-white">Indicadores para Visualização</mat-label>
        <mat-select [formControl]="indicadoresForm" multiple>
          <mat-select-trigger>
            {{indicadoresForm.value ? indicadoresForm.value[0] : ''}}
            <span *ngIf="indicadoresForm.value?.length > 1" class="example-additional-selection">
        (+{{indicadoresForm.value.length - 1}} {{indicadoresForm.value?.length === 2 ? 'outro' : 'outros'}})
      </span>
          </mat-select-trigger>
          <mat-option (onSelectionChange)="selecaodeIndicadores($event)"  *ngFor="let topping of indicadores" [value]="topping">{{topping}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Vai ser um select com check box -->
  </div>
</div>

<!--Tabeka-->

<h4  *ngIf="!filtroEscolaMonitoramento.ano"  class="mt-4">
  <i class="fas fa-exclamation-circle"></i> Adicione um filtro para que seja exibido os dados
</h4>

<h4  *ngIf="filtroEscolaMonitoramento.ano && !dataSource.data.length"  class="mt-4">
  <i class="fas fa-exclamation-circle"></i> Nenhuma escola encontrada!
</h4>

<div class="mat-elevation-z8 mt-4 " >



  <table  mat-table [dataSource]="dataSource"  class="table-striped" matSort>

    <!-- Position Column -->
    <ng-container matColumnDef="inep">
      <th  mat-header-cell *matHeaderCellDef> INEP </th>
      <td mat-cell *matCellDef="let element"> {{element.inep}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="nome">
      <th   class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> ESCOLAS </th>
      <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="turmas">
      <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> TURMAS </th>
      <td mat-cell *matCellDef="let element" >
      <span *ngIf="element.turmas"> {{element.turmas}} </span>
      <span *ngIf="!element.turmas"> 0 </span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="professores">
      <th mat-sort-header  class="text-center" mat-header-cell *matHeaderCellDef [hidden]="this.exibirColunas[0]"> PROFESSORES LOTADOS </th>
      <td mat-cell  *matCellDef="let element"  [hidden]="this.exibirColunas[0]">
        <span *ngIf="element.professores"> {{element.professores}} </span>
        <span *ngIf="!element.professores"> - </span></td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="totalProfessores">
      <th  mat-sort-header class="text-center" mat-header-cell *matHeaderCellDef [hidden]="this.exibirColunas[1]"> PROFESSORES NECESSÁRIOS </th>
      <td mat-cell  *matCellDef="let element" [hidden]="this.exibirColunas[1]">
        <span *ngIf="element.totalProfessores"> {{element.totalProfessores}}
        </span>
        <span *ngIf="!element.totalProfessores"> - </span>
      </td>

    </ng-container>


    <!-- Symbol Column -->
    <ng-container matColumnDef="matriculas">
      <th mat-sort-header class="text-center" mat-header-cell *matHeaderCellDef [hidden]="this.exibirColunas[3]"> MATRÍCULAS </th>
      <td mat-cell  *matCellDef="let element" [hidden]="this.exibirColunas[3]">
        <span *ngIf="element.matriculas"> {{element.matriculas}}
        </span>
        <span *ngIf="!element.matriculas"> - </span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="vagas">
      <th mat-sort-header class="text-center" mat-header-cell *matHeaderCellDef [hidden]="this.exibirColunas[2]"> VAGAS </th>
      <td mat-cell  *matCellDef="let element" [hidden]="this.exibirColunas[2]">
        <span *ngIf="element.vagas"> {{element.vagas}}
        </span>
        <span *ngIf="!element.vagas"> - </span>
      </td>
    </ng-container>



    <!-- Symbol Column -->
    <ng-container matColumnDef="vagasDisponiveis">
      <th mat-sort-header class="text-center" mat-header-cell *matHeaderCellDef [hidden]="this.exibirColunas[4]"> VAGAS DISPONÍVEIS </th>
      <td mat-cell  *matCellDef="let element" [hidden]="this.exibirColunas[4]">
        <span *ngIf="element.vagasDisponiveis"> {{element.vagasDisponiveis}}
        </span>
        <span *ngIf="!element.vagasDisponiveis"> - </span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
<!--    <ng-container matColumnDef="vagasDisponiveis">-->
<!--      <th mat-sort-header class="text-center" *matHeaderCellDef [hidden]="this.exibirColunas[4]"> VAGAS DISPONÍVEIS </th>-->
<!--      <td mat-cell  *matCellDef="let element" [hidden]="this.exibirColunas[4]">-->
<!--        <span *ngIf="element.vagasDisponiveis"> {{element.vagasDisponiveis}}-->
<!--        </span>-->
<!--        <span *ngIf="!element.vagasDisponiveis"> - </span>-->
<!--      </td>-->
<!--    </ng-container>-->

    <!-- Symbol Column -->
    <ng-container matColumnDef="porcentagemOcupada">
      <th mat-sort-header class="text-center" mat-header-cell *matHeaderCellDef [hidden]="this.exibirColunas[5]"> OCUPAÇÃO </th>
      <td mat-cell  *matCellDef="let element" [hidden]="this.exibirColunas[5]">
        <span *ngIf="element.porcentagemOcupada"> {{element.porcentagemOcupada}}%
        </span>
        <span *ngIf="!element.porcentagemOcupada"> - </span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="deficitProfessor">
      <th mat-sort-header class="text-center" mat-header-cell *matHeaderCellDef [hidden]="this.exibirColunas[6]"> DÉFICIT PROFESSOR </th>
      <td mat-cell  *matCellDef="let element" [hidden]="this.exibirColunas[6]">
        <span *ngIf="element.deficitProfessor"> {{element.deficitProfessor}}
        </span>
        <span *ngIf="!element.deficitProfessor"> - </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr *ngIf="filtroEscolaMonitoramento.ano && !dataSource.data.length" class="mat-row">
      <td class="mat-cell" colspan="9999">
        NENHUMA ESCOLA ENCONTRADA!
      </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons  >
  </mat-paginator>
</div>
<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
