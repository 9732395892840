import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FotoAluno} from '../model/FotoAluno';

@Injectable({
  providedIn: 'root'
})
export class FotoService {

  private URL = environment.API_URL + '/aluno';

  constructor(private http: HttpClient ) {
  }


  // tslint:disable-next-line:typedef
  buscarFotoPefil(alunoId: number): Observable<any>  {
    return this.http.get<any>(`${this.URL}/fotoPerfil/${alunoId}`);
  }

  salvarFotoPerfil(fotoAluno: FotoAluno): Observable<FotoAluno> {
    return this.http.post<FotoAluno>(`${this.URL}/foto`, fotoAluno);
  }

}
