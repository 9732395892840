import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { Generico } from '../../../model/Generico';
import { RelatoriosService } from '../../../services/relatorios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '@full-fledged/alerts';
import { Instituicao } from '../../../model/Instituicao';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UnidadeEnsino } from '../../../model/UnidadeEnsino';
import { UnidadeEscolarService } from '../../../services/unidade-escolar.service';

@Component({
  selector: 'app-r-turmas',
  templateUrl: './r-turmas.component.html',
  styleUrls: ['./r-turmas.component.scss'],
})
export class RTurmasComponent implements OnInit {
  @Input() anosLetivos: number[] = [];
  anoSelecionado: number = null;
  medicaoSelecionada: number = null;
  modalidadeSelecionada: number = null;
  nivelSelecionada: number = null;
  etapaSelecionada: number = null;

  mediacoes: Generico[] = [];
  modalidades: Generico[] = [];
  niveis: Generico[] = [];
  etapas: Generico[] = [];

  unidadesEnsino: UnidadeEnsino[] = [];
  unidadesEnsinoAux: UnidadeEnsino[] = [];
  unidadeEnsinoSelecionado: UnidadeEnsino = new UnidadeEnsino();

  instituicao: Instituicao = new Instituicao();

  filtroForm: FormGroup = this.formBuilder.group({
    nomeEscola: ['', [Validators.required]],
  });

  constructor(
    private utilService: UtilService,
    private relatorioService: RelatoriosService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private unidadeEscolarService: UnidadeEscolarService
  ) {}

  ngOnInit(): void {
    if (localStorage.hasOwnProperty('instituicao')) {
      this.instituicao = JSON.parse(atob(localStorage.getItem('instituicao')));
    }
    this.buscarTodasUnidadesEnsino();
    this.filtroEscola();
    console.log(this.anosLetivos);
    // if (!(this.anosLetivos.length > 0)) {
    //   this.alertService.danger(
    //     `Para a instituição ${this.instituicao.nomeFantasia}, não existe turmas cadastrado!`
    //   );
    // }
    this.utilService.buscarMediccoes().subscribe((data) => {
      console.log(data);
      this.mediacoes = data;
    });
  }

  filtroEscola() {
    this.filtroForm
      .get('nomeEscola')
      .valueChanges.pipe(
        debounceTime(300),
        map((emittedValue) => emittedValue.trim()),
        distinctUntilChanged()
      )
      .subscribe((selectedValue) => {
        console.log('Alterando o valor escola');
        console.log(selectedValue);
        if (selectedValue !== '') {
          this.unidadesEnsino = this.unidadesEnsinoAux;
          this.unidadesEnsino = this.unidadesEnsino.filter((item) => {
            return (
              item.nome.toLowerCase().indexOf(selectedValue.toLowerCase()) > -1
            );
          });
        } else {
          this.unidadesEnsino = this.unidadesEnsinoAux;
        }
      });
  }

  buscarTodasUnidadesEnsino() {
    this.unidadeEscolarService
      .buscarUnidadesEnsino(this.instituicao.id)
      .subscribe(
        (data) => {
          this.unidadesEnsino = data;
          this.unidadesEnsinoAux = this.unidadesEnsino;
          console.log(this.unidadesEnsino);
        },
        (error) => {}
      );
  }

  selecionarUnidadeEnsino(unidadeEnsino) {
    console.log(unidadeEnsino);
    this.unidadeEnsinoSelecionado = unidadeEnsino;
  }

  buscarModalidade() {
    console.log(this.medicaoSelecionada);
    this.utilService
      .buscarModalidades(this.medicaoSelecionada)
      .subscribe((data) => {
        console.log(data);
        this.modalidades = data;
        this.modalidadeSelecionada = null;
        this.nivelSelecionada = null;
        this.etapaSelecionada = null;
      });
  }

  buscarNiveis() {
    console.log(this.modalidadeSelecionada);
    this.nivelSelecionada = null;
    this.etapaSelecionada = null;
    this.utilService
      .buscarNiveisEnsino(this.modalidadeSelecionada)
      .subscribe((data) => {
        console.log(data);
        this.niveis = data;
      });
  }

  buscarEtapas() {
    console.log(this.nivelSelecionada);
    this.etapaSelecionada = null;
    this.utilService
      .buscarEtapasEnsino(this.nivelSelecionada)
      .subscribe((data) => {
        console.log(data);
        this.etapas = data;
      });
  }

  rendimentoTurma() {
    console.log(this.medicaoSelecionada);
    if (this.nivelSelecionada) {
      this.spinner.show();
      const idUnidade = this.unidadeEnsinoSelecionado.id
        ? this.unidadeEnsinoSelecionado.id
        : null;
      this.relatorioService
        .rendimentoTurma(
          this.anoSelecionado,
          this.instituicao.id,
          this.etapaSelecionada,
          this.medicaoSelecionada,
          this.modalidadeSelecionada,
          this.nivelSelecionada,
          idUnidade
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            console.log(error.error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 400) {
              this.alertService.danger(
                'Nenhum relatório encontrado!'
              );
            }
          }
        );
    } else {
      this.alertService.warning('Selecione Todos os campos obrigatórios (*)');
    }
  }

  turmaPorGradeCompleto() {
    console.log(this.medicaoSelecionada);
    if (this.nivelSelecionada) {
      this.spinner.show();
      const idUnidade = this.unidadeEnsinoSelecionado.id
        ? this.unidadeEnsinoSelecionado.id
        : null;
      this.relatorioService
        .turmaPorGradeCompleto(
          this.anoSelecionado,
          this.instituicao.id,
          this.etapaSelecionada,
          this.medicaoSelecionada,
          this.modalidadeSelecionada,
          this.nivelSelecionada,
          idUnidade
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            console.log(error.error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 400) {
              this.alertService.danger(
                'Nenhum relatório encontrado!'
              );
            }
          }
        );
    } else {
      this.alertService.warning('Selecione Todos os campos obrigatórios (*)');
    }
  }
}
