import {Component, OnInit} from '@angular/core';
import {AlunoService} from '../../services/aluno.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TurmaHorarioDisciplina} from '../../model/TurmaHorarioDisciplina';
import {Horario} from '../../model/Horario';
import {AuthService} from '../../services/auth.service';

// tslint:disable-next-line:class-name
export class HorarioDisciplina {
  horario: Horario;
  diaDisciplina: DiaDisciplina[] = [];
}

export class DiaDisciplina {
  idDia: number;
  disciplina: string;

  constructor(idDia: number, disciplina: string) {
    this.idDia = idDia;
    this.disciplina = disciplina;
  }
}

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss']
})
export class HorarioComponent implements OnInit {

  turmaHorario: TurmaHorarioDisciplina[] = [];
  horariosDisciplinas: HorarioDisciplina[] = [];

  constructor(private alunoService: AlunoService,
              private spinner: NgxSpinnerService,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    if (this.authService.check()) {
      this.buscarDisciplinas();
    }
  }

  // tslint:disable-next-line:typedef
  buscarDisciplinas() {
    this.spinner.show();
    this.turmaHorario = [];
    this.alunoService.horarioTurma(this.authService.getUser().id).subscribe((data) => {
        // console.log('Horarioooo');
        this.turmaHorario = data;
        // console.log(this.turmaHorario);
        this.spinner.hide();
        this.prepararHorarioDisciplina();
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();

      });
  }

  // tslint:disable-next-line:typedef
  prepararHorarioDisciplina() {
    this.horariosDisciplinas = [];

    this.turmaHorario.forEach(e => {
      const horario = this.horariosDisciplinas.find(horarioD => horarioD.horario.id === e.horario.id);
      if (horario === undefined) {
        const horarioDisciplina = new HorarioDisciplina();
        horarioDisciplina.horario = e.horario;
        this.converterHora(e.horario.inicio);
        horarioDisciplina.diaDisciplina.push(new DiaDisciplina(e.dia.id, e.turma.disciplina.descricao));
        this.horariosDisciplinas.push(horarioDisciplina);
      } else {
        horario.diaDisciplina.push(new DiaDisciplina(e.dia.id, e.turma.disciplina.descricao));
      }
    });

    // console.log(this.horariosDisciplinas);
  }

  // tslint:disable-next-line:typedef
  returnDisciplina(horarioD: HorarioDisciplina, dia: number): string {
    const disciplina = horarioD.diaDisciplina.find(disc => disc.idDia === dia);
    if (disciplina === undefined) {
      return ' - ';
    } else {
      return disciplina.disciplina;
    }
  }

  converterHora(horaD){
    // console.log(horaD);
    const hora = new Date(Date.prototype.setHours.apply(new Date(), horaD.split(':')));
    // console.log (hora);
    return hora;
  }
}
