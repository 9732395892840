import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderAlunoComponent} from './header-aluno/header-aluno.component';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HeaderAlunoComponent
  ],
  exports: [HeaderAlunoComponent, HeaderComponent],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class TemplateModule { }
