import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { UnidadeEscolarService } from '../../services/unidade-escolar.service';
import { UsuarioDTO } from '../../model/UsuarioDTO';
import { Instituicao } from '../../model/Instituicao';
import { ModalYesNoComponent } from '../modal/modal-yes-no/modal-yes-no.component';
import { MatDialog } from '@angular/material/dialog';
import { SelecionarInstituicaoComponent } from '../modal/selecionar-instituicao/selecionar-instituicao.component';

@Component({
  selector: 'app-monitoramento',
  templateUrl: './monitoramento.component.html',
  styleUrls: ['./monitoramento.component.scss'],
})
export class MonitoramentoComponent implements OnInit {
  tabSelect = 1;
  gestor: UsuarioDTO = new UsuarioDTO();
  instituicao: Instituicao = new Instituicao();
  instituicaoImagem: Instituicao = new Instituicao();

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    public authService: AuthService,
    public dialog: MatDialog,
    private unidadeEscolarService: UnidadeEscolarService
  ) {}

  ngOnInit(): void {
    if (!this.authService.checkGestor()) {
      {
        this.router.navigate(['/login/gestor']);
      }
    } else {
      this.gestor = this.authService.getGestor();
      console.log(this.gestor);
      this.mostrarInstituicao();
    }

    this.instituicaoImagem = JSON.parse(
      localStorage.getItem('instituicaoSelecionada')
    );

  }

  mostrarInstituicao() {
    if (localStorage.hasOwnProperty('instituicao')) {
      if (this.gestor.perfilInstituicoes.length === 1) {
        this.instituicao = this.gestor.perfilInstituicoes[0].instituicao;
        localStorage.setItem(
          'instituicao',
          btoa(JSON.stringify(this.gestor.perfilInstituicoes[0].instituicao))
        );
        console.log(JSON.stringify(this.gestor.perfilInstituicoes[0].perfil));

        localStorage.setItem(
          'perfil',
          btoa(JSON.stringify(this.gestor.perfilInstituicoes[0].perfil))
        );
      } else if (this.gestor.perfilInstituicoes.length > 1) {
        const dialogRef = this.dialog.open(SelecionarInstituicaoComponent, {
          panelClass: 'dialog-responsive',
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.instituicao = JSON.parse(
            atob(localStorage.getItem('instituicao'))
          );
          console.log(this.instituicao);
          location.reload();
        });
      }
    }
    console.log(localStorage.getItem('perfil'));

  }

  sair() {
    const dialogRef = this.dialog.open(ModalYesNoComponent, {
      panelClass: 'dialog-responsive',
      data: 'Deseja realmente sair?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.authService.logout();
        // localStorage.removeItem('instituicaoSelecionada');
        localStorage.removeItem('instituicao');
        localStorage.removeItem('gestor');
        localStorage.removeItem('perfil');
        this.router.navigate(['/login/gestor']);
      }
    });
  }
}
