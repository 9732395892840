<div class="bg-body">
  <div id="containerMain">
    <div id="box">
      <div class="container">
        <div class="title">LOGIN</div>
        <div id="login-form">
          <form novalidate [formGroup]="loginForm">
            <div class="input">
              <!--      <label for="name">Código</label>-->
              <input formControlName="cpf" placeholder="CPF"
                     type="text" name="cpf" [brmasker]="{person: true, userCaracters: true}"  id="name" maxlength="14">
              <span class="spin"></span>
            </div>
            <div class="input">
              <!--      <label for="pass">Data de Nascimento</label>-->
              <input type="password" formControlName="senha"
                     placeholder="Senha" name="pass" id="pass">
              <span class="spin"></span>
            </div>

            <div class="button login">
              <button [disabled]="loginForm.invalid" (click)="entrar()"><span>Entrar</span> <i
                class="fa fa-check"></i></button>
            </div>
            <a href="#" class="pass-forgot">Esqueceu a senha?</a>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ff-alerts></ff-alerts>
