import {Aluno} from './Aluno';

export class FotoAluno {
  id: number;
  aluno: Aluno;
  data: Date;
  arquivo: string;
  perfil: boolean;
  foto: any;
  }
