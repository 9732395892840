import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ModalFaltasComponent} from '../../pages/modal/modal-faltas/modal-faltas.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalYesNoComponent} from '../../pages/modal/modal-yes-no/modal-yes-no.component';
import {FotoEvent} from '../../events/foto-event';
import {Location} from '@angular/common';
import {FotoService} from '../../services/foto.service';

@Component({
  selector: 'app-header-aluno',
  templateUrl: './header-aluno.component.html',
  styleUrls: ['./header-aluno.component.scss']
})
export class HeaderAlunoComponent implements OnInit {
  fotoBase64 = null;
  public route: string;

  constructor(public authService: AuthService,
              private router: Router,
              location: Location,
              public dialog: MatDialog,
              private fotoEvent: FotoEvent,
              private fotoService: FotoService) {

    router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();
        console.log(this.route);

      } else {
        console.log('Erro ao obter');
      }
    });

    // Alteração na foto do perfil
    fotoEvent.alteracao$.subscribe(
      (data) => {
        if (localStorage.hasOwnProperty('fotoPerfil')) {
          this.fotoBase64 = localStorage.getItem('fotoPerfil');
        }
      }
    );
  }

  ngOnInit(): void {
    if (!this.authService.check()) {
      this.router.navigate(['/login']);
    } else {
      if (localStorage.hasOwnProperty('fotoPerfil')) {
        this.fotoBase64 = localStorage.getItem('fotoPerfil');
      } else {
        this.buscarFoto();
      }
    }
  }

  // tslint:disable-next-line:typedef
  sair() {
    console.log('Sair');
    const dialogRef = this.dialog.open(ModalYesNoComponent, {
      panelClass: 'dialog-responsive',
      data: 'Deseja realmente sair?'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.authService.logoutAluno();
        this.router.navigate(['/login']);
      }
    });
  }

  // tslint:disable-next-line:typedef
  buscarFoto() {
    this.fotoService.buscarFotoPefil(this.authService.getUser().id).subscribe((data) => {
      this.fotoBase64 = data.foto;
      console.log('chegou');
      },

      (error) => {
      console.log(error);
      });
  }

}
