<div class="container container-body mt-5">

  <!-- <h4 class="btn-link cursor" style="color: dimgray" (click)="openListaInstituicao()">
    <i
      class="fas fa-map-marker-alt mr-2 text-gray"></i>{{instituicao.nomeFantasia | titlecase}}
  </h4> -->

  <hr/>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="INSTITUIÇÃO">
    <app-instituicao></app-instituicao>
    </mat-tab>

    <mat-tab label="ESCOLA">
      <app-escola></app-escola>
    </mat-tab>

    <mat-tab label="TURMA">
      <app-turma></app-turma>
    </mat-tab>

    <mat-tab label="PROFESSORES">
      <app-professor></app-professor>
    </mat-tab>

    <mat-tab label="ALUNOS">
      <app-aluno></app-aluno>
    </mat-tab>

    <mat-tab label="MATRICULA">
      <app-matricula></app-matricula>
    </mat-tab>
  </mat-tab-group>

</div>

<ff-alerts></ff-alerts>
<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
