<div class="tam">
  <agm-map
    [zoom]="zoom" [latitude]="lat" [longitude]="lng">

    <!--        <agm-marker  [latitude]="toNumber('-43.937546955834')" [longitude]="toNumber('-3.5856801489371')">-->
    <div *ngFor="let unidade of unidades">
      <agm-marker *ngIf="unidade.latitude != null && unidade.longitude != null" [latitude]="unidade.longitude"
                  [longitude]="unidade.latitude" [agmFitBounds]="true" (markerClick)="mostrarDadosEscola(unidade)">

      </agm-marker>
    </div>
    <!--        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>-->
  </agm-map>

  <div class="container titulo">
    <div class="row">
      <div class=" col-12 col-md-8 mt-5">
        <h1>Bem-vindo!</h1>
        <br>
        <a id="voltar" type="button" class="mr-2 btn btn-primary  w-15" routerLink="/"> Voltar </a>
        <a href="/login/gestor" type="button" class="btn btn-primary  w-25">
          <i class="fas fa-university"></i> Ambiente Gestor
        </a>
        <a href="/etutor/public/welcome.jsf" type="button"
           class=" ml-2 btn btn-primary w-26">
          <i class="fas fa-chalkboard-teacher"></i> Ambiente Escolar
        </a>
        <a type="button" class="ml-2 btn btn-primary  w-25" routerLink="/portal">
          <i class="fas fa-user-graduate"></i> Ambiente Aluno
        </a>

      </div>
    </div>
  </div>


  <div id="card" class="col-md-12">
    <div id="cardHeader">
      <h2 *ngIf="unidadeSelecionada">{{unidadeSelecionada.nome}}</h2>
      <h2 *ngIf="!unidadeSelecionada.id">Seleciona no mapa a sua escola</h2>
    </div>
    <div id="cardBody" *ngIf="unidadeSelecionada.id">
      <ul class="card-text mt-2">
        <li *ngIf="unidadeSelecionada.endereco">
          <span class="font-weight-bold">ENDEREÇO:</span>
          {{unidadeSelecionada.endereco}},
          <span *ngIf="unidadeSelecionada.enderecoNumero"> Nº {{unidadeSelecionada.enderecoNumero}},</span>
          <span
            *ngIf="unidadeSelecionada.enderecoComplemento"> COMPLEMENTO {{unidadeSelecionada.enderecoComplemento}}</span>
          <span *ngIf="unidadeSelecionada.bairro"> BAIRRO {{unidadeSelecionada.bairro}},</span>
          <span *ngIf="unidadeSelecionada.cep"> CEP: {{unidadeSelecionada.cep}},</span>

        </li>
        <!--        <li>Lotação:</li>-->
        <li *ngIf="unidadeSelecionada.email">
          <span class="font-weight-bold">E-MAIL: </span> {{unidadeSelecionada.email}}</li>
        <li
          *ngIf="unidadeSelecionada.telefone || unidadeSelecionada.telefonePublico01 || unidadeSelecionada.telefonePublico02">
          <span class="font-weight-bold"> TELEFONE: </span>
          <span *ngIf="unidadeSelecionada.telefone">{{unidadeSelecionada.telefone}} </span>
          <span *ngIf="unidadeSelecionada.telefonePublico01"> {{unidadeSelecionada.telefonePublico01}}</span>
          <span *ngIf="unidadeSelecionada.telefonePublico02"> {{unidadeSelecionada.telefonePublico02}}</span>
        </li>
      </ul>
    </div>
    <div id="cardFooter" *ngIf="unidadeSelecionada.id" >
      <a routerLink="/login">Acessar</a>
    </div>
  </div>
</div>
<ngx-spinner type="ball-atom">
  <p style="color: white" > Carregando... </p>
</ngx-spinner>

