<p class="text-center">
  <img src="assets/undrwa/education.svg" width="140px"/>
</p>
<div class="col-12 m-0 p-0">
  <label for="situacaoFuncional">SELECIONE SUA INSTITUIÇÃO</label>
  <select [(ngModel)]="instituicaoSelecionada"  id="situacaoFuncional"
          class="form-control labelFilter m-0 p-0">
    <option selected disabled>Selecione</option>
    <option  [ngValue]="instituicao" class="p-0 m-0" *ngFor="let instituicao of instituicoes">
      {{instituicao.nomeFantasia}}
    </option>
  </select>
</div>
<button [disabled]="!instituicaoSelecionada.id" class="btn btn-primary float-right mt-3" (click)="selecionar()">Selecionar</button>
