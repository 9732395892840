import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TurmaDisciplina} from '../model/TurmaDisciplina';
import {MaterialAula} from '../model/MaterialAula';
import {TurmaHorarioDisciplina} from '../model/TurmaHorarioDisciplina';
import {FaltaDTO} from '../model/FaltaDTO';
import {AlunoAtividade} from '../model/AlunoAtividade';
import {Aluno} from '../model/Aluno';
import {AlunoDTO} from '../model/AlunoDTO';
import {DadosProfessorCargaHorariaDTO} from '../model/dto/DadosProfessorCargaHorariaDTO';

@Injectable({
  providedIn: 'root'
})
export class ProfessorService {

  private URL = environment.API_URL + '/professor';
  private urlApi = environment.API_RELATORIO_URL;

  constructor(private http: HttpClient ) {
  }


  buscarProfessores(instituicao, nome, ano: string): Observable<DadosProfessorCargaHorariaDTO[]>{
    return this.http.get<DadosProfessorCargaHorariaDTO[]>(`${this.urlApi}/professores/listaProfessoresParaLevantamentoCargaHoraria/${nome}/${instituicao}/${ano}`);
  }

}
