import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-yes-no',
  templateUrl: './modal-yes-no.component.html',
  styleUrls: ['./modal-yes-no.component.scss']
})
export class ModalYesNoComponent implements OnInit {

  pergunta = 'Deseja realmente excluir?';

  constructor(public dialogRef: MatDialogRef<ModalYesNoComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    if (data != null){
      this.pergunta = data;
    }
  }

  ngOnInit(): void {
  }

  close(resposta: boolean): void {
    this.dialogRef.close(resposta);
  }

}
