<link href="https://fonts.googleapis.com/css?family=Muli:300,400,700,900" rel="stylesheet">
<div class="body">
  <div class="site-wrap">

    <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>


    <header id="inicio" class="site-navbar py-4 js-sticky-header site-navbar-target" role="banner">

      <div class="container-fluid">
        <div class="d-flex align-items-center">
          <div class="site-logo mr-auto w-25"><a a [ngxScrollTo]="'#home-section'"><img
                src="assets/logos/eTutor-removebg.png" alt="">
            </a>
          </div>
          <div class="mx-auto text-center">
            <nav class="site-navigation position-relative text-right" role="navigation">
              <ul class="site-menu main-menu js-clone-nav mx-auto d-none d-lg-block  m-0 p-0">
                <li><a [ngxScrollTo]="'#sobre'" class="nav-link cursor">Sobre</a></li>
                <li><a [ngxScrollTo]="'#escola'">Escola</a></li>
                <li><a [ngxScrollTo]="'#professores'">Professores</a></li>
                <li><a [ngxScrollTo]="'#section-alunos'" class="nav-link">Alunos</a></li>

              </ul>
            </nav>
          </div>

          <div class="ml-auto w-25">
            <nav class="site-navigation position-relative text-right" role="navigation">
              <ul class="site-menu main-menu site-menu-dark js-clone-nav mr-auto d-none d-lg-block m-0 p-0">
                <li class="cta"><a [ngxScrollTo]="'#contact-section'" class="nav-link"><span>Contato</span></a></li>
              </ul>
            </nav>
            <a class="d-inline-block d-lg-none site-menu-toggle js-menu-toggle text-black float-right"><span
                class="icon-menu h3"></span></a>
          </div>
        </div>
      </div>

    </header>

    <div class="intro-section" id="home-section">

      <div class="slide-1" data-stellar-background-ratio="0.5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-lg-6 mb-4 titulo">
                  <h1>Agora ficou mais simples e fácil acompanhar tudo com o
                    Gestor Escolar eTutor!</h1>
                  <div id="footerSaibaMais">
                    <p><a [ngxScrollTo]="'#sobre'" class="btn btn-primary py-3 px-5 btn-pill ">Saiba
                        mais</a></p>
                  </div>
                </div>
                <div class="col-lg-5 ml-auto">
                  <form action="" method="post" class="form-box">
                    <h3 class="h4 text-black mb-2">Selecione seu Estado</h3>
                    <div class="form-group">
                      <ng-select [minTermLength]="1" dropdownPosition="top" [clearable]="false" bindLabel="UF:"
                        notFoundText="UF não encontrada" [(ngModel)]="estado" [disabled]="!estados.length"
                        name="select_estado" (change)="getMunicipiosUf(estado)">
                        <ng-option *ngFor="let estado of estados" [value]="estado"> {{estado.descricao}}</ng-option>
                      </ng-select>
                      <!--                      <select #selectList id="estado" class="form-control"-->
                      <!--                              (change)="selecionarEstado(selectList.value)">-->
                      <!--                        <option selected>Estado</option>-->
                      <!--                        <option *ngFor="let estado of estados"-->
                      <!--                                [value]="estado.id">{{estado.descricao | titlecase}}</option>-->
                      <!--                      </select>-->
                    </div>
                    <h3 class="h4 text-black mb-2 mt-3">Selecione sua Cidade</h3>
                    <div class="form-group">
                      <ng-select [minTermLength]="1" dropdownPosition="top" [clearable]="false" bindLabel="UF:"
                        notFoundText="UF não encontrada" [(ngModel)]="cidadeSelecionada" [disabled]="!cidades.length"
                        name="select_cidade" (change)="selecionarCidade(cidadeSelecionada)">
                        <ng-option *ngFor="let cidade of cidades" [value]="cidade">
                          {{cidade.descricao | uppercase}}
                        </ng-option>
                      </ng-select>

                      <!--                      <select [disabled]="!cidades.length" #selectListCidade id="cidade" class="form-control"-->
                      <!--                              (change)="selecionarCidade(selectListCidade.value)">-->
                      <!--                        <option selected>Cidade</option>-->
                      <!--                        <option *ngFor="let cidade of cidades" value="{{cidade | json}}">-->
                      <!--                          {{cidade.descricao | titlecase}}-->
                      <!--                        </option>-->
                      <!--                      </select>-->
                    </div>
                    <div class="form-group btnAcessar mt-4">
                      <input class="btn btn-outline-primary btn-pill mt-4" value="Acessar" (click)="acessar()">
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    <div class="site-section courses-title" id="courses-section">-->
    <!--      <div class="container">-->
    <!--        <div class="row mb-5 justify-content-center">-->
    <!--          <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">-->
    <!--            &lt;!&ndash; LUGAR DE UM TITULO &ndash;&gt;-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">-->
    <!--      <div class="container">-->
    <!--        <div class="row">-->

    <!--          <div class="owl-carousel col-12 nonloop-block-14">-->

    <!--            <div class="course bg-white h-100 align-self-stretch">-->
    <!--              <figure class="m-0">-->
    <!--                <a href="course-single.html"><img src="assets/etutor/img_1.jpg" alt="Image" class="img-fluid"></a>-->
    <!--              </figure>-->
    <!--              <div class="course-inner-text py-4 px-4">-->
    <!--                <h3><a href="#">Gestão Completa</a></h3>-->
    <!--                <p>Lorem ipsum dolor sit amet ipsa nulla adipisicing elit. </p>-->
    <!--              </div>-->
    <!--              <div class="d-flex border-top stats">-->
    <!--                <div class="py-3 px-4 w-25 cardButtonSobre"><a href="#sobre"-->
    <!--                                                               class="btn btn-primary py-3 px-5 btn-pill ">Saiba-->
    <!--                  mais</a>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="course bg-white h-100 align-self-stretch">-->
    <!--              <figure class="m-0">-->
    <!--                <a href="course-single.html"><img src="assets/etutor/img_2.jpg" alt="Image" class="img-fluid"></a>-->
    <!--              </figure>-->
    <!--              <div class="course-inner-text py-4 px-4">-->
    <!--                <h3><a href="#">Acompanhamento Escolar</a></h3>-->
    <!--                <p>Lorem ipsum dolor sit amet ipsa nulla adipisicing elit. </p>-->
    <!--              </div>-->
    <!--              <div class="d-flex border-top stats">-->
    <!--                <div class="py-3 px-4 w-25 cardButtonSobre"><a href="#sobre"-->
    <!--                                                               class="btn btn-primary py-3 px-5 btn-pill ">Saiba-->
    <!--                  mais</a>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="course bg-white h-100 align-self-stretch">-->
    <!--              <figure class="m-0">-->
    <!--                <a href="course-single.html"><img src="assets/etutor/img_3.jpg" alt="Image" class="img-fluid"></a>-->
    <!--              </figure>-->
    <!--              <div class="course-inner-text py-4 px-4">-->
    <!--                <h3><a href="#">Pais e reponsáveis</a></h3>-->
    <!--                <p>Lorem ipsum dolor sit amet ipsa nulla adipisicing elit. </p>-->
    <!--              </div>-->
    <!--              <div class="d-flex border-top stats">-->
    <!--                <div class="py-3 px-4 w-25 cardButtonSobre"><a href="#sobre"-->
    <!--                                                               class="btn btn-primary py-3 px-5 btn-pill ">Saiba-->
    <!--                  mais</a>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->


    <!--            <div class="course bg-white h-100 align-self-stretch">-->
    <!--              <figure class="m-0">-->
    <!--                <a href="course-single.html"><img src="assets/etutor/img_4.jpg" alt="Image" class="img-fluid"></a>-->
    <!--              </figure>-->
    <!--              <div class="course-inner-text py-4 px-4">-->
    <!--                <h3><a href="#">Alunos</a></h3>-->
    <!--                <p>Lorem ipsum dolor sit amet ipsa nulla adipisicing elit. </p>-->
    <!--              </div>-->
    <!--              <div class="d-flex border-top stats">-->
    <!--                <div class="py-3 px-4 w-25 cardButtonSobre"><a href="#sobre"-->
    <!--                                                               class="btn btn-primary py-3 px-5 btn-pill ">Saiba-->
    <!--                  mais</a>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="course bg-white h-100 align-self-stretch">-->
    <!--              <figure class="m-0">-->
    <!--                <a href="course-single.html"><img src="assets/etutor/img_5.jpg" alt="Image" class="img-fluid"></a>-->
    <!--              </figure>-->
    <!--              <div class="course-inner-text py-4 px-4">-->
    <!--                <h3><a href="#">Unidade de Ensino</a></h3>-->
    <!--                <p>Lorem ipsum dolor sit amet ipsa nulla adipisicing elit. </p>-->
    <!--              </div>-->
    <!--              <div class="d-flex border-top stats">-->
    <!--                <div class="py-3 px-4 w-25 cardButtonSobre"><a href="#sobre"-->
    <!--                                                               class="btn btn-primary py-3 px-5 btn-pill ">Saiba-->
    <!--                  mais</a>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="course bg-white h-100 align-self-stretch">-->
    <!--              <figure class="m-0">-->
    <!--                <a href="course-single.html"><img src="assets/etutor/img_6.jpg" alt="Image" class="img-fluid"></a>-->
    <!--              </figure>-->
    <!--              <div class="course-inner-text py-4 px-4">-->
    <!--                <h3><a href="#">Sobre</a></h3>-->
    <!--                <p>Lorem ipsum dolor sit amet ipsa nulla adipisicing elit. </p>-->
    <!--              </div>-->
    <!--              <div class="d-flex border-top stats">-->
    <!--                <div class="py-3 px-4 w-25 cardButtonSobre"><a href="#sobre"-->
    <!--                                                               class="btn btn-primary py-3 px-5 btn-pill ">Saiba-->
    <!--                  mais</a>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->


    <!--          </div>-->


    <!--        </div>-->
    <!--        <div class="row justify-content-center">-->
    <!--          <div class="col-7 text-center">-->
    <!--            <button class="customPrevBtn btn btn-primary m-1"><</button>-->
    <!--            <button class="customNextBtn btn btn-primary m-1">></button>-->
    <!--          </div>-->
    <!--          <div id="sobre"></div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->


    <div class="site-section bg-white " id="sobre">
      <div class="container mt-4">
        <div class="row mb-5 justify-content-center">
          <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
            <h2 class="section-title">Sobre</h2>
            <p>O Etutor é uma Plataforma Tecnológica de suporte a todos os processos da Gestão Municipal na Área
              Educacional.
            </p>
          </div>
        </div>
        <div class="row mb-5 align-items-center">
          <div class="col-lg-7 mb-5" data-aos="fade-up" data-aos-delay="100">
            <img src=" assets/etutor/undraw_teacher.svg " alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-4 ml-auto" data-aos="fade-up" data-aos-delay="200">
            <h2 class="text-black mb-4" id="gestaoCompleta">Gestão Completa</h2>
            <p class="mb-4">O eTutor dota a gestão municipal com informações integras e automatizadas para suporte a
              tomada de decisões dentro das áreas administrativa, pedagógica e gerencial.</p>
          </div>
        </div>


        <div class="row mb-5 align-items-center">
          <div class="col-lg-7 mb-5 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="100">
            <img src="assets/etutor/undraw_youtube_tutorial.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-4 mr-auto order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
            <h2 class="text-black mb-4">Acompanhamento Escolar</h2>
            <p class="mb-4">Taxa de Ocupação da Rede
              Disponibilidade/Necessidade de Professores
              Desempenho Escolar
              Distorção Série/Idade
              Avaliação da Rede
              Formação Continuada
              Transporte Escolar
              Alimentação Escolar</p>
          </div>
        </div>

        <div class="row mb-5 align-items-center">
          <div class="col-lg-7 mb-5" data-aos="fade-up" data-aos-delay="100">
            <img src="assets/etutor/undraw_back_to_school_inwc.svg" alt="Image" class="img-fluid">
          </div>
          <div id="paisResponsáveis" class="col-lg-4 ml-auto" data-aos="fade-up" data-aos-delay="200">
            <h2 class="text-black mb-4">Pais e Responsáveis</h2>

            <p class="mb-4">
              Portal completo para acompanhamento da frequência, notas e atividades desenvolvidas pelos filhos. Além de
              acesso a uma série de documentos eletronicamente.

            </p>
            <div id="professores"></div>
          </div>
        </div>

        <div class="row mb-5 align-items-center mr-0 pr-0">
          <div class="col-lg-4 mb-5 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="100">
            <img src="assets/etutor/undraw_teaching.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-4 mr-auto order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
            <h2 class="text-black mb-4">Professores</h2>
            <p class="mb-4">
            <p>Diário Eletrônico,
              Horários sempre na mão,
              Mapeamento, Distribuição,
              Lotação e
              Carga Horária Otimizada,
              Suporte a programas de Formação
            </p>
            <br>

            <div id="escola"></div>
          </div>
        </div>

        <div class="row mb-5 align-items-center">
          <div class="col-lg-7 mb-5" data-aos="fade-up" data-aos-delay="100">
            <img src="assets/etutor/undraw_Ordinary_day_re_v5hy.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-4 ml-auto" data-aos="fade-up" data-aos-delay="200">
            <h2 class="text-black mb-4">Unidade de Ensino</h2>
            <p class="mb-4">Infraestrutura Física, Equipamento e Mobiliário, Documentos Eletrônicos, Matriculas e
              Transferências Online, Programas Educacionais, Modalidades de Ensino Ofertadas.</p>
          </div>
        </div>
        <div id="section-alunos"></div>
      </div>
    </div>

    <div class="site-section bg-white ">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-lg-7 mb-5 text-center" data-aos="fade-up" data-aos-delay="">
            <h2 class="section-title">Alunos</h2>
            <p class="mb-5">Através do eTutor é possível que a Secretária de Educação Municipal, que a Unidade Escolar,
              ou a Coordenação Pedagógica tenha acesso de forma inteligente às informações dos alunos de forma
              individual, por unidade de ensino ou mesmo uma visão desses dados a nível de rede escolar.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="100">
            <div class="teacher text-center">
              <img src="assets/etutor/aluna2.jpg" alt="Image" class="img-fluid w-50 rounded-circle mx-auto mb-4">
              <div class="py-2">
                <h3 class="text-black">Thays</h3>
                <p>Agora eu não me perco mais com os horários de aula, pois com o eTutor eu tenho sempre os meus
                  horários na palma da mão.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="200">
            <div class="teacher text-center">
              <img src="assets/etutor/alunomoreno.jpg" alt="Image" class="img-fluid w-50 rounded-circle mx-auto mb-4">
              <div class="py-2">
                <h3 class="text-black">Carlos</h3>
                <p>Com o eTutor eu não perco mais o link quando a aula é online. Outra coisa que é muito legal é que a
                  gente tem acesso a todos os materiais das disciplinas de forma muito organizada.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="300">
            <div class="teacher text-center">
              <img src="assets/etutor/aluno1.jpg" alt="Image" class="img-fluid w-50 rounded-circle mx-auto mb-4">
              <div class="py-2">
                <h3 class="text-black">Karen</h3>
                <p>Além do acesso fácil aos materiais que os professores usam nas aulas, o eTutor me ajuda com a
                  realização das tarefas, porque ele me diz quais tarefas eu já fiz e quais eu ainda não entreguei.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="site-section bg-image overlay" style="background-image: url('assets/etutor/student.jpg');">
      <div class="container">
        <div class="row justify-content-center align-items-center">
        </div>
      </div>
    </div>

    <div class="site-section pb-0">

      <div class="future-blobs">
        <div class="blob_2">
          <img src="assets/etutor/blob_2.svg" alt="Image">
        </div>
        <div class="blob_1">
          <img src="assets/etutor/blob_1.svg" alt="Image">
        </div>
      </div>
      <div class="container">
        <div class="row mb-5 justify-content-center" data-aos="fade-up" data-aos-delay="">
          <div class="col-lg-7 text-center">
            <!-- LUGAR DE UM TÍTULO -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 ml-auto align-self-start" data-aos="fade-up" data-aos-delay="100">

            <div class=" pl-5 pr-5 pt-3 pb-3 rounded bg-white why-choose-us-box">

              <ul>
                <li>Gestão Educacional Municipal Inteligente</li>
                <li>Importação e Exportação de Dados Educacionais</li>
                <li>Suporte ao cumprimento da LDB e demais legislação vigente</li>
                <li>Otimização das rotinas na Escola e na Secretaria de Educação</li>
                <li>Automatização do fluxo de informação entre os vários níveis da administração</li>
                <li>Gestão Inteligente focada nas Escolas, nos Professores e nos Alunos</li>
              </ul>

            </div>


          </div>
          <div class="col-lg-7 align-self-end" data-aos="fade-left" data-aos-delay="200">
            <img src="assets/etutor/person_transparent.png" alt="Image" class="img-fluid">
          </div>
        </div>
      </div>
    </div>


    <div class="site-section bg-light" id="contact-section">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-md-7">


            <h2 class="section-title mb-3">Fale conosco</h2>
            <p class="mb-5">Mande-nos um e-mail, os nossos especialistas estão a sua disposição</p>

            <form method="post" data-aos="fade">
              <div class="form-group row">
                <div class="col-md-6 mb-3 mb-lg-0">
                  <input type="text" class="form-control" placeholder="Nome">
                </div>
                <div class="col-md-6">
                  <input type="text" class="form-control" placeholder="Sobrenome">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder="Assunto">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <input type="email" class="form-control" placeholder="Email">
                </div>
              </div>
              <div class="form-group row btnEnviar">
                <div class="col-md-12">
                  <textarea class="form-control" id="" cols="30" rows="10"
                    placeholder="Escreva sua mensagem aqui."></textarea>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-6">

                  <input type="submit" class="btn btn-primary py-3 px-3 btn-block btn-pill" value="ENVIAR">
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


    <footer class="footer-section bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-3 ml-auto">
            <h3>Contato</h3>
            <ul id="telefone" class="list-unstyled footer-links">
              <li></li>
              <li>toutsolucoes.contato@gmail.com</li>
              <li>Endereço: Avenida Daniel de La Touche, Quadra G, Nº 11, sala 203, Edifício Viena Carneiro, Cohama -
                São Luís - MA
              </li>
            </ul>
          </div>

          <div class="col-md-3 ml-auto">
            <h3>Links</h3>
            <ul class="list-unstyled footer-links">
              <li><a [ngxScrollTo]="'#home-section'">Início</a></li>
              <li><a [ngxScrollTo]="'#sobre'">Sobre</a></li>
              <li><a [ngxScrollTo]="'#escola'">Escola</a></li>
              <li><a [ngxScrollTo]="'#professores'">Professores</a></li>
              <li><a [ngxScrollTo]="'#section-aluno'">Alunos</a></li>
            </ul>
          </div>

          <div class="row pt-5 mt-5 text-center">
            <div class="col-md-12">
              <div class="border-top pt-5">

                <p>
                  Todos os direitos reservados a &copy; Copyright 2021 - TOUT SOLUÇÕES EM TECNOLOGIA
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>


  </div> <!-- .site-wrap -->
</div>
<ff-alerts></ff-alerts>
<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>