<p class="text-center">
  <img src="assets/icon/alerticon.png" width="60px"/>
</p>
<h2 class="text-center ">
  No momento, não temos nenhuma instituição cadastrada em {{instituicao.nomeFantasia | titlecase}}.
</h2>
<div class="w-100 text-center">
  <button class="btn btn-outline-primary"   (click)="close()">
    Entendi
  </button>
</div>
