import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AlertModule } from '@full-fledged/alerts';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from '../../app-routing.module';
import { ModalAtividadeComponent } from './modal-atividade/modal-atividade.component';
import { ModalAvisoComponent } from './modal-aviso/modal-aviso.component';
import { ModalFaltasComponent } from './modal-faltas/modal-faltas.component';
import { ModalFiltroInstituicaoComponent } from './modal-filtro-instituicao/modal-filtro-instituicao.component';
import { ModalFiltroTurmaComponent } from './modal-filtro-turma/modal-filtro-turma.component';
import { ModalInsituicoesComponent } from './modal-insituicoes/modal-insituicoes.component';
import { ModalMatriculaComponent } from './modal-matricula/modal-matricula.component';
import { ModalYesNoComponent } from './modal-yes-no/modal-yes-no.component';
import { SelecionarInstituicaoComponent } from './selecionar-instituicao/selecionar-instituicao.component';

@NgModule({
  declarations: [ModalAtividadeComponent, ModalFaltasComponent, ModalYesNoComponent, ModalAvisoComponent, SelecionarInstituicaoComponent, ModalFiltroInstituicaoComponent, ModalFiltroTurmaComponent, ModalInsituicoesComponent, ModalMatriculaComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatToolbarModule,
        FormsModule,
        AlertModule,
        AppRoutingModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        MatSelectModule,
        NgSelectModule,
        RouterModule,
        MatRadioModule
    ],
  exports: [
    ModalAtividadeComponent,
    ModalFaltasComponent,
    ModalYesNoComponent
  ]
})
export class ModalModule {
}
