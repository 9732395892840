<div class="container main">
    <form [formGroup]="matriculaAluno">
        <div class="form-row mt-3">
            <div class="col-md-6 col-sm-12">
                <label class="label" for="">Modalidade: <span class="color-red">*</span></label>
                <ng-select placeholder="Modalidade" [items]="modalidade" notFoundText="Nada encontrado"
                    (change)="onChangeModalidade($event)" appendTo="body" formControlName="modalidade"
                    bindLabel="descricao" bindValue="id">
                </ng-select>
            </div>

            <div class="col-md-6 col-sm-12">
                <label class="label" for="">Nivel: <span class="color-red">*</span></label>
                <ng-select placeholder="Nivel" [items]="nivel" appendTo="body" notFoundText="Nada encontrado"
                    (change)="onChangeNivel($event)" formControlName="nivel" bindLabel="descricao" bindValue="id">
                </ng-select>
            </div>
        </div>

        <div class="form-row mt-3" >
            <div class="col-md-6 col-sm-12">
                <label class="label" for="">Etapa: <span class="color-red">*</span></label>
                <ng-select placeholder="Etapa" [items]="etapa" appendTo="body" notFoundText="Nada encontrado"
                    (change)="onChangeEtapa($event)" formControlName="etapa" bindLabel="descricao" bindValue="id">
                </ng-select>
            </div>

            <div class="col-md-6 col-sm-12" >
                <label class="label" for="">Turno: <span class="color-red">*</span></label>
                <ng-select placeholder="Turno" [items]="turno" appendTo="body" notFoundText="Nada encontrado"
                    (change)="onChangeTurno($event)" formControlName="turno" bindLabel="descricao" bindValue="id">
                </ng-select>
            </div>
        </div>

        <div class="form-row mt-3">
            <div class="col-12">
                <label class="label" for="">Escola: <span class="color-red">*</span></label>
                <ng-select placeholder="Escola" (open)="obterEscola()" [items]="escola" appendTo="body"
                    notFoundText="Nada encontrado" bindLabel="nome" formControlName="escola" bindValue="id">
                </ng-select>

            </div>
        </div>
    </form>
    <div class="form-bottom">
        <button mat-raised-button (click)="matricular()" [disabled]="!matriculaAluno.valid || loading" color="primary">MATRICULAR
            <mat-icon *ngIf="loading"><mat-spinner color="primary" diameter="20">
            </mat-spinner></mat-icon>
        </button>
        <button mat-button [disabled]="matriculaLoading" (click)="reemitirMatriculaAluno()" color="primary">
            EMITIR MATRICULA
                <mat-icon *ngIf="matriculaLoading"><mat-spinner color="primary" diameter="20">
            </mat-spinner></mat-icon>
          </button>
    </div>

</div>
<ff-alerts></ff-alerts>