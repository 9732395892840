import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@full-fledged/alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Generico } from 'src/app/model/Generico';
import { MonitoramentoService } from 'src/app/services/monitoramento.service';

@Component({
  selector: 'app-modal-matricula',
  templateUrl: './modal-matricula.component.html',
  styleUrls: ['./modal-matricula.component.scss']
})
export class ModalMatriculaComponent implements OnInit {

  listaEscolas: Generico[] = [];
  anosLetivos: Generico[] = [];
  modalidades: Generico[] = [];
  niveis: Generico[] = [];
  etapas: Generico[] = [];

  turnos = [
    { id: 1, descricao: "MATUTINO" },
    { id: 2, descricao: "VESPERTINO" },
    { id: 3, descricao: "NOTURNO" },
    // {id: 4, descricao: "DIURNO"},
    { id: 5, descricao: "INTEGRAL" },
  ];

  filtro = {
    ano: null,
    escola: null,
    etapa: null,
    turno: null,
    comVaga: "2",
    instituicao: null,
    modalidade: null,
    nivel: null
  };

  constructor(
    public dialogRef: MatDialogRef<ModalMatriculaComponent>,
    private spinner: NgxSpinnerService,
    private alert: AlertService,
    private monitoramento: MonitoramentoService) {
    const entidade: any = JSON.parse(localStorage.getItem('instituicaoSelecionada'));
    this.filtro.instituicao = entidade.id;
  }

  ngOnInit(): void {
    this.spinner.show();

    this.monitoramento.getEscola(this.filtro.instituicao).subscribe(resp => {
      // tslint:disable-next-line: no-unused-expression
      this.listaEscolas = resp;
    }, (e) => { this.spinner.hide(); }, () => {
      // tslint:disable-next-line: no-unused-expression
      this.spinner.hide();
    });
    this.monitoramento.getAnoLetivo(this.filtro.instituicao).subscribe(resp => {
      // tslint:disable-next-line: no-unused-expression
      this.anosLetivos = resp.sort();
    }, (e) => { this.spinner.hide(); }, () => {
      // tslint:disable-next-line: no-unused-expression
      this.spinner.hide();
    });
    this.monitoramento.getModalidade().subscribe(resp => {
      this.modalidades = resp;
      // tslint:disable-next-line: no-unused-expression
    }, (e) => { this.spinner.hide(); }, () => {
      // tslint:disable-next-line: no-unused-expression
      this.spinner.hide();
    });
  }

  onChangeEscola(escola) {
    console.log(escola);
  }

  onChangeModalidade(modalidade) {
    this.filtro.nivel = null;
    this.niveis = [];
    this.filtro.etapa = null;
    this.etapas = [];
    this.spinner.show();
    this.monitoramento.getNivel(Number(modalidade)).subscribe(resp => {this.niveis = resp; this.spinner.hide();});
  }

  onChangeNivel(nivel) {
    this.filtro.etapa = null;
    this.etapas = [];
    this.spinner.show();
    this.monitoramento.getEtapa(nivel).subscribe(resp => {this.etapas = resp; this.spinner.hide();});
  }

  aplicar() {
    console.log(this.filtro);
    (this.filtro.ano === null) ? this.alert.danger("Informe o ano!") : this.dialogRef.close(this.filtro);
    
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
