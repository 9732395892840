import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@full-fledged/alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { InstituicaoService } from './services/instituicao.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Portal Etutor';
  public route: string;

  constructor(location: Location, private router: Router, private instituicaoService: InstituicaoService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService) {
    router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();
        // console.log(this.route);
      } else {
        this.route = '';
        // console.log('Erro ao obter');
      }
    });
  }

}
