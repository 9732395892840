import { Component, OnInit } from '@angular/core';
import { RelatoriosService } from '../../services/relatorios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { Instituicao } from '../../model/Instituicao';
import { UnidadeEscolarService } from '../../services/unidade-escolar.service';
import { AlertService } from '@full-fledged/alerts';
import { AlunoService } from '../../services/aluno.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Aluno } from '../../model/Aluno';
import { AlunoDTO } from '../../model/AlunoDTO';
import { UnidadeEnsino } from '../../model/UnidadeEnsino';
import { ProfessorService } from '../../services/professor.service';
import { DadosProfessorCargaHorariaDTO } from '../../model/dto/DadosProfessorCargaHorariaDTO';

@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.scss'],
})
export class RelatorioComponent implements OnInit {
  instituicao: Instituicao = new Instituicao();
  anosLetivos: [] = [];
  anoSelecionado: number = null;
  anoSelecionadoAluno: number = null;
  anoSelecionadoProf: number = null;

  unidadesEnsino: UnidadeEnsino[] = [];
  unidadesEnsinoAux: UnidadeEnsino[] = [];
  unidadeEnsinoSelecionado: UnidadeEnsino = new UnidadeEnsino();

  alunos: AlunoDTO[] = [];
  alunoNaoEncontrado = false;
  alunoSelecionado: AlunoDTO = new AlunoDTO();

  professores: DadosProfessorCargaHorariaDTO[] = [];
  professorNaoEncontrado = false;
  professorSelecionado: DadosProfessorCargaHorariaDTO =
    new DadosProfessorCargaHorariaDTO();

  filtroForm: FormGroup = this.formBuilder.group({
    nomeAluno: ['', [Validators.required]],
    nomeEscola: ['', [Validators.required]],
    nomeProfessor: ['', [Validators.required]],
  });

  escolaNome: string;

  constructor(
    private relatorioService: RelatoriosService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private unidadeEscolarService: UnidadeEscolarService,
    private alertService: AlertService,
    private alunoService: AlunoService,
    private professorService: ProfessorService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.filtros();

    if (localStorage.hasOwnProperty('instituicao')) {
      this.instituicao = JSON.parse(atob(localStorage.getItem('instituicao')));
    }

    if (this.instituicao.id !== undefined) {
      console.log(this.instituicao);
      this.buscarAno(this.instituicao.id);
    }
    this.buscarTodasUnidadesEnsino();
  }

  filtros() {
    this.filtroForm
      .get('nomeAluno')
      .valueChanges.pipe(
        debounceTime(300),
        map((emittedValue) => emittedValue.trim()),
        distinctUntilChanged()
      )
      .subscribe((selectedValue) => {
        if (selectedValue !== '') {
          this.filtroAluno(selectedValue);
        } else {
          this.alunos = [];
          this.alunoSelecionado = new AlunoDTO();
        }
      });

    this.filtroForm
      .get('nomeProfessor')
      .valueChanges.pipe(
        debounceTime(300),
        map((emittedValue) => emittedValue.trim()),
        distinctUntilChanged()
      )
      .subscribe((selectedValue) => {
        this.escolaNome = selectedValue;
        if (selectedValue !== '') {
          this.filtroProfessor(selectedValue);
        } else {
          this.professores = [];
          this.professorSelecionado = new DadosProfessorCargaHorariaDTO();
        }
      });

    this.filtroForm
      .get('nomeEscola')
      .valueChanges.pipe(
        debounceTime(300),
        map((emittedValue) => emittedValue.trim()),
        distinctUntilChanged()
      )
      .subscribe((selectedValue) => {
        console.log('Alterando o valor escola');
        console.log(selectedValue);
        if (selectedValue !== '') {
          this.unidadesEnsino = this.unidadesEnsinoAux;
          this.unidadesEnsino = this.unidadesEnsino.filter((item) => {
            return (
              item.nome.toLowerCase().indexOf(selectedValue.toLowerCase()) > -1
            );
          });
        } else {
          this.unidadesEnsino = this.unidadesEnsinoAux;
        }
      });
  }

  buscarAno(id: number) {
    this.unidadeEscolarService.buscarAnoLetivo(id).subscribe(
      (data) => {
        console.log(data);
        this.anosLetivos = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  buscarTodasUnidadesEnsino() {
    this.unidadeEscolarService
      .buscarUnidadesEnsino(this.instituicao.id)
      .subscribe(
        (data) => {
          this.unidadesEnsino = data;
          this.unidadesEnsinoAux = this.unidadesEnsino;
          console.log(this.unidadesEnsino);
        },
        (error) => {}
      );
  }

  filtroAluno(nome) {
    console.log(this.anoSelecionadoAluno);
    if (this.anoSelecionadoAluno != null) {
      this.alunoService
        .buscarAluno(this.anoSelecionadoAluno, this.instituicao.id, nome)
        .subscribe(
          (data) => {
            console.log(data);
            this.alunos = data;
            this.alunoNaoEncontrado = false;
          },
          (erro) => {
            console.log(erro);
            if (erro.status === 404) {
              this.alunos = [];
              this.alunoSelecionado = new AlunoDTO();
              this.alunoNaoEncontrado = true;
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano');
    }
  }

  filtroProfessor(nome) {
    console.log(this.instituicao.id);
    if (this.anoSelecionadoProf != null) {
      this.professorService
        .buscarProfessores(
          this.instituicao.id,
          nome,
          String(this.anoSelecionadoProf)
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.professores = data;
            this.professorNaoEncontrado = false;
          },
          (erro) => {
            console.log(erro);
            if (erro.status === 404) {
              this.professores = [];
              this.professorSelecionado = new DadosProfessorCargaHorariaDTO();
              this.professorNaoEncontrado = true;
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano');
    }
  }

  selecionarAluno(aluno) {
    console.log(aluno);
    this.alunoSelecionado = aluno;
  }

  selecionarProfessor(prof) {
    console.log(prof);
    this.professorSelecionado = prof;
  }

  selecionarUnidadeEnsino(unidadeEnsino) {

    this.unidadeEnsinoSelecionado = unidadeEnsino;
  }

  displayFn(unidade) {
    return unidade.nome;
  }

  boletimAluno() {
    if (this.alunoSelecionado.id) {
      this.spinner.show();
      this.relatorioService.boletimAluno(this.alunoSelecionado.id).subscribe(
        (data) => {
          this.spinner.hide();
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
        (error) => {
          if (error.status === 404 || error.status === 400) {
            this.alertService.warning('Aluno não tem boletim no momento.');
          } else {
            this.alertService.warning(
              'Ocorreu um erro interno. Tente mais tarde!'
            );
          }
          this.spinner.hide();
          console.log(error);
        }
      );
    } else {
      this.alertService.warning('Selecione o aluno');
    }
  }

  cargaHorariaProf() {
    if (this.professorSelecionado.codigo_servidor) {
      this.spinner.show();
      this.relatorioService
        .cargaHorariaProf(
          this.anoSelecionadoProf,
          this.instituicao.id,
          this.professorSelecionado.codigo_servidor
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o Professor');
    }
  }

  relatorioTotalizacaoMatriculasEscola() {
    if (this.anoSelecionado != null) {
      this.instituicao = this.authService.getInstituicao();
      console.log(this.instituicao);
      this.spinner.show();
      this.relatorioService
        .totalizacaoMatriculasEscola(this.anoSelecionado, this.instituicao.id)
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.warning('Instituição não encontrada!');
            } else if (error.status === 400) {
              this.alertService.danger('Nenhum relatório encontrado!');
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano');
    }
  }

  //   Relatorio De Escola ###################################################################################################

  relatorioCargaHoraria() {
    if (this.unidadeEnsinoSelecionado.id && this.anoSelecionado != null) {
      this.spinner.show();
      this.relatorioService
        .cargaHoraria(
          this.anoSelecionado,
          this.instituicao.id,
          this.unidadeEnsinoSelecionado.id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 400) {
              this.alertService.danger('Nenhum relatório encontrado!');
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano e a Escola');
    }
  }

  relatorioProfessoresETurmas() {
    if (this.unidadeEnsinoSelecionado.id && this.anoSelecionado != null) {
      this.spinner.show();
      this.relatorioService
        .professoresETurmas(
          this.anoSelecionado,
          this.instituicao.id,
          this.unidadeEnsinoSelecionado.id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 400) {
              this.alertService.danger('Nenhum relatório encontrado!');
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano e a Escola');
    }
  }

  relatorioTurmas() {
    if (this.unidadeEnsinoSelecionado.id && this.anoSelecionado != null) {
      this.spinner.show();
      this.relatorioService
        .turmas(
          this.anoSelecionado,
          this.instituicao.id,
          this.unidadeEnsinoSelecionado.id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 400) {
              this.alertService.danger('Nenhum relatório encontrado!');
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano e a Escola');
    }
  }

  relatorioTurmasGrade() {
    if (this.unidadeEnsinoSelecionado.id && this.anoSelecionado != null) {
      this.spinner.show();
      this.relatorioService
        .turmasPorGrade(
          this.anoSelecionado,
          this.instituicao.id,
          this.unidadeEnsinoSelecionado.id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 400) {
              this.alertService.danger('Nenhum relatório encontrado!');
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano e a Escola');
    }
  }

  relatorioTurmasSemProfessor() {
    if (this.unidadeEnsinoSelecionado.id && this.anoSelecionado != null) {
      this.spinner.show();
      this.relatorioService
        .turmasSemProfessor(
          this.anoSelecionado,
          this.instituicao.id,
          this.unidadeEnsinoSelecionado.id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            if (error.status === 500) {
              this.alertService.danger(
                'Ops, ocorreu um erro interno no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 404) {
              this.alertService.danger(
                'Ops, o relatório não está disponível no sistema. Tente novamente mais tarde!'
              );
            } else if (error.status === 400) {
              this.alertService.danger('Nenhum relatório encontrado!');
            }
          }
        );
    } else {
      this.alertService.warning('Selecione o ano e a Escola');
    }
  }
}
