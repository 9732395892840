import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Instituicao} from '../../../model/Instituicao';

@Component({
  selector: 'app-modal-insituicoes',
  templateUrl: './modal-insituicoes.component.html',
  styleUrls: ['./modal-insituicoes.component.scss']
})
export class ModalInsituicoesComponent implements OnInit {
  instituicoes: Instituicao[] = [];
  instituicaoSelecionada = new Instituicao();
  constructor(public dialogRef: MatDialogRef<ModalInsituicoesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Instituicao[]) {
    this.instituicoes = data;
    console.log(this.instituicoes);
  }

  ngOnInit(): void {
  }
  selecionar() {
    console.log(this.instituicaoSelecionada);
    this.dialogRef.close(this.instituicaoSelecionada);
  }
}
