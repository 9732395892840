<section class="container mt-3 color-link">
  <a style="cursor: pointer" class="mb-2" (click)="voltar()">Ver todas disciplinas</a>
  <div id="nome-disciplina">
    <h1>{{disciplinaSelecionada.disciplina.descricao}}</h1>
    <h2> PROFESSOR: {{disciplinaSelecionada.professor.pessoa.nome}}</h2>
  </div>

  <div class="box">
    <div id="aula-online">
      <h2>Aula Online</h2>
    </div>
    <div *ngFor="let aula of materiais">
      <div class="area-link link-aulaON " *ngIf="aula.tipoMatDisponibilizado.id == 1">
        <a [href]="aula.arquivo" target="_blank">CLIQUE AQUI PARA ACESSAR A SALA ONLINE</a>
      </div>
    </div>
  </div>

  <!-- Box de materiais -->
  <div class="box">
    <div id="header-materiais" class="config-h2-materiais">
      <h2>Materiais</h2>
    </div>
    <div *ngFor="let material  of materiais">
      <div class="area-link link-aulaON cursor " *ngIf="material.tipoMatDisponibilizado.id == 2">
        <a title="baixar" (click)="downloadMaterial(material.id, material.arquivo)">{{material.titulo}}</a>
      </div>
    </div>
  </div>
  <!-- final do box de materiais -->
  <!-- inicio do box de atv -->
  <div id="box-atv">
    <div class="row">
      <div class="col-4 atividades">
        <h2>Atividades</h2>
      </div>
      <div class="col-4 prazo">
        <p title="até às 23:59:00">Prazo</p>
      </div>
      <div class="col-4 status">
        <p>Status</p>
      </div>
    </div>
    <div *ngFor="let atividade of atividades">
      <div class="row padding-atv" *ngIf="atividade.materialAula.tipoMatDisponibilizado.id == 4">
        <div class="col-4 text-left space-atvs cursor"  (click)="openDialog(atividade)">
          <!-- link para abrir a modal de informações da atv-->
          <a >{{atividade.materialAula.titulo}}</a>
        </div>
        <div class="col-4 text-center space-atvs">
          <p title="até 23:59:00">{{atividade.materialAula.dataExpiracao  | date: 'dd/MM/yyyy'}}</p>
        </div>
        <div id="color-status-pendente" class="col-4 text-right space-atvs">
          <p>{{atividade.statusAtividade.descricao}}</p>
        </div>
      </div>
    </div>
    <!--    <div class="row padding-atv">-->
    <!--      <div class="col-4 text-left space-atvs">-->
    <!--        <a href="#" data-toggle="modal" data-target="#modal-atv">Atividade 2</a>-->
    <!--      </div>-->
    <!--      <div class="col-4 text-center space-atvs">-->
    <!--        <p title="até 23:59:00">21/05/2021</p>-->
    <!--      </div>-->
    <!--      <div id="color-status-concluido" class="col-4 text-right space-atvs text-success">-->
    <!--        <p>Concluída</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="row padding-atv">-->
    <!--      <div class="col-4 text-left space-atvs">-->
    <!--        <a href="#" data-toggle="modal" data-target="#modal-atv">Atividade 2</a>-->
    <!--      </div>-->
    <!--      <div class="col-4 text-center space-atvs">-->
    <!--        <p title="até 23:59:00">21/05/2021</p>-->
    <!--      </div>-->
    <!--      <div id="color-status-atrasado" class="col-4 text-right space-atvs text-danger">-->
    <!--        <p>Atrasado</p>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <!-- Final do box de atv -->
  <!-- inicio do box de video aulas -->
  <div class="box">
    <div id="video-aulas">
      <div id="header-video-aulas">
        <h2>Vídeo-aulas</h2>
      </div>
      <div id="link-video-aulas">
        <ul *ngFor="let atividade of materiais">
          <li  *ngIf="atividade.tipoMatDisponibilizado.id == 3">
            <a [href]="atividade.arquivo" target="_blank">{{atividade.titulo}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<ff-alerts></ff-alerts>
<ngx-spinner type="ball-atom">
  <p style="color: white" > Carregando... </p>
</ngx-spinner>
