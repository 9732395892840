import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '@full-fledged/alerts';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-login-gestor',
  templateUrl: './login-gestor.component.html',
  styleUrls: ['./login-gestor.component.scss']
})
export class LoginGestorComponent implements OnInit {
  loginForm: FormGroup;

  constructor(private fb: FormBuilder,
              private alertService: AlertService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private authService: AuthService) {
    this.loginForm = fb.group(
      {
        cpf: ['', [Validators.required]],
        senha: ['', [Validators.required]]
      }
    );
  }

  ngOnInit(): void {
    // localStorage.getItem(JSON.parse('instituicaoSelecionada'));
    // localStorage.setItem('instituicao', JSON.parse('instituicaoSelecionada'));

    if (this.authService.checkGestor()) {
      this.router.navigate(['/monitoramento']);
      localStorage.setItem('instituicao', JSON.parse(atob(localStorage.getItem('instituicaoSelecionada'))));
  }

  }
  // tslint:disable-next-line:typedef
  entrar() {
    this.spinner.show();
    this.loginForm.value.cpf = this.loginForm.value.cpf.replace(/[^0-9]/g, '');
    console.log(this.loginForm.value);
    this.authService.loginGestor(this.loginForm.value).subscribe(
      (resp) => {
        this.spinner.hide();
        console.log(resp);
        localStorage.setItem('gestor', btoa(JSON.stringify(resp)));
        this.alertService.success('Seja bem-vindo!');
        this.router.navigate(['/monitoramento']);
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        if (error.status === 401){
          this.alertService.danger(error.error);
        } else {
          this.alertService.danger('Ocorreu um erro interno, tente mais tarde');
        }
      }
    );
  }
}
