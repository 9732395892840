import {Component, OnInit} from '@angular/core';
import {UnidadeEscolarService} from '../../services/unidade-escolar.service';
import {UnidadeEnsino} from '../../model/UnidadeEnsino';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog} from '@angular/material/dialog';
import {ModalYesNoComponent} from '../modal/modal-yes-no/modal-yes-no.component';
import {ModalAvisoComponent} from '../modal/modal-aviso/modal-aviso.component';
import {ActivatedRoute, Router} from '@angular/router';
import {InstituicaoService} from '../../services/instituicao.service';
import {Cidade} from '../../model/Cidade';
import {Instituicao} from '../../model/Instituicao';
import {ModalInsituicoesComponent} from '../modal/modal-insituicoes/modal-insituicoes.component';
import {InstituicaoEvent} from '../../events/instituicao-event';

declare var google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  lat = -2.518621;
  lng = -44.2482439;
  zoom = 14;
  unidades: UnidadeEnsino[] = [];
  unidadeSelecionada = new UnidadeEnsino();
  cidade: Cidade;
  instituicao = new Instituicao();
  userId = null;

  constructor(private unidadeService: UnidadeEscolarService,
              private spinner: NgxSpinnerService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private instituicaoEvent: InstituicaoEvent,
              private instituicaoService: InstituicaoService) {


  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.params.subscribe(params => {
      // console.log(params.id);
      if (!params.id) {
        if (localStorage.hasOwnProperty('cidade')) {
          this.cidade = JSON.parse(localStorage.getItem('cidade'));
          this.getInstituicao();
        } else {
          this.router.navigate(['/']);
        }
      } else {
        // console.log('Veio id');
        this.getInstituicaoId(params.id);
      }
    });
  }

  getInstituicaoId(id: number) {
    this.spinner.show();
    this.instituicaoService.buscarInstituicaoPorId(id).subscribe((data) => {
      this.spinner.hide();
      // console.log(data);
      this.instituicao = data;
      this.salvarInstituicao();
      this.definirLatLng();
    }, (error) => {
      this.router.navigate(['/']);
      // this.abrirAviso();
      this.spinner.hide();
      // console.log(error);
    });
  }

  private definirLatLng() {
    if (this.instituicao.latitude != null && this.instituicao.longitude != null) {
      this.lat = this.toNumber(this.instituicao.latitude);
      this.lng = this.toNumber(this.instituicao.longitude);
    } else {
      this.lat = -2.518621;
      this.lng = -44.2482439;
    }
    this.getUnidadeByInstituicao();
  }

  getInstituicao() {
    this.spinner.show();
    this.instituicaoService.buscarInstituicaoPorCidade(this.cidade.id).subscribe((data) => {
      // console.log(data);
      // console.log('Tamanho: ', data.length);
      if (data.length > 1) {
        // console.log('Veio mais de um');
        //   abrir dialog para ele escolher
        this.abrirInstituicoes(data);
      } else {
        this.instituicao = data[0];
        this.salvarInstituicao();
        this.definirLatLng();
      }
      this.spinner.hide();
    }, (error) => {
      this.abrirAviso();
      this.spinner.hide();
      // console.log(error);
    });
  }

  getUnidadeByInstituicao() {
    this.spinner.show();
    // console.log(this.instituicao.id);
    this.unidadeService.buscarTodasUnidadesEnsino(this.instituicao.id).subscribe((data) => {
      this.spinner.hide();
      this.unidades = data;
      // if (!this.unidades.length) {
      //   this.abrirAviso();
      // }
      // console.log(data);
      // this.transformLateLong();
    }, (error) => {
      this.spinner.hide();
      // console.log(error);
    });
  }

  // tslint:disable-next-line:typedef
  mostrarDadosEscola(unidadeSelecionda: UnidadeEnsino) {
    this.unidadeSelecionada = unidadeSelecionda;
  }


  // tslint:disable-next-line:typedef
  transformLateLong() {
    this.unidades.forEach(x => {
      x.latitude = this.toNumber(x.latitude);
      x.longitude = this.toNumber(x.longitude);
    });

  }

  // tslint:disable-next-line:typedef
  toNumber(s) {
    if (s != null) {
      return Number(s.replace(',', '.'));
    }
  }

  abrirAviso() {
    const dialogRef = this.dialog.open(ModalAvisoComponent, {
      panelClass: 'dialog-responsive',
      disableClose: true
    });
  }

  abrirInstituicoes(instituicoes: Instituicao[]) {
    const dialogRef = this.dialog.open(ModalInsituicoesComponent, {
      panelClass: 'dialog-responsive',
      disableClose: true,
      data: instituicoes
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.instituicao = result;
        this.salvarInstituicao();
        this.definirLatLng();
      }
    });
  }


  salvarInstituicao() {
    localStorage.setItem('instituicaoSelecionada', JSON.stringify(this.instituicao));
    this.instituicaoEvent.alteracao();
  }
}
