import {Zona} from './Zona';
import {SituacaoFuncionamento} from './SituacaoFuncionamento';
import {TipoTurma} from './TipoTurma';

export class BuscaEscolaMonitoramentoFilter {
  ano: number;
  zona: Zona;
  situacao: SituacaoFuncionamento;
  instituicao: number;
  usuario: number;
  turma: string;
  progEducacionalId1: boolean;
  progEducacionalId2: boolean;
  vagasDisponivel: boolean;
  deficitProfessor: boolean;
  tiposTurma: TipoTurma[];
}
