import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {UnidadeEscolarService} from '../../services/unidade-escolar.service';
import {FiltroEscolaMonitoramento} from '../../model/FiltroEscolaMonitoramento';
import {Instituicao} from '../../model/Instituicao';
import {BuscaEscolaMonitoramentoFilter} from '../../model/BuscaEscolaMonitoramentoFilter';
import {AlertService} from '@full-fledged/alerts';
import {EscolaMonitoramento} from '../../model/EscolaMonitoramento';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {SelecionarInstituicaoComponent} from '../../pages/modal/selecionar-instituicao/selecionar-instituicao.component';
import {UsuarioDTO} from '../../model/UsuarioDTO';
import {MatDialog} from '@angular/material/dialog';
import {Perfil} from '../../model/Perfil';
import {MonitoramentoService} from '../../services/monitoramento.service';

@Component({
  selector: 'app-escola-monitoramento',
  templateUrl: './escola-monitoramento.component.html',
  styleUrls: ['./escola-monitoramento.component.scss']
})
export class EscolaMonitoramentoComponent implements OnInit {

  filtros: FiltroEscolaMonitoramento = new FiltroEscolaMonitoramento();
  instituicao = new Instituicao();
  filtroEscolaMonitoramento = new BuscaEscolaMonitoramentoFilter();
  escolasMonitoramento: EscolaMonitoramento[] = [];
  escolasMonitoramentoAux: EscolaMonitoramento[] = [];
  gestor: UsuarioDTO = new UsuarioDTO();

  stateForm: FormGroup = this.formBuilder.group({
    stateService: [null, [Validators.required]],
  });

  constructor(private spinner: NgxSpinnerService,
              private router: Router,
              public authService: AuthService,
              private unidadeEscolarService: UnidadeEscolarService,
              private monitoramentoService: MonitoramentoService,
              private alertService: AlertService,
              public dialog: MatDialog,
              private formBuilder: FormBuilder) {


    this.stateForm.get('stateService').valueChanges
      .pipe(
        debounceTime(300),
        map(emittedValue => emittedValue.trim()),
        distinctUntilChanged()
      )
      .subscribe(selectedValue => {


        if (selectedValue !== '') {
          this.escolasMonitoramento = this.escolasMonitoramentoAux;
          this.escolasMonitoramento = this.escolasMonitoramento.filter(
            item => {
              return (item.nome.toLowerCase().indexOf(selectedValue.toLowerCase()) > -1);
            }
          );
        } else {
          this.escolasMonitoramento = this.escolasMonitoramentoAux;
        }
        // this._filterGroup(selectedValue);
      });
  }

  ngOnInit(): void {
    if (this.authService.checkGestor()) {
      this.buscarFiltros();
      this.gestor = this.authService.getGestor();

      if (localStorage.hasOwnProperty('instituicao')) {
        this.instituicao = JSON.parse(atob(localStorage.getItem('instituicao')));
      }
    }
  }

  buscarFiltros() {
    if (this.instituicao.id !== undefined) {
      this.unidadeEscolarService.buscarFiltro().subscribe((data) => {
        this.filtros = data;
      }, (error) => {
        console.log(error);
      });
    }
  }

  aplicarFiltro() {


    if (this.filtroEscolaMonitoramento.ano === undefined) {
      this.alertService.warning('Selecione o ano letivo');
    } else {
      this.filtroEscolaMonitoramento.instituicao = this.instituicao.id;

      if (this.filtroEscolaMonitoramento.turma === undefined) {
        this.filtroEscolaMonitoramento.turma = 'TODAS';
      }

      const perfil: Perfil = JSON.parse(atob(localStorage.getItem('perfil')));
      if (perfil.id === 5) {
        this.filtroEscolaMonitoramento.usuario = this.gestor.usuario.id;
      } else {
        this.filtroEscolaMonitoramento.usuario = null;
      }


      this.monitoramentoService.buscarEscolaMonitoramento(this.filtroEscolaMonitoramento).subscribe((data) => {

        this.escolasMonitoramento = data;
        this.escolasMonitoramentoAux = data;

        console.log(this.escolasMonitoramentoAux);
        if (!this.escolasMonitoramento.length) {
          this.alertService.warning('Nenhuma escola encontrada');
        }
      }, (error) => {

      });
    }
  }

  ordenarMenorOcupaca() {
    this.escolasMonitoramento.sort((a, b) => {
      return a.porcentagemOcupada < b.porcentagemOcupada ? -1 : a.porcentagemOcupada > b.porcentagemOcupada ? 1 : 0;
    });
    this.alertService.info('Ordenação feita com sucesso!');
  }

  ordenarMaiorOcupaca() {
    this.escolasMonitoramento.sort((a, b) => {
      return a.porcentagemOcupada > b.porcentagemOcupada ? -1 : a.porcentagemOcupada < b.porcentagemOcupada ? 1 : 0;
    });
    this.alertService.info('Ordenação feita com sucesso!');
  }

  corOcupacao(porcentagem: number): string {
    if (porcentagem < 50) {
      return '#EB6666';
    } else if (porcentagem >= 50 && porcentagem < 75) {
      return '#FFA500';
    } else if (porcentagem >= 75) {
      return 'green';
    }
  }

  detalhes(escola) {

    localStorage.setItem('escolaMonitoramento', JSON.stringify(escola));
    this.router.navigate(['/monitoramento/turmas']);
    // this.alertService.info('Em desenvolvimento');
  }

  openListaInstituicao() {
    if (this.gestor.perfilInstituicoes.length > 1) {

      const dialogRef = this.dialog.open(SelecionarInstituicaoComponent, {
        panelClass: 'dialog-responsive',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.instituicao = JSON.parse(atob(localStorage.getItem('instituicao')));

        location.reload();
      });
    }
  }
}
