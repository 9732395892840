import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UnidadeEnsino} from '../model/UnidadeEnsino';
import {FiltroEscolaMonitoramento} from '../model/FiltroEscolaMonitoramento';
import {BuscaEscolaMonitoramentoFilter} from '../model/BuscaEscolaMonitoramentoFilter';
import {EscolaMonitoramento} from '../model/EscolaMonitoramento';
import {AlunoDTO} from '../model/AlunoDTO';

@Injectable({
  providedIn: 'root'
})
export class UnidadeEscolarService {

  private URL = environment.API_URL + '/escola';
  private urlApi = environment.API_RELATORIO_URL;

  constructor(private http: HttpClient ) {
  }

  // tslint:disable-next-line:typedef
  buscarTodasUnidadesEnsino(instituicaoId: number): Observable<UnidadeEnsino[]>  {
    return this.http.get<UnidadeEnsino[]>(`${this.URL}/instituicao/${instituicaoId}`);
  }

  // tslint:disable-next-line:typedef
  // http://192.168.0.31:8080/apietutor-0.0.1/anoLetivo/porInstituicao/3
  buscarAnoLetivo(id: number): Observable<any>  {
    return this.http.get<any>(`${this.urlApi}/anoLetivo/porInstituicao/${id}`);
  }

  buscarFiltro(): Observable<FiltroEscolaMonitoramento>  {
    return this.http.get<FiltroEscolaMonitoramento>(`${this.URL}/filtros`);
  }


  buscarEscolaMonitoramento(filtro: BuscaEscolaMonitoramentoFilter): Observable<EscolaMonitoramento[]>{
    return this.http.put<EscolaMonitoramento[]>(`${this.URL}/monitoramento`, filtro);
  }

  buscarUnidadesEnsino(instituicao): Observable<UnidadeEnsino[]>{
    return this.http.get<UnidadeEnsino[]>(`${this.urlApi}/escolas/${instituicao}`);
  }

}
