import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MaterialAula} from '../../../model/MaterialAula';
import {AlunoAtividade} from '../../../model/AlunoAtividade';
import {AlertService} from '@full-fledged/alerts';
import {AlunoService} from '../../../services/aluno.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-modal-atividade',
  templateUrl: './modal-atividade.component.html',
  styleUrls: ['./modal-atividade.component.scss']
})
export class ModalAtividadeComponent implements OnInit {

  atividade: AlunoAtividade;
  fileToUpload: File = null;
  fileBase64 = null;
  comentarioAluno: string = null;

  constructor(public dialogRef: MatDialogRef<ModalAtividadeComponent>,
              @Inject(MAT_DIALOG_DATA) public atividadeRecebida: AlunoAtividade,
              private alertService: AlertService,
              private alunoService: AlunoService,
              private spinner: NgxSpinnerService) {
    this.atividade = atividadeRecebida;
    console.log(atividadeRecebida);
  }

  ngOnInit(): void {
  }


  close(bool): void {
    this.dialogRef.close(bool);
  }

  // tslint:disable-next-line:typedef
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        this.fileBase64 = reader.result.split(',')[1];
        console.log(this.fileBase64);
      }
    };
  }

  // tslint:disable-next-line:typedef
  enviarAlunoAtividade() {
    if (this.fileBase64 !== null && this.fileToUpload != null) {
      this.atividade.arquivoAnexado = this.fileBase64;
      this.atividade.materialAula = this.atividadeRecebida.materialAula;
      this.atividade.arquivo = this.fileToUpload.name;
      this.atividade.matricula = null;
      this.alunoService.enviarAtividade(this.atividade.id, this.atividade).subscribe((data) => {
          console.log(data);
          this.alertService.success('Resposta enviada com sucesso!!!');
          this.close(true);
        },
        (error) => {
          console.log(error);
        });
    } else {
      this.alertService.warning('Anexe um arquivo de resposta');
    }
  }

  // tslint:disable-next-line:typedef
  downloadMaterial(idMaterial, filename) {
    this.spinner.show();
    this.alunoService.downloadMaterial(idMaterial).subscribe((response) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      });
  }
}
