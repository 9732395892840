import {Component, OnInit} from '@angular/core';
import {AlunoService} from '../../services/aluno.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';
import {FaltaDTO} from '../../model/FaltaDTO';
import {Disciplina} from '../../model/Disciplina';
import {MaterialAula} from '../../model/MaterialAula';
import {ModalAtividadeComponent} from '../modal/modal-atividade/modal-atividade.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalFaltasComponent} from '../modal/modal-faltas/modal-faltas.component';

class FaltaQuantidade {
  disciplina: Disciplina;
  quantidade: number;
}

@Component({
  selector: 'app-acompanhamento',
  templateUrl: './acompanhamento.component.html',
  styleUrls: ['./acompanhamento.component.scss']
})
export class AcompanhamentoComponent implements OnInit {

  faltas: FaltaDTO[] = [];
  faltasQuantidade: FaltaQuantidade[] = [];

  constructor(private alunoService: AlunoService,
              private spinner: NgxSpinnerService,
              public authService: AuthService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (this.authService.check()) {
      this.buscarFaltas();
    }
  }


  // tslint:disable-next-line:typedef
  buscarFaltas() {
    this.spinner.show();
    this.alunoService.buscarFalta(this.authService.getUser().id).subscribe((data) => {
        // console.log(data);
        this.faltas = data;
        this.spinner.hide();
        this.preparaQuantidadeFaltas();
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();

      });
  }

  // tslint:disable-next-line:typedef
  preparaQuantidadeFaltas() {
    this.faltasQuantidade = [];

    this.faltas.forEach(e => {
      const falta = this.faltasQuantidade.find(f => f.disciplina.id === e.disciplina.id);
      if (falta === undefined) {
        const faltaQuantidade = new FaltaQuantidade();
        faltaQuantidade.disciplina = e.disciplina;
        faltaQuantidade.quantidade = e.quantidade;
        this.faltasQuantidade.push(faltaQuantidade);
      } else {
        falta.quantidade += e.quantidade;
      }
    });

    // console.log('Falta quantidade');
    // console.log(this.faltasQuantidade);
  }

  // tslint:disable-next-line:typedef
  openDialogFaltas(disciplinaSele) {
    const dialogRef = this.dialog.open(ModalFaltasComponent, {
      panelClass: 'dialog-responsive',
      data: {disciplina: disciplinaSele, lista: this.faltas }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

}
