<header>
  <div class="container container-header">
    <div id="first-partHeader">
      <div id="logo">
        <img routerLink="/instituicao" [src]="'data:image/jpg;base64,' + instituicaoImagem.imagem" alt="Logo da prefeitura">
        <!-- <a href="#">e-tutor</a> -->
      </div>
    </div>
    <div id="navbar">
      <nav>
        <div class="nav">
          <a
            class="nav-item nav-link"
            (click)="tabSelect = 1"
            [class.active]="tabSelect === 1"
            >Gestão Integrada</a
          >
          <a
            class="nav-item nav-link"
            [class.active]="tabSelect === 2"
            (click)="tabSelect = 2"
            >Indicadores para análise</a
          >
        </div>
      </nav>
    </div>
    <div id="userName" class="second-partHeader">
        <a [matMenuTriggerFor]="menu">
          {{ authService.getGestor().usuario.nome }}
          <i id="arrow" class="fas fa-chevron-down"></i
        ></a>
        <mat-menu #menu="matMenu">
          <button style="margin-top: 40px;" (click)="sair()" mat-menu-item>
            <span>Sair</span>
          </button>
        </mat-menu>
    </div>
  </div>
</header>
<div class="bg-white bg-body">
  <app-escola-monitoramento *ngIf="tabSelect === 1"></app-escola-monitoramento>

  <app-relatorio *ngIf="tabSelect === 2"></app-relatorio>
</div>
