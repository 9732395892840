import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Generico} from '../model/Generico';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private URL = environment.API_RELATORIO_URL;

  constructor(private http: HttpClient ) {
  }


  // tslint:disable-next-line:typedef
  buscarMediccoes(): Observable<Generico[]>  {
    return this.http.get<Generico[]>(`${this.URL}/mediacaoEnsino/todas`);
  }

  buscarModalidades(idMediacao: number): Observable<Generico[]>  {
    return this.http.get<Generico[]>(`${this.URL}/modalidades?idMediacao=${idMediacao}`);
  }

  buscarNiveisEnsino(idModalidade: number): Observable<Generico[]>  {
    return this.http.get<Generico[]>(`${this.URL}/niveisEnsino?idModalidade=${idModalidade}`);
  }

  buscarEtapasEnsino(idNivelEnsino: number): Observable<Generico[]>  {
    return this.http.get<Generico[]>(`${this.URL}/etapasEnsino?idNivelEnsino=${idNivelEnsino}`);
  }

}
