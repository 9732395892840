import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {PortalAlunoComponent} from './pages/portal-aluno/portal-aluno.component';
import {LoginComponent} from './pages/login/login.component';
import {PerfilComponent} from './pages/perfil/perfil.component';
import {EtutorComponent} from './pages/etutor/etutor.component';
import {LoginGestorComponent} from './pages/login-gestor/login-gestor.component';
import {MonitoramentoComponent} from './pages/monitoramento/monitoramento.component';
import {TurmasMonitoramentoComponent} from './pages/turmas-monitoramento/turmas-monitoramento.component';

const routes: Routes = [
  {path: 'instituicao', component: HomeComponent},
  {path: 'instituicao/:id', component: HomeComponent},
  {path: 'portal', component: PortalAlunoComponent},
  {path: 'login', component: LoginComponent},
  {path: 'login/gestor', component: LoginGestorComponent},
  {path: 'monitoramento', component: MonitoramentoComponent},
  {path: 'monitoramento/turmas', component: TurmasMonitoramentoComponent},
  {path: '', component: EtutorComponent},
  {path: 'perfil', component: PerfilComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
