import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { InstituicaoService } from '../../services/instituicao.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '@full-fledged/alerts';
import { Instituicao } from '../../model/Instituicao';
import { InstituicaoEvent } from '../../events/instituicao-event';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public route: string;
  instituicao = new Instituicao();

  constructor(
    location: Location,
    private router: Router,
    private instituicaoService: InstituicaoService,
    private spinner: NgxSpinnerService,
    private instituicaoEvent: InstituicaoEvent,
    private alertService: AlertService
  ) {
    router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();
        // console.log(this.route);
      } else {
        this.route = '';
        // console.log('Erro ao obter');
      }
    });

    instituicaoEvent.alteracao$.subscribe((data) => {
      if (localStorage.hasOwnProperty('instituicaoSelecionada')) {
        this.instituicao = JSON.parse(
          localStorage.getItem('instituicaoSelecionada')
        );
      }
    });
  }

  ngOnInit(): void {
    if (localStorage.hasOwnProperty('cidade')) {
      this.getInstituicao();
    } else {
      this.router.navigate(['/']);
    }
    const insituicao = localStorage.getItem('instituicao');
    if (insituicao) {
      this.instituicao = JSON.parse(atob(insituicao));
    }
  }

  getInstituicao() {
    this.spinner.show();
    this.instituicaoService
      .buscarInstituicaoPorCidade(JSON.parse(localStorage.getItem('cidade')).id)
      .subscribe(
        (data) => {
          // console.log(data);
          // TODO: Alterar aqui
          // this.instituicao = data;
          data.map((instituicao) => {
            return localStorage.setItem(
              'instituicao', btoa(JSON.stringify(instituicao)
            ));
          });
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          // console.log(error);
        }
      );
  }
}
