import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ModalFiltroInstituicaoComponent} from '../../../pages/modal/modal-filtro-instituicao/modal-filtro-instituicao.component';
import {MonitoramentoService} from '../../../services/monitoramento.service';
import {AlertService} from '@full-fledged/alerts';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog} from '@angular/material/dialog';
import {ModalFiltroTurmaComponent} from '../../../pages/modal/modal-filtro-turma/modal-filtro-turma.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {BuscaEscolaMonitoramentoFilter} from '../../../model/BuscaEscolaMonitoramentoFilter';
import {EscolaMonitoramento} from '../../../model/EscolaMonitoramento';
import {TurmaMonitoramento} from '../../../model/TurmaMonitoramento';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-turma',
  templateUrl: './turma.component.html',
  styleUrls: ['./turma.component.scss']
})
export class TurmaComponent implements OnInit, AfterViewInit {
  anoSelecionado;
  turmasMonitoramento: TurmaMonitoramento[] = [];
  filtroEscolaMonitoramento = new BuscaEscolaMonitoramentoFilter();
  escolaSelecionada: EscolaMonitoramento = new EscolaMonitoramento();

  displayedColumns: string[] = ['turma', 'turno', 'vagas', 'matriculados', 'saldo'];
  dataSource = new MatTableDataSource<TurmaMonitoramento>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private monitoramentoService: MonitoramentoService,
              private alertService: AlertService,
              private spinner: NgxSpinnerService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Exibição';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  abrirFiltro() {
    const dialogRef = this.dialog.open(ModalFiltroTurmaComponent, {
      panelClass: 'dialog-responsive',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        console.log(result);
        this.anoSelecionado = result[0];
        this.escolaSelecionada = result[1];
        this.buscarTurmasMonitoramento();
      }
    });
  }

  buscarTurmasMonitoramento() {
    this.spinner.show();
    this.monitoramentoService.buscarTurmaMonitoramento(this.escolaSelecionada.ano).subscribe((data) => {
      this.turmasMonitoramento = data;
      this.dataSource.data = data;
      this.spinner.hide();
      console.log(this.turmasMonitoramento);
      if (!this.turmasMonitoramento.length) {
        this.alertService.warning('Nenhuma turma encontrada');
      }
    }, (error) => {
      this.spinner.hide();
      this.alertService.danger('Ops, ocorreu um erro. Tente novamente mais tarde');
    });
  }

  getPageSizeOptions(): number[] {
    if (this.dataSource.data.length && this.dataSource.data.length > 20) {
      return [5, 10, 20, this.dataSource.data.length];
    } else {
      return [5, 10, 20];
    }
  }
}
