import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { Aluno } from '../model/Aluno';
import { AlunoAtividade } from '../model/AlunoAtividade';
import { AlunoDTO } from '../model/AlunoDTO';
import { FaltaDTO } from '../model/FaltaDTO';
import { MaterialAula } from '../model/MaterialAula';
import { TurmaDisciplina } from '../model/TurmaDisciplina';
import { TurmaHorarioDisciplina } from '../model/TurmaHorarioDisciplina';

@Injectable({
  providedIn: 'root'
})
export class AlunoService {

  private URL = environment.API_ALUNO + '/aluno';
  private urlApi = environment.API_RELATORIO_URL;

  constructor(private http: HttpClient ) {
  }

  // tslint:disable-next-line:typedef
  buscarPefil(alunoId: number): Observable<Aluno>  {
    return this.http.get<Aluno>(`${this.URL}/detalhes/${alunoId}`);
  }


  // tslint:disable-next-line:typedef
  buscarDisciplinasPorAluno(alunoId: number): Observable<TurmaDisciplina[]>  {
    return this.http.get<TurmaDisciplina[]>(`${this.URL}/disciplinas/${alunoId}`);
  }

  // tslint:disable-next-line:typedef
  buscarMateriaAula(idTurmaDisciplina: number): Observable<MaterialAula[]>  {
    return this.http.get<MaterialAula[]>(`${this.URL}/material/${idTurmaDisciplina}`);
  }

  // tslint:disable-next-line:typedef
  buscarAtividade(idAluno: number, idTurmaDisciplina: number): Observable<AlunoAtividade[]>  {
    return this.http.get<AlunoAtividade[]>(`${this.URL}/atividade/aluno/${idAluno}/turmaDisciplinaId/${idTurmaDisciplina}`);
  }

  // tslint:disable-next-line:typedef
  horarioTurma(idAluno: number): Observable<TurmaHorarioDisciplina[]>  {
    return this.http.get<TurmaHorarioDisciplina[]>(`${this.URL}/horarioTurma/${idAluno}`);
  }

  // Faltas
  // tslint:disable-next-line:typedef
  buscarFalta(idAluno: number): Observable<FaltaDTO[]>  {
    return this.http.get<FaltaDTO[]>(`${this.URL}/faltas/${idAluno}`);
  }

  enviarAtividade(idAlunoAtv: number, alunoAtividade: AlunoAtividade): Observable<any> {
    return this.http.put<any>(`${this.URL}/atividade/resposta/${idAlunoAtv}`, alunoAtividade);
  }

  buscarAluno(anoLetivo, instituicao, nome: string): Observable<AlunoDTO[]>{
    return this.http.get<AlunoDTO[]>(`${this.urlApi}/matriculas/${anoLetivo}?idInstituicao=${instituicao}&nomeAluno=${nome}`);
  }

  downloadMaterial(idMaterial): Observable<any>{
    return this.http.get<any>(`${this.URL}/material/download/${idMaterial}`, { responseType: 'blob' as 'json'});
  }

}
