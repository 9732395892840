<div class="bg-container">
  <div class="filters">
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-filtro" (click)="abrirFiltro()">
      + Adicionar filtro
    </button>
  </div>

  <div class="indicadoresVisu">
    <div class="filtros_selecionados">
      <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngIf="filtro.ano">ANO:
          {{filtro.ano}}
        </mat-chip>

        <!-- <mat-chip *ngIf="filtro.escola">ESCOLA
        </mat-chip>

        <mat-chip *ngIf="filtro.modalidade">MODALIDADE
        </mat-chip>

        <mat-chip *ngIf="filtro.nivel">NIVEL
        </mat-chip>

        <mat-chip *ngIf="filtro.etapa">ETAPA
        </mat-chip> -->

        <mat-chip *ngIf="filtro.turno">TURNO
        </mat-chip>

        <!-- <mat-chip>VAGAS:
        </mat-chip> -->

      </mat-chip-list>
    </div>
    <div class="col-md-4 col-6">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label class="color-white">Indicadores para Visualização</mat-label>
        <mat-select [formControl]="indicadoresForm" multiple>
          <mat-select-trigger>
            {{indicadoresForm.value ? indicadoresForm.value[0] : ''}}
            <span *ngIf="indicadoresForm.value?.length > 1" class="example-additional-selection">
              (+{{indicadoresForm.value.length - 1}} {{indicadoresForm.value?.length === 2 ? 'outro' : 'outros'}})
            </span>
          </mat-select-trigger>
          <mat-option (onSelectionChange)="teste($event)" *ngFor="let topping of indicadores" [value]="topping">
            {{topping}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Vai ser um select com check box -->
  </div>
</div>

<!--Tabeka-->

<h4 class="mt-4" *ngIf="dataSource.data.length === 0">
  <i class="fas fa-exclamation-circle"></i> Adicione um filtro para que seja exibido os dados
</h4>

<h4 class="mt-4" *ngIf="dataSource.data.length === 0 && !temEscola">
  <i class="fas fa-exclamation-circle"></i> Nenhuma escola encontrada!
</h4>

<div class="mat-elevation-z8 mt-4">



  <table mat-table [dataSource]="dataSource" class="table-striped" cdkDropList cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)">

    <!-- Position Column -->
    <ng-container matColumnDef="inep">
      <th cdkDrag mat-header-cell *matHeaderCellDef> INEP </th>
      <td mat-cell *matCellDef="let element"> {{element.inep}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="escolas">
      <th cdkDrag mat-header-cell *matHeaderCellDef> ESCOLAS </th>
      <td mat-cell *matCellDef="let element"> {{element.escola}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="modalidade">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef> MODALIDADE </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.modalidade}} </td>
    </ng-container>

    <ng-container matColumnDef="nivel">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef> NIVEL </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.nivel}} </td>
    </ng-container>

    <ng-container matColumnDef="etapa">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef> ETAPA </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.etapa}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="p-vagas">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[0]"> VAGAS </th>
      <td mat-cell class="text-center" *matCellDef="let element" [hidden]="exibirColunas[0]"> {{element.vagas}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="p-pre">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[1]"> PRÉ MATRICULAS
      </th>
      <td mat-cell class="text-center" *matCellDef="let element" [hidden]="exibirColunas[1]"> {{element.preMatriculas}}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="p-matriculas">
      <th cdkDrag class="text-center" mat-header-cell *matHeaderCellDef [hidden]="exibirColunas[2]"> MATRICULAS </th>
      <td mat-cell class="text-center" *matCellDef="let element" [hidden]="exibirColunas[2]"> {{element.matriculas}}
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- <tr class="mat-row" *ngIf="dataSource.length === 0">
      <td class="mat-cell" colspan="9999">
        NENHUMA ESCOLA ENCONTRADA!
      </td>
    </tr> -->
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>
</div>
<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>