export class Instituicao {
  id: number;
  razao: string;
  nomeFantasia: string;
  cnpj: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  nomeLogo: string;
  caminhoLogo: string;
  imagem: any;
  latitude: any;
  longitude: any;
}
