import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '@full-fledged/alerts';
import { BuscaEscolaMonitoramentoFilter } from '../../../model/BuscaEscolaMonitoramentoFilter';
import { FiltroEscolaMonitoramento } from '../../../model/FiltroEscolaMonitoramento';
import { Instituicao } from '../../../model/Instituicao';
import { Perfil } from '../../../model/Perfil';
import { TipoTurma } from '../../../model/TipoTurma';
import { AuthService } from '../../../services/auth.service';
import { UnidadeEscolarService } from '../../../services/unidade-escolar.service';


@Component({
  selector: 'app-modal-filtro-instituicao',
  templateUrl: './modal-filtro-instituicao.component.html',
  styleUrls: ['./modal-filtro-instituicao.component.scss']
})
export class ModalFiltroInstituicaoComponent implements OnInit {
  filtros: FiltroEscolaMonitoramento = new FiltroEscolaMonitoramento();
  filtroEscolaMonitoramento = new BuscaEscolaMonitoramentoFilter();
  instituicao = new Instituicao();
  tiposTurmaSelecionado = [];
  tiposTurma: TipoTurma[] = [
    {id: 1, descricao: 'ENSINO FUNDAMENTAL E MEDIO'},
    {id: 2, descricao: 'EDUCACAO INFANTIL'},
    {id: 3, descricao: 'ATIVIDADE COMPLEMENTAR'},
    {id: 4, descricao: 'ATENDIMENTO EDUCACIONAL ESPECIALIZADO'},
  ];

  programasSelecionado = [];
  programaEducacional: string[] = ['BRASIL ALFABETIZADO', 'ACELERA BRASIL'];
  @ViewChild('allSelected') private allSelected: Input;
  @ViewChild('allSelectedTurma') private allSelectedTurma: Input;

  constructor(public dialogRef: MatDialogRef<ModalFiltroInstituicaoComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private unidadeEscolarService: UnidadeEscolarService,
              private alertService: AlertService,
              public authService: AuthService) {
    console.log(data);
  }

  ngOnInit(): void {
    this.buscarFiltros();
    if (localStorage.hasOwnProperty('instituicao')) {
      this.instituicao = JSON.parse(atob(localStorage.getItem('instituicao')));
    }
  }

  buscarFiltros() {
    this.unidadeEscolarService.buscarFiltro().subscribe((data) => {
      this.filtros = data;
      this.filtroEscolaMonitoramento.situacao = null;
      this.filtroEscolaMonitoramento.zona = null;
      this.filtroEscolaMonitoramento.turma = 'TODAS';
      this.filtroEscolaMonitoramento.vagasDisponivel = null;
      this.filtroEscolaMonitoramento.deficitProfessor = null;
    }, (error) => {
      console.log(error);
    });
  }

  aplicarFiltro() {
    if (this.filtroEscolaMonitoramento.ano === undefined) {
      this.alertService.warning('Selecione o ano letivo');
    } else {
      this.filtroEscolaMonitoramento.instituicao = this.instituicao.id;

      if (this.filtroEscolaMonitoramento.turma === undefined) {
        this.filtroEscolaMonitoramento.turma = 'TODAS';
      }

      const perfil: Perfil = JSON.parse(atob(localStorage.getItem('perfil')));
      if (perfil.id === 5) {
        this.filtroEscolaMonitoramento.usuario = this.authService.getGestor().usuario.id;
      } else {
        this.filtroEscolaMonitoramento.usuario = null;
      }

      if (this.tiposTurmaSelecionado.length) {
        this.filtroEscolaMonitoramento.tiposTurma = this.tiposTurmaSelecionado;
      } else {
        this.filtroEscolaMonitoramento.tiposTurma = null;
      }

      this.filtroEscolaMonitoramento.progEducacionalId1 = false;
      this.filtroEscolaMonitoramento.progEducacionalId2 = false;
      if (this.programasSelecionado.length) {
        if (this.programasSelecionado.find(element => element === 'BRASIL ALFABETIZADO')) {
          this.filtroEscolaMonitoramento.progEducacionalId1 = true;
        }
        if (this.programasSelecionado.find(element => element === 'ACELERA BRASIL')) {
          this.filtroEscolaMonitoramento.progEducacionalId2 = true;
        }
      }

      console.log(this.filtroEscolaMonitoramento);
      console.log(JSON.stringify(this.filtroEscolaMonitoramento));
      this.close(this.filtroEscolaMonitoramento);
    }
  }

  toggleSelecaoPE() {
    // @ts-ignore
    if (this.allSelected.nativeElement.checked) {
      this.programasSelecionado = this.programaEducacional;
    } else {
      this.programasSelecionado = [];
    }
  }

  toggleSelecaoTurmas() {
    // @ts-ignore
    if (this.allSelectedTurma.nativeElement.checked) {
      this.tiposTurmaSelecionado = this.tiposTurma;
    } else {
      this.tiposTurmaSelecionado = [];
    }
  }

  close(filtro): void {
    this.dialogRef.close(filtro);
  }
}
