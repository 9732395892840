import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@full-fledged/alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  login = true;
  maxDate: Date;
  minDate: Date;

  constructor(private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService) {
    this.loginForm = fb.group(
      {
        id: ['', [Validators.required]],
        dataNascimento: ['', [Validators.required]]
      }
    );

  }

  ngOnInit(): void {
    if (this.authService.check()) {
      this.router.navigate(['/portal']);
    }
  }

  toggleLogin(event) {
    console.log(event);
    this.login = event;
  }

  // tslint:disable-next-line:typedef
  entrar() {
    this.spinner.show();
    this.authService.login(this.loginForm.value).subscribe(
      (resp) => {
        console.log(resp);
        localStorage.setItem('aluno', btoa(JSON.stringify(resp)));
        this.alertService.success('Seja bem-vindo!');
        this.router.navigate(['/portal']);
      },
      (error) => {
        if (error.status === 404) {
          this.alertService.danger('Aluno não encontrado ou não está matriculado');
        } else if (error.status === 403) {
          this.alertService.danger(error.error.mensagemUsuario);
        } else {
          this.alertService.danger('Ocorreu um erro interno, tente mais tarde');
        }
      }
    );
  }
}
