import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { Generico } from '../model/Generico';
import { Modalidade } from '../model/Modalidade';
import { Turno } from '../model/Turno';

@Injectable({
  providedIn: 'root'
})
export class MatriculaAlunoService {

  private URL = environment.API_ALUNO;

  constructor(private http: HttpClient) {
  }

  // tslint:disable-next-line:typedef
  getModalidade(entidade: number): Observable<Modalidade[]> {
    console.log(`${this.URL}/util/modalidade`);
    return this.http.get<Modalidade[]>(`${this.URL}/util/modalidade?instituicao=${entidade}`).pipe(take(1));
  }
  // tslint:disable-next-line:typedef
  getTurno(): Observable<Turno[]> {
    return this.http.get<Turno[]>(`${this.URL}/util/turno`).pipe(take(1));
  }
  // tslint:disable-next-line:typedef
  getEtapa(id: number, entidade: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(`${this.URL}/util/etapa?instituicao=${entidade}&nivel=${id}`).pipe(take(1));
  }
  // tslint:disable-next-line:typedef
  getEscola(etapaID: number, turnoID: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(`${this.URL}/util/escola?etapa=${etapaID}&turno=${turnoID}`).pipe(take(1));
  }
  // tslint:disable-next-line:typedef
  getNivel(id: number, entidade: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(`${this.URL}/util/nivel?instituicao=${entidade}&modalidade=${id}`).pipe(take(1));
  }

  postMatricula(matricula: any): Observable<any> {
    return this.http.post(`${this.URL}/matricula`, matricula).pipe(take(1));
  }

  // matricula/reemitir?aluno=10001
  emitirMatricula(id: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.URL}/matricula/reemitir?aluno=${id}`,
      { observe: 'body', responseType: 'blob' as 'json' }).pipe(take(1));
  }

  // api-aluno/util/copula?aluno=17002
  copulaAluno(idAluno: number): Observable<any> {
    return this.http.get<any>(`${this.URL}/util/copula?aluno=${idAluno}`).pipe(take(1));
  }

  cadastrarAluno(alunoForm): Observable<any> {
    return this.http.post(`${this.URL}/aluno`, alunoForm).pipe(take(1));
  }

}
