import {SituacaoFuncionamento} from './SituacaoFuncionamento';
import {DependenciaAdministrativa} from './DependenciaAdministrativa';
import {RegulamentacaoConselho} from './RegulamentacaoConselho';
import {LocalizacaoDiferenciada} from './LocalizacaoDiferenciada';
import {MaterialEtnico} from './MaterialEtnico';
import {LinguaIndigena} from './LinguaIndigena';
import {CategoriaPrivada} from './CategoriaPrivada';
import {TipoLogradouro} from './TipoLogradouro';
import {Cidade} from './Cidade';
import {OcupacaoEscola} from './OcupacaoEscola';
import {Zona} from './Zona';
import {ConveniadaPoderPublico} from './ConveniadaPoderPublico';
import {DependenciaEscola} from './DependenciaEscola';
import {MantenedoraEscola} from './MantenedoraEscola';
import {TipoAbastecimentoAgua} from './TipoAbastecimentoAgua';
import {TipoAbastecimentoEnergia} from './TipoAbastecimentoEnergia';
import {TipoDestinoLixo} from './TipoDestinoLixo';
import {TipoEsgotoSanitario} from './TipoEsgotoSanitario';
import {LocalizacaoFisicaUnidadeEnsino} from './LocalizacaoFisicaUnidadeEnsino';
import {AnoLetivo} from './AnoLetivo';
import {Instituicao} from './Instituicao';

export class UnidadeEnsino {
  id: number;
  inep: string;
  uc: number;
  nome: string;
  cep: string;
  endereco: string;
  bairro: string;
  enderecoNumero: string;
  enderecoComplemento: string;
  telefone: string;
  telefonePublico01: string;
  telefonePublico02: string;
  fax: string;
  email: string;
  homepage: string;
  distrito: string;
  cnpjEscolaPrivada: string;
  acessoInternet: boolean;
  alimentacaoEscola: boolean;
  aguaFiltrada: boolean;
  educacaoIndigena: boolean;
  longitude: any;
  latitude: any;
  internetBandaLarga: boolean;
  atendimentoEducalizacaoEspecializado: boolean;
  cedeEspacoBrasilAlfabetizado: boolean;
  abreFinaisDeSemana: boolean;
  formacaoPorAlternancia: boolean;
  ensinoFundamentalEmCiclos: boolean;
  atividadesComplementares: boolean;
  acessibilidade: boolean;
  situacao: SituacaoFuncionamento;
  dependencia: DependenciaAdministrativa;
  regulamentacao: RegulamentacaoConselho;
  localizacao: LocalizacaoDiferenciada;
  materialEtnico: MaterialEtnico;
  linguaIndigenaEnsino: LinguaIndigena;
  catPrivada: CategoriaPrivada;
  tipoLogradouro: TipoLogradouro;
  cidade: Cidade;
  ocupacao: OcupacaoEscola;
  zona: Zona;
  conveniada: ConveniadaPoderPublico;
  // polo : Polo;
  // equipamentos : EquipamentoEscola[];
  dependencias: DependenciaEscola[];
  mantenedoras: MantenedoraEscola[];
  abastecimentosAgua: TipoAbastecimentoAgua[];
  abastecimentosEnergia: TipoAbastecimentoEnergia[];
  destinosLixo: TipoDestinoLixo[];
  esgotosSanitario: TipoEsgotoSanitario[];
  compartilhamentos: UnidadeEnsino[];
  localizacoes: LocalizacaoFisicaUnidadeEnsino[];
  // lotacoes : Lotacao[];
  anos: AnoLetivo[];
  instituicao: Instituicao;
  inepInteger: number;
  // talvez precisa adicionar enderecoFormatado;
}
