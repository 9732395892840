import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@full-fledged/alerts';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as moment from 'moment';
import { Cidade } from 'src/app/model/Cidade';
import { Estado } from 'src/app/model/Estado';
import { Generico } from 'src/app/model/Generico';
import { Modalidade } from 'src/app/model/Modalidade';
import { Turno } from 'src/app/model/Turno';
import { InstituicaoService } from 'src/app/services/instituicao.service';
import { MatriculaAlunoService } from 'src/app/services/matriculaAluno.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-cadastro-aluno',
  templateUrl: './cadastro-aluno.component.html',
  styleUrls: ['./cadastro-aluno.component.scss'],
})
export class CadastroAlunoComponent implements OnInit {
  geral: FormGroup;
  endereco: FormGroup;
  documentos: FormGroup;
  preMatricula: FormGroup;

  listaModalidades: Modalidade[] = [];
  listaNiveis: Generico[] = [];
  listaEtapas: Generico[] = [];
  listaTurnos: Turno[] = [];
  listaEscolas: Generico[] = [];
  listaEstados: Estado[] = [];
  listaCidades: Cidade[] = [];
  listaTodasCidades: Cidade[] = [];
  listaSexo: Generico[] = [];
  listaLogradouros: Generico[] = [];
  orgaos: Generico[] = [];

  idTurno: number;
  idEtapa: number;

  semEscola = true;

  loadingFinalizar = false;

  sortDescricao = (a, b) => {
    return a.descricao > b.descricao ? 1 : -1;
  };

  dadosAluno: any;
  textMatriculado: string;

  entidade = JSON.parse(localStorage.getItem('instituicaoSelecionada'));

  maxDate: string;
  minDate: string;

  @Output() loginToggle = new EventEmitter();
  @ViewChild('stepper') stepper;

  @ViewChild('sucessoCadastro')
  public readonly mySwal!: SwalComponent;

  constructor(
    private fb: FormBuilder,
    private matriculaService: MatriculaAlunoService,
    private alertService: AlertService,
    private instituicao: InstituicaoService,
    private matricula: MatriculaAlunoService
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = moment(new Date(currentYear - 100, 0, 1)).format(
      'YYYY-MM-DD'
    );
    this.maxDate = moment(new Date()).format('YYYY-MM-DD');

    this.geral = this.fb.group({
      nome: [null, Validators.required],
      pai: [null],
      mae: [null, Validators.required],
      sexo: [null, Validators.required],
      email: [null, Validators.email],
      dataNascimento: [null, [Validators.required]],
      cidadeNascimento: [null],
      bolsaFamilia: [null],
      celular: [null],
      telefone: [null],
    });

    this.endereco = this.fb.group({
      cep: [null, Validators.required],
      uf: [null, Validators.required],
      cidade: [null, Validators.required],
      nomeLogradouro: [null, Validators.required],
      tipoLogradouro: [null, Validators.required],
      bairro: [null, Validators.required],
      complemento: [null],
      numero: [null],
    });

    this.documentos = this.fb.group({
      cpf: [null],
      codigoNis: [null],
      rg: [null],
      dataExpedicao: [null],
      digitoRg: [null],
      orgaoExpedidor: [null],
    });

    this.preMatricula = this.fb.group({
      modalidade: [null, Validators.required],
      nivel: [null, Validators.required],
      etapaEnsino: [null, Validators.required],
      turno: [null, Validators.required],
      unidadeEnsino: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    if (!this.entidade.id) {
      alert('Nenhuma instituicao selecionada');
    }
    this.obterModalidades();
    this.obterTurnos();
    this.obterUFs();
    this.getSexos();
    this.logadrouros();
    this.getOrgaos();
  }

  toggleLogin() {
    this.loginToggle.emit(true);
  }

  obterModalidades() {
    this.matriculaService
      .getModalidade(this.entidade.id)
      .subscribe(
        (resp) => (this.listaModalidades = resp.sort(this.sortDescricao))
      );
  }

  onChangeModalidade(modalidade) {
    this.preMatricula.get('nivel').reset();
    this.listaNiveis = [];
    this.obterNiveis(modalidade.source.value);
  }

  obterUFs() {
    this.instituicao
      .buscarTodosEstados()
      .subscribe((resp) => (this.listaEstados = resp));
  }

  onChangeUF(estado) {
    const idEstado = estado.source.value;
    if (idEstado) {
      this.instituicao
        .buscarCisdadesPorId(idEstado)
        .subscribe(
          (resp) => (this.listaCidades = resp.sort(this.sortDescricao))
        );
    }
  }

  logadrouros() {
    this.instituicao.buscarTiposLogradouros().subscribe(resp => {
      this.listaLogradouros = resp;
    });
  }

  obterNiveis(idModalidade: number) {
    this.matriculaService
      .getNivel(idModalidade, this.entidade.id)
      .subscribe((resp) => (this.listaNiveis = resp));
  }

  onChangeNivel(nivel) {
    this.preMatricula.get('etapaEnsino').reset();
    this.listaEtapas = [];
    this.obterEtapas(nivel.source.value);
  }

  obterEtapas(idNivel: number) {
    this.matriculaService
      .getEtapa(idNivel, this.entidade.id)
      .subscribe((resp) => (this.listaEtapas = resp.sort(this.sortDescricao)));
  }

  obterTurnos() {
    this.matriculaService
      .getTurno()
      .subscribe((resp) => (this.listaTurnos = resp));
  }

  onChangeTurno(turno) {
    this.preMatricula.get('unidadeEnsino').reset();
    this.listaEscolas = [];
    this.idTurno = turno.source.value;
    if (this.idEtapa) {
      this.onOpenEscola();
    }
  }

  onChangeEtapa(etapa) {
    this.preMatricula.get('unidadeEnsino').reset();
    this.listaEscolas = [];
    this.idEtapa = etapa.source.value;
    if (this.idTurno) {
      this.onOpenEscola();
    }
  }

  verificarTodosCampos(): boolean {
    const valido =
      this.preMatricula.get('modalidade').value &&
      this.preMatricula.get('nivel').value &&
      this.preMatricula.get('etapaEnsino').value &&
      this.preMatricula.get('turno').value;
    return valido;
  }

  onOpenEscola() {
    this.matriculaService
      .getEscola(this.idEtapa, this.idTurno)
      .subscribe((resp) => {
        resp.length === 0 ? (this.semEscola = true) : (this.semEscola = false);
        this.listaEscolas = resp.sort(this.sortDescricao);
      });
  }

  getOrgaos() {
    this.instituicao.buscarOrgaos().subscribe(resp => this.orgaos = resp.sort(this.sortDescricao));
  }

  getSexos() {
    this.instituicao.getSexo().subscribe((resp) => (this.listaSexo = resp));
  }

  openMatricula(file) {
    const fileRequest = new Blob([file], { type: 'application/pdf' });
    window.open(URL.createObjectURL(fileRequest));
  }

  cadastrarAluno() {
    const dataExpedicaoLocal: moment.Moment = moment.utc(this.documentos.value.dataExpedicao).local();
    const dataNascimentoLocal: moment.Moment = moment.utc(this.geral.value.dataNascimento).local();

    this.documentos.value.dataExpedicao ? this.documentos.value.dataExpedicao = dataExpedicaoLocal.format("DD/MM/YYYY").toString() :
      this.documentos.value.dataExpedicao = null;

    this.geral.value.dataNascimento ? this.geral.value.dataNascimento = dataNascimentoLocal.format("DD/MM/YYYY").toString() :
      this.geral.value.dataNascimento = null;

    const aluno = {
      instituicao: this.entidade.id,
      ...this.geral.value,
      ...this.endereco.value,
      ...this.documentos.value,
      ...this.preMatricula.value,
    };

    if (this.geral.invalid) {
      this.alertService.danger('Formulário de Informações Gerais incompleto');
    } else if (this.endereco.invalid) {
      this.alertService.danger('Formulário de Endereço incompleto');
    } else if (this.documentos.invalid) {
      this.alertService.danger('Formulário de Documentos incompleto');
    } else if (this.preMatricula.invalid) {
      this.alertService.danger('Formulário de Pré-matrícula incompleto');
    } else {
      console.log(aluno);

      this.loadingFinalizar = true;

      this.matriculaService.cadastrarAluno(aluno).subscribe(
        (resp) => {
          this.dadosAluno = resp;
          this.textMatriculado =
            '<p><strong>${resp.nome},</strong> para acessar o sistema:</p>' +
            '<p><strong>Codigo:</strong> ${resp.id}</p>' +
            '<p><strong>Data de Nascimento:</strong> ${resp.dataNascimento}</p>' +
            '<p>Visualizar comprovante de matricula?</p>';

          console.log(this.textMatriculado);
        },
        (e) => {
          this.alertService.danger('Houve um erro, verifique os campos e tente novamente');
          this.loadingFinalizar = false;
        },
        async () => {
          this.loadingFinalizar = false;

          const swal = Swal.fire({
            title: 'Cadastrado com sucesso!',
            icon: 'success',
            allowOutsideClick: false,
            reverseButtons: true,
            html:
              `<div class="align-left"><strong>${this.dadosAluno.nome}</strong>, para acessar o sistema:<br></div>` +
              `<div class="align-left">Codigo: <strong>${this.dadosAluno.id}</strong><br></div>` +
              `<div class="align-left">Data de Nascimento: <strong>${this.dadosAluno.dataNascimento}</strong><br></div>` +
              `Visualizar comprovante de matricula?<br>`,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
              'Visualizar',
            cancelButtonText:
              'Fechar',
          });

          const { isConfirmed } = await swal;
          if (isConfirmed) {
            this.matricula.emitirMatricula(this.dadosAluno.id).subscribe(resp => this.openMatricula(resp));
            //this.openMatricula(this.dadosAluno);
          }
        }
      );
      this.documentos.value.dataExpedicao ? this.documentos.value.dataExpedicao = dataExpedicaoLocal.format().toString() :
        this.documentos.value.dataExpedicao = null;
      this.geral.value.dataNascimento ? this.geral.value.dataNascimento = dataNascimentoLocal.format().toString() :
        this.geral.value.dataNascimento = null;
    }
  }
}
