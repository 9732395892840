<div class="containerMain">
    <div class="box-cadastro">
        <mat-toolbar color="primary">
            <button (click)="toggleLogin()" mat-icon-button class="example-icon mr-2"
                aria-label="Example icon-button with menu icon">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span>Cadastro Aluno</span>
            <span class="example-spacer"></span>
        </mat-toolbar>
        <mat-horizontal-stepper labelPosition="bottom" #stepper>
            <mat-step [stepControl]="geral">
                <!-- stepper title !-->
                <ng-template matStepLabel>Informações Gerais</ng-template>
                <!-- end stepper title !-->

                <!-- stepper content !-->
                <form [formGroup]="geral">
                    <div class="form-row">
                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Nome Completo</mat-label>
                            <input formControlName="nome" maxlength="80" matInput placeholder="">
                            <mat-error *ngIf="geral.get('nome').hasError('required')">Campo obrigatório!</mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Sexo</mat-label>
                            <mat-select formControlName="sexo">
                                <mat-option [value]="sexo.id" *ngFor="let sexo of listaSexo">{{ sexo.descricao }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="geral.get('sexo').hasError('required')">Campo obrigatório!</mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Data de Nascimento</mat-label>
                            <input matInput formControlName="dataNascimento" placeholder="00/00/0000"
                                [matDatepicker]="picker" [min]="minDate" [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="geral.get('dataNascimento').hasError('required')">Data inválida!
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Nome da Mãe</mat-label>
                            <input formControlName="mae" matInput placeholder="">
                            <mat-error *ngIf="geral.get('mae').hasError('required')">Campo obrigatório!</mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Nome do Pai</mat-label>
                            <input formControlName="pai" matInput placeholder="">
                        </mat-form-field>

                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input formControlName="email" type="email" matInput placeholder="">
                            <mat-error *ngIf="geral.get('email').hasError('email')">Insira um email válido!</mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Celular</mat-label>
                            <input formControlName="celular" matInput mask="(00) 00000-0000" placeholder="">
                        </mat-form-field>
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input formControlName="telefone" matInput mask="(00) 00000-0000" placeholder="">
                        </mat-form-field>
                    </div>
                </form>
                <!-- end stepper content !-->

                <!-- stepper footer !-->
                <div class="form-row justify-content-end">
                    <button mat-raised-button color="primary" matStepperNext>Próximo</button>
                </div>
                <!-- end stepper footer !-->
            </mat-step>
            <mat-step [stepControl]="endereco">
                <!-- stepper title !-->
                <ng-template matStepLabel>Endereço</ng-template>
                <!-- end stepper title !-->

                <!-- stepper content !-->
                <form [formGroup]="endereco">
                    <div class="form-row">
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>CEP</mat-label>
                            <input formControlName="cep" mask="00000-000" matInput placeholder="">
                            <mat-error *ngIf="endereco.get('cep').hasError('required')">Campo obrigatório!</mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-1 col-sm-2" appearance="outline">
                            <mat-label>UF</mat-label>
                            <mat-select (selectionChange)="onChangeUF($event)" formControlName="uf">
                                <mat-option [value]="estado.id" *ngFor="let estado of listaEstados">{{ estado.sigla }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="endereco.get('uf').hasError('required')">Campo obrigatório!</mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-2 col-sm-4" appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <mat-select formControlName="cidade">
                                <mat-option [value]="cidade.id" *ngFor="let cidade of listaCidades">{{ cidade.descricao
                                    }}</mat-option>
                            </mat-select>
                            <mat-hint *ngIf="listaCidades.length === 0">Selecione uma UF</mat-hint>
                            <mat-error *ngIf="endereco.get('cidade').hasError('required')">Campo obrigatório!
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Tipo de Logradouro</mat-label>
                            <mat-select formControlName="tipoLogradouro" matInput placeholder="">
                                <mat-option [value]="logradouro.id" *ngFor="let logradouro of listaLogradouros">
                                    {{logradouro.descricao}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="endereco.get('tipoLogradouro').hasError('required')">Campo obrigatório!
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input formControlName="nomeLogradouro" matInput placeholder="">
                            <mat-error *ngIf="endereco.get('nomeLogradouro').hasError('required')">Campo obrigatório!
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-5 col-sm-10" appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input formControlName="bairro" matInput placeholder="">
                            <mat-error *ngIf="endereco.get('bairro').hasError('required')">Campo obrigatório!
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-4 col-sm-8" appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input formControlName="complemento" matInput placeholder="">
                        </mat-form-field>
                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Número</mat-label>
                            <input formControlName="numero" mask="0000" matInput placeholder="">
                        </mat-form-field>
                    </div>
                </form>
                <!-- end stepper content !-->

                <!-- stepper footer !-->
                <div class="form-row justify-content-end">
                    <button class="mr-2" mat-stroked-button color="primary" matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" matStepperNext>Próximo</button>

                </div>
                <!-- end stepper footer !-->
            </mat-step>
            <mat-step [stepControl]="documentos">
                <!-- stepper title !-->
                <ng-template matStepLabel>Documentos</ng-template>
                <!-- end stepper title !-->

                <!-- stepper content !-->
                <form [formGroup]="documentos">
                    <div class="form-row">
                        <mat-form-field class="col-md-4 col-sm-8" appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input formControlName="cpf" mask="000.000.000-00" matInput placeholder="">
                            <mat-error *ngIf="endereco.get('bairro').hasError('required')">Campo obrigatório!
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-5 col-sm-10" appearance="outline">
                            <mat-label>Código NIS</mat-label>
                            <input mask="00000000000" formControlName="codigoNis" matInput placeholder="">
                        </mat-form-field>
                        <mat-form-field class="col-md-4 col-sm-8" appearance="outline">
                            <mat-label>RG</mat-label>
                            <input formControlName="rg" mask="000000000000" matInput placeholder="">
                        </mat-form-field>
                        <mat-form-field class="col-md-1 col-sm-2" appearance="outline">
                            <mat-label>Digito</mat-label>
                            <input formControlName="digitoRg" mask="00" matInput placeholder="">
                        </mat-form-field>
                        <mat-form-field class="col-md-4 col-sm-8" appearance="outline">
                            <mat-label>Órgão Emissor</mat-label>
                            <mat-select formControlName="orgaoExpedidor">
                                <mat-option [value]="orgao.id" *ngFor="let orgao of orgaos">{{orgao.descricao}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="col-md-3 col-sm-6" appearance="outline">
                            <mat-label>Data de Expedição</mat-label>
                            <input matInput placeholder="00/00/0000" formControlName="dataExpedicao"
                                [matDatepicker]="picker2" [min]="minDate" [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </form>
                <!-- end stepper content !-->

                <!-- stepper footer !-->
                <div class="form-row justify-content-end">
                    <button class="mr-2" mat-stroked-button color="primary" matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" matStepperNext>Próximo</button>

                </div>
                <!-- end stepper footer !-->
            </mat-step>

            <mat-step [stepControl]="preMatricula">
                <!-- stepper title !-->
                <ng-template matStepLabel>Pré-matrícula</ng-template>
                <!-- end stepper title !-->
                <!-- stepper content !-->
                <form [formGroup]="preMatricula">
                    <div class="form-row">
                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Modalidade</mat-label>
                            <mat-select formControlName="modalidade" (selectionChange)="onChangeModalidade($event)">
                                <mat-option *ngFor="let modalidade of listaModalidades" [value]="modalidade.id">
                                    {{modalidade.descricao}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Nivel</mat-label>
                            <mat-select formControlName="nivel" (selectionChange)="onChangeNivel($event)">
                                <mat-option *ngFor="let nivel of listaNiveis" [value]="nivel.id">{{ nivel.descricao }}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="!preMatricula.get('modalidade').value">Selecione uma
                                <strong>modalidade</strong>.</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Etapa</mat-label>
                            <mat-select formControlName="etapaEnsino" (selectionChange)="onChangeEtapa($event)">
                                <mat-option *ngFor="let etapa of listaEtapas" [value]="etapa.id">{{etapa.descricao}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="!preMatricula.get('nivel').value">Selecione um <strong>nivel</strong>.
                            </mat-hint>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
                            <mat-label>Turno</mat-label>
                            <mat-select formControlName="turno" (selectionChange)="onChangeTurno($event)">
                                <mat-option *ngFor="let turno of listaTurnos" [value]="turno.id">{{ turno.descricao }}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                        <mat-form-field class="col-12" appearance="outline">
                            <mat-label>Unidade de Ensino</mat-label>
                            <mat-select formControlName="unidadeEnsino">
                                <mat-option *ngFor="let escola of listaEscolas" [value]="escola.id">
                                    {{escola.nome}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="!verificarTodosCampos()">Preencha os campos <strong>Modalidade</strong>,
                                <strong>Nivel</strong>, <strong>Etapa</strong> e
                                <strong>Turno</strong>.
                            </mat-hint>
                            <mat-hint style="color: red;" *ngIf="verificarTodosCampos() && listaEscolas.length === 0">
                                Nenhuma escola encontrada!
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <p></p>
                </form>
                <!-- end stepper content !-->

                <!-- stepper footer !-->
                <div class="form-row justify-content-end">
                    <button class="mr-2" mat-stroked-button color="primary" matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" [disabled]="loadingFinalizar"
                        (click)="cadastrarAluno()">Finalizar
                        <mat-icon *ngIf="loadingFinalizar">
                            <mat-spinner diameter="20">
                            </mat-spinner>
                        </mat-icon>
                    </button>

                </div>
                <!-- end stepper footer !-->
            </mat-step>
        </mat-horizontal-stepper>
    </div>

</div>

<swal #sucessoCadastro title="Matriculado com sucesso!" [confirmButtonText]="'Visualizar'" [cancelButtonText]="'Fechar'"
    [showCancelButton]="true"
    [text]="textMatriculado"
    icon="success"></swal>