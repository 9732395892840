import {Component, OnInit} from '@angular/core';
import * as AOS from 'aos';
import {InstituicaoService} from '../../services/instituicao.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {Cidade} from '../../model/Cidade';
import {Estado} from '../../model/Estado';
import {AlertService} from '@full-fledged/alerts';

@Component({
  selector: 'app-etutor',
  templateUrl: './etutor.component.html',
  styleUrls: ['./etutor.component.scss']
})
export class EtutorComponent implements OnInit {
  cidades: Cidade[] = [];
  // cidadesAtualizadas: Cidade[] = [];
  estados: Estado[] = [];
  estado: Estado;
  cidadeSelecionada: Cidade = null;
  cidade: Cidade = null;
  constructor(private instituicaoService: InstituicaoService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    AOS.init();
    this.spinner.show();
    this.instituicaoService.buscarTodosEstados().subscribe((data) => {
      // console.log(data);
      this.estados = data;
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      // console.log(error);
    });
  }

  // // tslint:disable-next-line:typedef
  // preencherEstados() {
  //   this.estados = [];
  //   this.cidades.forEach(e => {
  //     // this.horariosDisciplinas.find(horarioD => horarioD.horario.id === e.horario.id);
  //     const estado = this.estados.find(estadoD => estadoD.id === e.estado.id);
  //     if (estado === undefined) {
  //       this.estados.push(e.estado);
  //     }
  //   });
  //
  // }

  // tslint:disable-next-line:typedef
  // selecionarEstado(estadoId) {
  //   this.cidadesAtualizadas = [];
  //   this.cidades.forEach(e => {
  //     // tslint:disable-next-line:triple-equals
  //     if (e.estado.id == estadoId) {
  //       this.cidadesAtualizadas.push(e);
  //     }
  //   });
  // }
  getMunicipiosUf(estado: Estado) {
    this.spinner.show();
    this.instituicaoService.buscarCisdadesPorId(estado.id).subscribe((data) => {
      // console.log(data);
      this.cidades = data;
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      // console.log(error);
    });
  }

  // tslint:disable-next-line:typedef
  selecionarCidade(cidade: Cidade){
    // console.log(cidade);
    // cidade = JSON.parse(cidade);
    this.cidadeSelecionada = cidade;
    localStorage.setItem('cidade', JSON.stringify(cidade));
  }

  acessar(){
    if (this.cidadeSelecionada != null) {
      // console.log(this.cidadeSelecionada);
      this.router.navigate(['/instituicao']);

    } else {
      this.alertService.warning('Ops! Selecione a cidade.');
    }
  }
}
