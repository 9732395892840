import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Aluno } from '../model/Aluno';
import { UsuarioDTO } from '../model/UsuarioDTO';
import { Instituicao } from '../model/Instituicao';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.API_ALUNO + '/aluno/login';
  apiUrlG = environment.API_RELATORIO_URL + '/usuario/login';

  constructor(private http: HttpClient, private router: Router) { }

  // check
  check(): boolean {
    return localStorage.getItem('aluno') ? true : false;
  }

  checkGestor(): boolean {
    return localStorage.getItem('gestor') ? true : false;
  }

  // Login
  login(user: { codigo, dtNascimento }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}`, user).pipe(take(1));
  }

  // Login
  loginGestor(user): Observable<UsuarioDTO> {
    console.log(`${this.apiUrlG}`);
    console.log(user);
    return this.http.post<UsuarioDTO>(`${this.apiUrlG}`, user).pipe(take(1));
  }

  logout(): void {
    // localStorage.removeItem('user');
    localStorage.clear();
  }

  logoutAluno() {
    localStorage.removeItem("aluno");
  }

  // Recuperar senha
  getUser(): Aluno {
    return localStorage.getItem('aluno') ? JSON.parse(atob(localStorage.getItem('aluno'))) : null;
  }

  getGestor(): UsuarioDTO {
    return localStorage.getItem('gestor') ? JSON.parse(atob(localStorage.getItem('gestor'))) : null;
  }

  getInstituicao(): Instituicao {
    return localStorage.getItem('instituicao') ? JSON.parse(atob(localStorage.getItem('instituicao'))) : null;
  }
}
