import { Component, OnInit } from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-portal-aluno',
  templateUrl: './portal-aluno.component.html',
  styleUrls: ['./portal-aluno.component.scss']
})
export class PortalAlunoComponent implements OnInit {
  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
  }
}
