import {MaterialAula} from './MaterialAula';
import {Matricula} from './Matricula';
import {StatusAtividade} from './StatusAtividade';

export class AlunoAtividade {

  id: number;
  arquivo: string;
  observacaoAluno: string;
  observacaoProfessor: string;
  dataResposta: Date;
  materialAula: MaterialAula;
  matricula: Matricula;
  statusAtividade: StatusAtividade;
  arquivoAnexado: string;

}
