<div class="container container-body mt-5">

  <!--  <div class="text-center">-->
  <!--    <h2>Em desenvolvimento...</h2>-->
  <!--    <img src="assets/undrwa/work.svg" width="40%"/>-->
  <!--  </div>-->
  <hr/>
  <div class="row">
    <!--    <div class="form-group col-md-2">-->
    <!--      <label for="anoLetivo_in">ANO LETIVO: <span class="color-red">*</span></label>-->
    <!--      <select [(ngModel)]="anoSelecionado" id="anoLetivo_in" class="form-control labelFilter">-->
    <!--        <option *ngFor="let ano of anosLetivos" [ngValue]="ano">{{ano}}</option>-->
    <!--      </select>-->
    <!--    </div>-->

    <div class="col-12">

      <mat-tab-group mat-align-tabs="center">
        <mat-tab label="INSTITUIÇÃO">

          <div class="form-group col-md-2 mt-3">
            <label for="anoLetivo_in">ANO LETIVO: <span class="color-red">*</span></label>
            <select [(ngModel)]="anoSelecionado" id="anoLetivo_in" class="form-control labelFilter">
              <option *ngFor="let ano of anosLetivos" [ngValue]="ano">{{ano}}</option>
            </select>
          </div>

          <div class="box-relatorios">
            <div class="listaRelatorios">
              <ul>
                <li class="li-relatorios" (click)="relatorioTotalizacaoMatriculasEscola()"><a><i
                  class="fas fa-print mr-2"></i>Total de Matrículas das Escolas</a></li>
              </ul>
            </div>
          </div>
        </mat-tab>

        <!--        Escola #############################-->
        <mat-tab label="ESCOLA">

          <div class="row m-0">
            <div class="form-group col-md-2 mt-3">
              <label for="anoLetivo_es">ANO LETIVO: <span class="color-red">*</span></label>
              <select [(ngModel)]="anoSelecionado" id="anoLetivo_es" class="form-control labelFilter">
                <option *ngFor="let ano of anosLetivos" [ngValue]="ano">{{ano}}</option>
              </select>
            </div>
            <form [formGroup]="filtroForm" class="form-group col-8 mt-3">
              <label for="nome_escola">ESCOLA: <span class="color-red">*</span></label>
              <input type="text" class="form-control" id="nome_escola" placeholder="Nome da escola"
                     formControlName="nomeEscola" name="nome_escola" required [matAutocomplete]="autoGroupEscola">
            </form>
            <div *ngIf="alunoNaoEncontrado && filtroForm.controls['nomeEscola'].valid ">
              <small class="color-red">Nenhum aluno encontrado com esse nome em {{anoSelecionado}}</small>
            </div>
            <mat-autocomplete #autoGroupEscola="matAutocomplete">
              <mat-option *ngFor="let unidade of unidadesEnsino" [value]="unidade.nome"
                          (onSelectionChange)="selecionarUnidadeEnsino(unidade)">
                {{unidade.nome}}
              </mat-option>
            </mat-autocomplete>
          </div>
          
          <div class="box-relatorios">
            <div class="listaRelatorios">
              <ul>
                <li class="li-relatorios" (click)="relatorioCargaHoraria()"><a><i class="fas fa-print mr-2"></i>Carga
                  Horária</a></li>
                <li class="li-relatorios" (click)="relatorioProfessoresETurmas()"><a><i class="fas fa-print mr-2"></i>Professores
                  e suas Turmas</a></li>
                <li class="li-relatorios" (click)="relatorioTurmas()"><a><i class="fas fa-print mr-2"></i>Turmas</a>
                </li>
                <li class="li-relatorios" (click)="relatorioTurmasGrade()"><a><i class="fas fa-print mr-2"></i>Turmas
                  por Grade</a></li>
                <li class="li-relatorios" (click)="relatorioTurmasSemProfessor()"><a><i class="fas fa-print mr-2"></i>Turmas
                  sem Professor</a></li>
              </ul>
            </div>
          </div>
        </mat-tab>

<!-- Turmas --------------------------------------------------------------->
        <mat-tab label="TURMAS">
          <h4 class="mt-3">
  <app-r-turmas [anosLetivos]="anosLetivos"></app-r-turmas>
          </h4>
          <!--      <div class="form-group col-12 mt-2">-->
          <!--        <label for="anoLetivo93">SELECIONE A ESCOLA: <span class="color-red">*</span></label>-->
          <!--        <select id="anoLetivo93" class="form-control labelFilter">-->
          <!--          <option disabled selected></option>-->
          <!--          <option>ESCOLA</option>-->
          <!--          <option>ALUNO</option>-->
          <!--          <option>TURMAS</option>-->
          <!--          <option>DISCIPLINAS</option>-->
          <!--        </select>-->
          <!--      </div>-->

          <!--      <div class="form-group col-12 mt-2">-->
          <!--        <label for="anoLetivo84">SELECIONE A TURMA: <span class="color-red">*</span></label>-->
          <!--        <select id="anoLetivo84" class="form-control labelFilter">-->
          <!--          <option disabled selected></option>-->
          <!--          <option>ESCOLA</option>-->
          <!--          <option>ALUNO</option>-->
          <!--          <option>TURMAS</option>-->
          <!--          <option>DISCIPLINAS</option>-->
          <!--        </select>-->
          <!--      </div>-->

        </mat-tab>


        <!--         PROFESOR ------------------------------------------ -->
        <mat-tab label="PROFESSORES">
          <div class="row  m-0">
            <div class="form-group col-md-2 mt-3">
              <label for="anoLetivo_prof">ANO LETIVO: <span class="color-red">*</span></label>
              <select [(ngModel)]="anoSelecionadoProf" id="anoLetivo_prof" class="form-control labelFilter">
                <option *ngFor="let ano of anosLetivos" [ngValue]="ano">{{ano}}</option>
              </select>
            </div>
            <form [formGroup]="filtroForm" class="col-8 mt-3 m-0">
              <div class="form-group ">
                <div class="form-group">
                  <label for="profI">Professor: <span class="color-red">*</span></label>
                  <input type="text" class="form-control" id="profI" placeholder="Nome do professor"
                         formControlName="nomeProfessor" name="nome_aluno" required [matAutocomplete]="autoGroupProf">
                </div>
                <div *ngIf="professorNaoEncontrado && filtroForm.controls['nomeProfessor'].valid ">
                  <small class="color-red">Nenhum professor encontrado com esse nome</small>
                </div>
                <mat-autocomplete #autoGroupProf="matAutocomplete">
                  <mat-option *ngFor="let professor of professores" [value]="professor.nome_servidor"
                              (onSelectionChange)="selecionarProfessor(professor)">
                    {{professor.nome_servidor}} | <small> MAT:{{professor.matricula}}</small>
                  </mat-option>
                </mat-autocomplete>
              </div>
            </form>
            <div class="form-group col-2 mt-3" *ngIf="this.professorSelecionado.matricula">
              <div class="form-group">
                <label for="exampleInputEmail1">MAT</label>
                <input type="text" class="form-control" disabled [value]="this.professorSelecionado.matricula">
              </div>
            </div>
          </div>
          <div class="box-relatorios">
            <div class="listaRelatorios">
              <ul>
                <li class="li-relatorios" (click)="cargaHorariaProf()">
                  <a>
                  <i class="fas fa-print mr-2"></i>
                  Carga Horária
                </a>
                </li>
              </ul>
            </div>
          </div>
        </mat-tab>


        <mat-tab label="ALUNO">

          <div class="row  m-0">

            <div class="form-group col-md-2 mt-3">
              <label for="anoLetivo_al">ANO LETIVO: <span class="color-red">*</span></label>
              <select [(ngModel)]="anoSelecionadoAluno" id="anoLetivo_al" class="form-control labelFilter">
                <option *ngFor="let ano of anosLetivos" [ngValue]="ano">{{ano}}</option>
              </select>
            </div>

            <form [formGroup]="filtroForm" class="col-8 mt-3 m-0">
              <div class="form-group ">
                <div class="form-group">
                  <label for="exampleInputEmail1">ALUNO: <span class="color-red">*</span></label>
                  <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Nome do aluno"
                         formControlName="nomeAluno" name="nome_aluno" required [matAutocomplete]="autoGroup">
                </div>
                <div *ngIf="alunoNaoEncontrado && filtroForm.controls['nomeAluno'].valid ">
                  <small class="color-red">Nenhum aluno encontrado com esse nome em {{anoSelecionadoAluno}}</small>
                </div>
                <mat-autocomplete #autoGroup="matAutocomplete">
                  <mat-option *ngFor="let aluno of alunos" [value]="aluno.alunoNome"
                              (onSelectionChange)="selecionarAluno(aluno)">
                    {{aluno.alunoNome}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </form>
            <div class="form-group col-2 mt-3" *ngIf="this.alunoSelecionado.id">
              <div class="form-group">
                <label for="exampleInputEmail1">MAT</label>
                <input type="text" class="form-control" disabled [value]="this.alunoSelecionado.id">
              </div>
            </div>
          </div>
          <div class="box-relatorios">
            <div class="listaRelatorios">
              <ul>
                <li class="li-relatorios" (click)="boletimAluno()"><a><i class="fas fa-print mr-2"></i>Boletim
                  Escolar</a></li>
              </ul>
            </div>
          </div>


        </mat-tab>

      </mat-tab-group>
      <!--  <div id="box-relatorios">-->
      <!--    <div id="listaRelatorios">-->
      <!--      <ul>-->
      <!--        <li class="li-relatorios"><a href="#"><i class="fas fa-print mr-2"></i>Relatório de escolas - Professores</a>-->
      <!--        </li>-->
      <!--        <li class="li-relatorios"><a href="#">Relatório de alunos - Por alunos</a></li>-->
      <!--        <li class="li-relatorios"><a href="#">Relatório de alunos</a></li>-->
      <!--        <li class="li-relatorios"><a href="#">Relatório de alunos</a></li>-->
      <!--        <li class="li-relatorios"><a href="#">Relatório de alunos</a></li>-->
      <!--      </ul>-->

      <!--    </div>-->
      <!--  </div>-->

    </div>
  </div>
</div>
<ngx-spinner type="ball-atom">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
<ff-alerts></ff-alerts>
