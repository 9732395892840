<div class="row m-0 mt-3 mb-3 disciplinas" *ngIf="!mostrarDetalhes">
  <!-- Card de cada matéria -->
  <div class="col-lg-3 col-md-6 col-12" *ngFor="let disciplina of disciplinas; let i = index">
    <div class="card">
      <div class="card-header" [ngStyle]="{background: listaGradiente[i].cor}">
        <h1>{{disciplina.disciplina.descricao}}</h1>
      </div>
      <div class="card-body">
        <img src="assets/img/teacher.png" alt="img do pofessor">
        <div class="teacher-name">
          <p>{{disciplina.professor.pessoa.nome}}</p>
        </div>
      </div>
      <div class="card-footer" [ngStyle]="{background: listaGradiente[i].cor}" (click)="buscarMaterialAula(disciplina)">
        <a style="cursor: pointer" >Acessar</a>
      </div>
    </div>
  </div>

  <div *ngIf="disciplinas.length === 0" class="alert w-100 alert-warning" role="alert">
    Nenhuma disciplina encontrada!
  </div>
  <!--Fim do card -->
  <!-- Card -->
<!--  <div class="col-lg-3 col-md-6 col-12">-->
<!--    <div  class="card">-->
<!--      <div class="card-header Geografia">-->
<!--        <h1>Geografia</h1>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <img src="assets/img/teacher.png" alt="img do pofessor">-->
<!--        <div class="teacher-name">-->
<!--          <p>Emanuel Claudino</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card-footer Geografia">-->
<!--        <a href="#">Acessar</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  &lt;!&ndash;Fim do card &ndash;&gt;-->
<!--  &lt;!&ndash; Card &ndash;&gt;-->
<!--  <div class="col-lg-3 col-md-6 col-12">-->
<!--    <div  class="card">-->
<!--      <div class="card-header Portugues">-->
<!--        <h1>Português</h1>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <img src="assets/img/teacher.png" alt="img do pofessor">-->
<!--        <div class="teacher-name">-->
<!--          <p>Ingrid Silva</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card-footer Portugues">-->
<!--        <a href="#">Acessar</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  &lt;!&ndash;Fim do card &ndash;&gt;-->
<!--  &lt;!&ndash; Card &ndash;&gt;-->
<!--  <div class="col-lg-3 col-md-6 col-12">-->
<!--    <div  class="card">-->
<!--      <div class="card-header Artes">-->
<!--        <h1>Artes</h1>-->
<!--      </div>-->
<!--      <div class="card-body config-card-body">-->
<!--        <img src="assets/img/teacher.png" alt="img do pofessor">-->
<!--        <div class="teacher-name">-->
<!--          <p>William Prado</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card-footer Artes ">-->
<!--        <a href="#">Acessar</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <!--Fim do card -->
</div>
<ff-alerts></ff-alerts>
<app-detalhe-disciplina (evento)="voltar()" [atividades]="atividades" [disciplinaSelecionada]="disciplinaSelecionada"  *ngIf="mostrarDetalhes"  [materiais]="materiais"></app-detalhe-disciplina>
<ngx-spinner type="ball-atom">
  <p style="color: white" > Carregando... </p>
</ngx-spinner>
