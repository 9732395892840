<nav class="navbar navbar-light  effect1"  >
  <div class="logo-prefeitura col-12 col-sm-2 col-md-3">
    <a *ngIf="instituicao.imagem" routerLink="/instituicao"><img [src]="'data:image/jpg;base64,' + instituicao.imagem" alt="Logo da prefeitura"></a>
  </div>
  <div class="nome-escola col-12 col-sm-6 col-md-6 ">
    <h2 *ngIf="instituicao.nomeFantasia">{{instituicao.nomeFantasia}}</h2>
  </div>
  <div class="logo-eTutor col-12 col-sm-4 col-md-3">
    <a href="/instituicao"><img src="assets/logos/eTutor.png" alt="Logo do eTutor"></a>
  </div>
</nav>
