import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UnidadeEscolarService} from '../../../services/unidade-escolar.service';
import {AlertService} from '@full-fledged/alerts';
import {AuthService} from '../../../services/auth.service';
import {FiltroEscolaMonitoramento} from '../../../model/FiltroEscolaMonitoramento';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {BuscaEscolaMonitoramentoFilter} from '../../../model/BuscaEscolaMonitoramentoFilter';
import {Instituicao} from '../../../model/Instituicao';
import {MonitoramentoService} from '../../../services/monitoramento.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {EscolaMonitoramento} from '../../../model/EscolaMonitoramento';

@Component({
  selector: 'app-modal-filtro-turma',
  templateUrl: './modal-filtro-turma.component.html',
  styleUrls: ['./modal-filtro-turma.component.scss']
})
export class ModalFiltroTurmaComponent implements OnInit {
  escolaSelecionada: EscolaMonitoramento = new EscolaMonitoramento();
  escolas: EscolaMonitoramento[] = [];
  escolasAux: EscolaMonitoramento[] = [];
  desabilitarEscola = true;
  filtros: FiltroEscolaMonitoramento = new FiltroEscolaMonitoramento();
  anoSelecionado;
  instituicao = new Instituicao();

  filtroForm: FormGroup = this.formBuilder.group({
    nomeEscola: [{value: '', disabled: this.desabilitarEscola}, [Validators.required]],
  });

  constructor(public dialogRef: MatDialogRef<ModalFiltroTurmaComponent>,
              private unidadeEscolarService: UnidadeEscolarService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService,
              private monitoramentoService: MonitoramentoService,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    this.buscarFiltros();
    this.filtroNomeEscola();
    if (localStorage.hasOwnProperty('instituicao')) {
      this.instituicao = JSON.parse(atob(localStorage.getItem('instituicao')));
    }
  }


  buscarFiltros() {
    this.unidadeEscolarService.buscarFiltro().subscribe((data) => {
      this.filtros = data;
    }, (error) => {
      console.log(error);
    });
  }


  filtroNomeEscola() {
    this.filtroForm.get('nomeEscola').valueChanges
      .pipe(
        debounceTime(300),
        map(emittedValue => emittedValue.trim()),
        distinctUntilChanged()
      )
      .subscribe(selectedValue => {
        console.log('Alterando o valor escola');
        console.log(selectedValue);
        if (selectedValue !== '') {
          this.escolas = this.escolasAux;
          this.escolas = this.escolasAux.filter(
            item => {
              return (item.nome.toLowerCase().indexOf(selectedValue.toLowerCase()) > -1);
            }
          );
        } else {
          this.escolas = this.escolasAux;

        }
      });
  }

  buscarEscolas() {
    const filtroEscolaMonitoramento = new BuscaEscolaMonitoramentoFilter();
    filtroEscolaMonitoramento.ano = this.anoSelecionado;
    filtroEscolaMonitoramento.instituicao = this.instituicao.id;
    filtroEscolaMonitoramento.turma = 'TODAS';

    console.log(filtroEscolaMonitoramento);

    this.spinner.show();
    this.monitoramentoService.buscarEscolaMonitoramento(filtroEscolaMonitoramento).subscribe((data) => {
      this.spinner.hide();
      this.escolas = data;
      this.escolasAux = data;
      console.log(data);
      if (!this.escolas.length) {
        this.alertService.warning('Nenhuma escola encontrada');
        this.filtroForm.controls.nomeEscola.disable();
      } else {
        this.filtroForm.controls.nomeEscola.enable();

      }
    }, (error) => {
      console.log('Deu erro;');
    });

  }

  selecionarUnidadeEnsino(escola) {
    console.log(escola);
    this.escolaSelecionada = escola;
  }


  close(filtro): void {
    if (filtro != null){
      filtro = [this.anoSelecionado, this.escolaSelecionada];
    }
    this.dialogRef.close(filtro);
  }
}
