import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@full-fledged/alerts';
import { Generico } from 'src/app/model/Generico';
import { Modalidade } from 'src/app/model/Modalidade';
import { Turno } from 'src/app/model/Turno';
import { MatriculaAlunoService } from '../../services/matriculaAluno.service';

@Component({
  selector: 'app-aluno-matricula',
  templateUrl: './aluno-matricula.component.html',
  styleUrls: ['./aluno-matricula.component.scss']
})
export class AlunoMatriculaComponent implements OnInit {

  matriculaAluno: FormGroup;
  alunoID: number;

  modalidadeID: number;
  modalidade: Modalidade[] = [];

  turno: Turno[] = [];
  turnoID: number;

  nivel: Generico[] = [];
  nivelID: number;

  etapa: Generico[] = [];
  etapaID: number;

  escola: Generico[] = [];
  escolaID: number;

  matriculaLoading = false;
  loading = false;
  entidade = JSON.parse(localStorage.getItem('instituicaoSelecionada'));

  constructor(private matricula: MatriculaAlunoService, private alertService: AlertService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.matriculaForm();
    this.obterModalidade();
    this.obterTurno();
    const { id } = JSON.parse(atob(localStorage.getItem('aluno')));
    this.alunoID = id;

    this.obterDadosMatricula();
  }

  matriculaForm() {
    this.matriculaAluno = this.fb.group({
      modalidade: [null, [Validators.required]],
      turno: [null, [Validators.required]],
      nivel: [null, [Validators.required]],
      etapa: [null, [Validators.required]],
      escola: [null, [Validators.required]],
    });
  }

  obterModalidade() {
    this.matricula.getModalidade(this.entidade.id).subscribe(response => this.modalidade = response);
  }

  obterTurno() {
    this.matricula.getTurno().subscribe(response => this.turno = response);
  }

  obterNivel(id: number) {
    if (id) {
      this.matricula.getNivel(id, this.entidade.id).subscribe(response => this.nivel = response);
    }
  }

  obterEtapa(id: number) {
    if (id) {
      this.matricula.getEtapa(id, this.entidade.id).subscribe(response => this.etapa = response);
    }
  }

  obterEscola() {
    if (this.etapaID && this.turnoID) {
      this.matricula.getEscola(this.etapaID, this.turnoID).subscribe((response) => {
        this.escola = response;
      },
        (error) => {
          if (error.status === 404) {
            this.alertService.danger(error.error.mensagemUsuario);
          }
        });
    }
  }

  onChangeModalidade(item) {
    this.modalidadeID = item.id;
    this.obterNivel(this.modalidadeID);
    this.matriculaAluno.controls.nivel.reset();
    this.matriculaAluno.controls.etapa.reset();
    this.matriculaAluno.controls.escola.reset();
  }

  onChangeTurno(item) {
    this.turnoID = item.id;
    this.matriculaAluno.controls.escola.reset();
  }

  onChangeNivel(item) {
    this.nivelID = item.id;
    this.obterEtapa(this.nivelID);
    this.matriculaAluno.controls.etapa.reset();
    this.matriculaAluno.controls.escola.reset();
  }

  onChangeEtapa(item) {
    this.etapaID = item.id;
    this.matriculaAluno.controls.escola.reset();
  }

  matricular() {
    this.loading = true;
    const matricula = {
      aluno: this.alunoID,
      etapaEnsino: this.matriculaAluno.controls.etapa.value,
      turno: this.matriculaAluno.controls.turno.value,
      unidadeEnsino: this.matriculaAluno.controls.escola.value,
    };

    this.matricula.postMatricula(matricula).subscribe(response => {
    },
      (error) => {
        if (error.status !== 200) {
          this.alertService.danger(`Houve um erro!\n` +
            `${error.status} ${error.statusText}`);
        }
        else {
          this.alertService.success('Matriculado com sucesso!');
          // this.matriculaAluno.reset();
          this.loading = false;
        }
        this.loading = false;
      });
  }

  reemitirMatriculaAluno() {
    this.matriculaLoading = true;
    this.matricula.emitirMatricula(this.alunoID).subscribe(
      (resp) => {
        const file = new Blob([resp], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        this.alertService.danger(`Houve um erro!\n` +
          `${error.status} ${error.statusText}`);
        this.matriculaLoading = false;
      }, () => { this.matriculaLoading = false; });
  }

  obterDadosMatricula() {
    this.matricula.copulaAluno(this.alunoID).subscribe(resp => {
      this.obterNivel(resp.idModalidade);
      this.matriculaAluno.controls.nivel.patchValue(resp.idNivel);
      this.obterEtapa(resp.idNivel);
      this.matriculaAluno.controls.modalidade.patchValue(resp.idModalidade);
    });
  }

}