<app-header-aluno></app-header-aluno>
<div class="container mt-4">
  <mat-tab-group>
    <mat-tab label="Geral">
      <div class="container box-main mb-5">
        <div id="part-1">
          <div class="form-group input-nome-aluno col-md-6">
            <label for="nome-aluno">Nome</label>
            <input [(ngModel)]="aluno.nome" type="text" class="form-control" id="nome-aluno" placeholder="Nome do aluno" disabled>
          </div>
          <div class="col-3">
            <div class="form-group input-sexo" *ngIf="aluno.sexo">
              <label for="sexo">Sexo</label>
              <select id="sexo" class="form-control" disabled>estado
                <option selected >{{aluno.sexo.descricao}}</option>

              </select>
            </div>
          </div>
          <div id="data-nascimento" class="col-md-3">
            <div class="form-group input-data-nasc">
              <label for="data-nasc">Nascimento</label>
              <input [(ngModel)]="aluno.nascimento" type="text" class="form-control" id="data-nasc" placeholder="Data de nascimento" disabled>
            </div>
          </div>
        </div>
        <div id="nome-pais">
          <div id="nome-mae" class="col-md-6">
            <div class="form-group input-nome-mae">
              <label for="mae">Mãe</label>
              <input [(ngModel)]="aluno.mae" type="text" class="form-control" id="mae" placeholder="Nome da Mãe" disabled>
            </div>
          </div>
          <div id="nome-pai" class="col-md-6">
            <div class="form-group input-nome-pai">
              <label for="pai">Pai</label>
              <input [(ngModel)]="aluno.pai" type="text" class="form-control" id="pai" placeholder="Nome do Pai" disabled>
            </div>
          </div>
        </div>
        <!-- Informações dos documentos -->
        <div id="contato" *ngIf="aluno.contato">
          <div class="h2-contato">
            <h2>Contato</h2>
          </div>
          <div id="tell-Email-cell">
            <div class="email col-md-5">
              <label for="InputEmail1">Email:</label>
              <input [(ngModel)]="aluno.contato.email" type="email" class="form-control" id="InputEmail1" aria-describedby="emailHelp"
                     placeholder="Email">
            </div>
            <div class="tell col-md-3">
              <label for="tell">Telefone:</label>
              <input  [(ngModel)]="aluno.contato.telefone" type="tel" class="form-control" id="tell" aria-describedby="tellHelp" placeholder="Telefone">
            </div>
            <div class="cell col-md-3">
              <label for="cell">Celular:</label>
              <input [(ngModel)]="aluno.contato.celular" type="tel" class="form-control" id="cell" aria-describedby="cellHelp" placeholder="Celular">
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

<!--    Endereço ----------------------------------------------------------------------------------------------------->
    <mat-tab label="Endereço" *ngIf="aluno.endereco">
      <div    class="container box-main mb-5">
        <div id="endereço">
          <div id="endereço0">
<!--            <div class="cidade-estado">-->
<!--              <div class="form-group input-estado col-md-6">-->
<!--                <label for="estado">Estado</label>-->
<!--                <select id="estado" class="form-control" disabled>-->
<!--                  <option selected>{{aluno.endereco}}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="form-group input-cidade col-md-6">-->
<!--                <label for="cidade">Cidade</label>-->
<!--                <select id="cidade" class="form-control" disabled>-->
<!--                  <option selected>Selecionar</option>-->
<!--                  <option>São luís</option>-->
<!--                  <option>...</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
            <div id="endereço1">
              <div class="tipo- form-group input-tipo col-md-2">
                <label for="tipo">Tipo</label>
                <select id="tipo" class="form-control" disabled>
                  <option selected>Selecionar</option>
                  <option>...</option>
                  <option>...</option>
                </select>
              </div>
              <div class="form-group input-logradouro col-md-5">
                <label class="label-bairro" for="logradouro">Logradouro</label>
                <input [(ngModel)]="aluno.endereco.nomeLogradouro" class="form-control" id="logradouro" aria-describedby="bairroHelp" placeholder="Logradouro" disabled>
              </div>
              <div class="bairro col-md-3">
                <label class="label-bairro" for="bairro">Bairro</label>
                <input   [(ngModel)]="aluno.endereco.bairro"   class="form-control" id="bairro" aria-describedby="bairroHelp" placeholder="Bairro" disabled>
              </div>
              <div class="numero col-md-1">
                <label class="label-numero" for="bairro">Nº</label>
                <input  [(ngModel)]="aluno.endereco.numero"  class="form-control" id="numero" aria-describedby="numeroHelp" placeholder="SN" disabled>
              </div>
            </div>
          </div>
        </div>
        <div id="secondPartAdress">
          <div id="CEP" class=" col-md-3">
            <div class=" cep">
              <label class="label-cep" for="cep1">CEP</label>
              <input  [(ngModel)]="aluno.endereco.cep"  class="form-control" id="cep1" aria-describedby="cepHelp" disabled>
            </div>
          </div>
          <div id="complemento" class="col-md-6">
            <div class="complemento">
              <label class="label-complemento" for="complemento1">Complemento</label>
              <input  [(ngModel)]="aluno.endereco.complemento"  class="form-control" id="complemento1" aria-describedby="complementoHelp" disabled>
            </div>
          </div>
<!--          <div class="tipo- form-group input-tipo col-md-3">-->
<!--            <label for="tipo">Tipo do imóvel</label>-->
<!--            <select id="tipo" class="form-control" disabled>-->
<!--              <option selected>Selecionar</option>-->
<!--              <option>Casa</option>-->
<!--              <option>...</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div id="morePartOfAdress">-->
<!--          <div id="UC" class="col-md-6">-->
<!--            <div class="complemento">-->
<!--              <label class="label-UC" for="uc1">UC</label>-->
<!--              <input class="form-control" id="uc1" disabled>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div id="zona" class="col-md-6">-->
<!--            <div class="zona">-->
<!--              <label class="label-zona" for="zona1">Zona</label>-->
<!--              <input class="form-control" id="zona1" disabled>-->
<!--            </div>-->
<!--          </div>-->

        </div>
      </div>
    </mat-tab>




    <mat-tab label="Documentos">
      <div class="box-main container mb-5">
        <div id="info-docs">
          <div class="form-group input-cpf col-md-3">
            <label for="CPF">CPF</label>
            <input type="text" class="form-control" id="CPF" placeholder="CPF" disabled>
          </div>
          <div class="form-group input-passaporte col-md-3">
            <label for="passaporte">Código Inep</label>
            <input  [(ngModel)]="aluno.codigoInep"  type="text" class="form-control" id="passaporte" placeholder="Código Inep" disabled>
          </div>
          <div class="form-group input-NIS col-md-3">
            <label for="NIS">Código NIS</label>
            <input  [(ngModel)]="aluno.codigoNis"  type="text" class="form-control" id="NIS" placeholder="NIS" disabled>
          </div>
          <div class="form-group input-bolsaFamilia col-md-2">
            <label for="inputBolsaFamilia">Bolsa Família</label>
            <select id="inputBolsaFamilia" class="form-control" disabled>
              <option selected *ngIf="aluno.bolsaFamilia">SIM</option>
              <option selected *ngIf="!aluno.bolsaFamilia">NÃO</option>
            </select>
          </div>
        </div>
        <!-- Registro geral -->
        <div id="RG-infos1">
          <div class="form-group input-RG col-md-3">
            <label for="RG">RG</label>
            <input type="text" class="form-control" id="RG" placeholder="RG" disabled>
          </div>
          <div class="form-group input-digito col-md-1">
            <label for="dg">Dígito</label>
            <input type="text" class="form-control" id="dg" placeholder="" disabled>
          </div>
          <div class="form-group input-OG col-md-3">
            <label for="inputOrgaoEmissor1">Orgão Emissor</label>
            <select id="inputOrgaoEmissor1" class="form-control" disabled>
              <option selected>Selecionar</option>
              <option>SSP</option>
              <option>...</option>
            </select>
          </div>
          <div class="form-group input-uf col-md-2">
            <label for="uf">UF</label>
            <select id="uf" class="form-control" disabled>
              <option selected>Selecionar</option>
              <option>MA</option>
              <option>...</option>
            </select>
          </div>
          <div class="form-group input-expediçao col-md-2">
            <label for="expediçao">Expedição</label>
            <input type="text" class="form-control" id="expediçao" placeholder="" disabled>
          </div>
        </div>
      </div>
    </mat-tab>



    <mat-tab label="Fotos">

      <div class="box-main container mb-5">

        <p class="text-center mt-2">
          <img *ngIf="!fotoBase64" class="perfil" src="assets/img/graduated.png" alt="image of profile">
          <img *ngIf="fotoBase64" class="perfil" [src]="'data:image/jpg;base64,' + fotoBase64" alt="image of profile">
        </p>
        <div class="text-center" *ngIf="!fotoNome">
        <input class="ng-hide d-none" id="input-file-id" multiple type="file"  accept="image/*" (change)="handleFileInput($event.target.files)"/>
        <label for="input-file-id" class="cursor color-font-primary">
          Alterar foto do perfil</label>
        </div>

        <div class="text-center mb-2"  *ngIf="fotoNome" >
          <button class="btn btn-primary mt-2 bt-w-30" (click)="salvarFoto()">
            <i class="fas fa-upload mr-1"></i>
            Enviar
          </button>

          <br/>

          <button class="btn btn-outline-primary mt-3 bt-w-30" (click)="cancelarFoto()">
            <i class="fas fa-times mr-1"></i>
            Cancelar
          </button>
        </div>
      </div>


    </mat-tab>



  </mat-tab-group>
</div>

<ff-alerts></ff-alerts>
<ngx-spinner type="ball-atom">
  <p style="color: white" > Carregando... </p>
</ngx-spinner>

